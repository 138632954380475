import {
    possibleBasicMortgageParams,
    MortgageParams,
    StringMortgageParams,
    AnyTypeMortgageParams,
    AnyTypeCompleteMortgageParams,
    RepaymentScheduleResult }
from '../../constants/types';
import { maxTaxDeductionBase, numberOfIterationsForComupingInterestRate } from '../../constants/constants';
import { sanitizeInput } from '../../helpers/helpers';

export default class MortgageMathService {

    calcMortgageViaMissingParam(input: MortgageParams | StringMortgageParams, rounded = true) {
        /*
        Vstupy a výstupy:
        - výše hypotéky
        - výše splátky
        - doba splatnosti
        - úroková sazba
        Vždy potřebuju 3 tyhle věci a dopočítám z nich tu 4.
        */

        const numericInput: {[key: string]: number} = {};
        Object.keys(input).forEach((key) => {
            // @ts-ignore
            if (typeof input[key] !== "string" && typeof input[key] !== 'number') {
                numericInput[key] = NaN;
            } else {
                // @ts-ignore
                numericInput[key] = sanitizeInput(input[key]);
            }
        })

//        console.log('Calc mortgage via missing param:', numericInput);
        const paramToCalc = this.determineParamToCalc(numericInput);
        let result: any;
//        console.log("Param to calc:", paramToCalc);
        switch (paramToCalc) {
            case 'installment':
                result = this.calcAnuity(numericInput, rounded);
                break;
            case 'iRate':
                result = this.calcInterestRate(numericInput);
                break;
            case 'due':
                result = this.calcDue(numericInput);
                break;
            case 'amount':
                result = this.calcAmount(numericInput, rounded);
                break;
            default:
                break;
        }
        
//        console.log('Calc result:', result);
        return result;
    }

    calcAmount(input: MortgageParams, rounded = true) : number {
        const iRate = input.iRate!/100;
        const due = input.due!*12;
        const installment = input.installment!;
        // We use the formula from calcAnuity
        const result = installment/(iRate!/12)*(1 - Math.pow(1+(iRate!/12), -due!) ) 
        return rounded ? Math.round(result) : result;
    }

    calcDue(input: MortgageParams) : number {
        const paymentsAtTheEndOfTheMonth = 1;
        const amount = input.amount!;
        const installment = input.installment!;
        let presentValue = 0;
        const iRate = input.iRate!/100;
        let result = 0;

        let oldPresentValue = 0;

        while (presentValue < amount) {
            result++;
            presentValue += installment/Math.pow(1+iRate, (result+paymentsAtTheEndOfTheMonth)/12);
            if (Math.floor(presentValue) < oldPresentValue || result > 480) {
                throw new Error('Can not calculate due!')
            }
            oldPresentValue = presentValue;
        }
        return Math.floor(result/12+1);
    }

    calcAnuity(input: MortgageParams, shouldRound = true) : number {
        // https://www2.karlin.mff.cuni.cz/~portal/fin_mat/?page=anuita
        /*
        s = V*i/(1-(1+i)^-1)
        */

        const amount = input.amount;
        const iRate = input.iRate!/100;
        const due = input.due!*12;
        if (!iRate) {
            // iRate is 0
            const result = Math.round(amount!/due);
            return result;
        }
        // Calc installment formula
        const result = amount!*(iRate/12) / (1 - Math.pow(1+(iRate/12), -due) ) 
        return shouldRound ? Math.round(result) : result;
    }

    calcInterestRate(input: MortgageParams, shouldReturnRPSN?: boolean) : number {

        // see https://www.gpf.cz/jak-vypocitat-rpsn-rocni-procentni-sazba-nakladu
        // interst rate and RPSN will use the same formula for now

        const amount = input.amount!;
//        const due = input.due;
//        const installment = input.installment;

        let currentEstimate = 0.05;
        const estimateHistory = [-0.1, 1];
        let sumHelper = this.calcInterestRateSumHelper(input, currentEstimate);
        let tries = 0;

        while (tries < numberOfIterationsForComupingInterestRate) {
            tries++;
            // First process the latest estimate by pushing it into the history
            estimateHistory.push(currentEstimate);
            estimateHistory.sort(function(a, b) {
                return a - b;
            });

            // The decide the next estimate
            const resultDiff = sumHelper + amount;
            if (resultDiff < 0) {
                // we have to raise the estimate
                const currentEstimateIndex = estimateHistory.indexOf(currentEstimate);
                if (currentEstimateIndex === estimateHistory.length-1) {
                    currentEstimate = currentEstimate*2;
                } else {
                    currentEstimate = (estimateHistory[currentEstimateIndex] + estimateHistory[currentEstimateIndex+1]) / 2
                }
            } else {
                // we have to lower the estimate
                const currentEstimateIndex = estimateHistory.indexOf(currentEstimate);
                if (currentEstimateIndex === 0) {
                    currentEstimate = currentEstimate/2;
                } else {
                    currentEstimate = (estimateHistory[currentEstimateIndex] + estimateHistory[currentEstimateIndex-1]) / 2
                }
            }

            // Then calc the next interest rate helper
            sumHelper = this.calcInterestRateSumHelper(input, currentEstimate);
        }

        if (currentEstimate < - 0.195 || currentEstimate > 0.95) {
            throw new Error('Estimate is too big/small')
        }

        if (shouldReturnRPSN) {
            return Math.round(currentEstimate*10000)/100
        } else {
            /*
            NOTE: the result is now basically RPSN. To transform it to the interest rate, we need to
            un-pow it and multiply by 12 (months)
            If we ever wanted to get RPSN by 
            */
            return Math.round((Math.pow(1+currentEstimate, 1/12)-1)*12*10000)/100;
        }
    }

    calcInterestRateSumHelper(input: MortgageParams, estimatedRate: number) : number {
        // NOTE: if paymentsAtTheEndOfTheMonth are true, then we will take that into
        // account in the current value computed below
        // TODO: discuss with Pepa what how we want to set this
        const paymentsAtTheEndOfTheMonth = 1;

        let result = 0;
        const due = input.due!*12;
        const installment = input.installment!;

        for (let i = 0; i < due; i++) {
            result -= installment/Math.pow(1+estimatedRate, (i+paymentsAtTheEndOfTheMonth)/12)
        };

        return result;
    }

    determineParamToCalc(input: MortgageParams) : keyof MortgageParams | '' | undefined  {
        let result: keyof MortgageParams | '' = '';
        possibleBasicMortgageParams.forEach(item => {
            if (!input[item] && input[item] !== 0) {
                if (result) {
                    console.error("Got invalid params", input);
                    return '';
                } else {
                    result = item;
                }
            }
        })
        return result;
    }

    calcFutureValue(input: AnyTypeMortgageParams, fixationLengthInYears: number | string) {
        const numericInput: {[key: string]: number} = {};
        Object.keys(input).forEach((key) => {
            // @ts-ignore
            numericInput[key] = sanitizeInput(input[key]);
        })
        
        if (typeof fixationLengthInYears === 'string') {
            fixationLengthInYears = parseInt(fixationLengthInYears);
        }

        if (isNaN(fixationLengthInYears)) {
            console.error ('cannot calculate future value as fix length is:', fixationLengthInYears);
            return;
        }

        let futureValue = numericInput.amount;

        for (let i = 0; i < fixationLengthInYears*12; i++) {
            futureValue += (futureValue * (numericInput.iRate/1200));
            futureValue -= numericInput.installment;
        }

        return futureValue;
    }

    calcRepaymentSchedule(input: AnyTypeCompleteMortgageParams, useTaxDeduction: boolean = true, yearlyUpkeep: number = 0, valueOfProperty: number, yearlyValueOfPropertyIncreaseIndex: number = 0, yearlyUpkeepIncreaseIndex: number = 0, taxRefundRate: number = 15) {
        /*
        Sanitizes inputs
        Generates schedule array with MONTHLY data, each item contains and object with:
            - month
            - interest
            - value left
        */

        const interest = sanitizeInput(input.iRate)/100;
        const due = sanitizeInput(input.due);
        const installment = sanitizeInput(input.installment);
        let amount = sanitizeInput(input.amount);

        let sumOfInterest = 0;
        let sumOfYearlyInterest = 0;
        let currentPropertyValue = valueOfProperty;
        let currentYearlyUpkeep = yearlyUpkeep;
        let sumOfPayments = valueOfProperty - amount;

        const result: RepaymentScheduleResult[] = [];

        for (let i = 0; i < due*12; i++) {
            const monthlyInterest = amount*(interest/12);
            sumOfInterest += monthlyInterest;
            sumOfYearlyInterest += monthlyInterest;
            amount = amount + monthlyInterest - installment;
            currentPropertyValue = currentPropertyValue * (yearlyValueOfPropertyIncreaseIndex);
            currentYearlyUpkeep = currentYearlyUpkeep * (yearlyUpkeepIncreaseIndex);
            sumOfPayments += installment;

            const monthlyResult = {
                month: i+1,
                interest: Math.round(monthlyInterest),
                amount: Math.round(amount),
                payment: installment,
                mortgageInvestment: 0,
                taxDeduction: 0,
                upkeepCost: 0,
                currentPropertyValue: Math.round(currentPropertyValue),
                sumOfYearlyInterest: Math.round(sumOfYearlyInterest),
                sumOfPayments: Math.round(sumOfPayments)
            }

            if ((i+1) % 12 === 0 && i !== 0) {
                let taxDeduction = 0;
                if (useTaxDeduction) {
                    taxDeduction = Math.round(Math.min(sumOfYearlyInterest, maxTaxDeductionBase)*(taxRefundRate/100));
                }

                monthlyResult.taxDeduction = taxDeduction;
                monthlyResult.upkeepCost = currentYearlyUpkeep;

                if (currentYearlyUpkeep) {
                    const extraPaymentsResult = currentYearlyUpkeep - taxDeduction;
//                    if (extraPaymentsResult > 0) {
                        // Upkeep is higher than deduction, we need to increate the payment
//                        monthlyResult.payment = installment + extraPaymentsResult;
//                    } else {
                        // Upkeep is lower than deduction, we need to note the investment
                        monthlyResult.mortgageInvestment = -extraPaymentsResult;
//                    }
                } else {
                    monthlyResult.mortgageInvestment = taxDeduction;
                }

                sumOfYearlyInterest = 0;
            }

            if (i === due*12-1) {
                // Last payment will pay the rounding diffs
                monthlyResult.payment = installment + monthlyResult.amount;
                monthlyResult.amount = 0;
                sumOfInterest = 0;
            }

            result.push(monthlyResult);
        }

        return result;
    }

    // SHOULD BE ONE MONTH ACTUALLY
    calcOneYear(input: AnyTypeCompleteMortgageParams, extraAmountOrPaymentAtTheEndOfYear?: number, iRate?: number, due?: number) {
        const amount = sanitizeInput(input.amount);
        const interestRate = (iRate || iRate === 0) ? iRate : sanitizeInput(input.iRate);
        const finalDue = (due || due === 0) ? due : sanitizeInput(input.due);

        // WE must recalculate the installment as the amount might have changed
        const newInstallment = this.calcAnuity({
            amount: amount,
            iRate: interestRate,
            due: finalDue/12
        }, false);

        /*
        We will calculate future value of all the inputs and return them in an object
        */
        let futureValue = this.calcFutureValue(
            {
                amount: amount,
                iRate: interestRate,
                due: finalDue/12,
                installment: newInstallment
            
            }, 1/12);
        if (!futureValue || futureValue < 0) {
            futureValue = 0;
        }
        const newAmount = futureValue - (extraAmountOrPaymentAtTheEndOfYear || 0);
        const newDue = finalDue - 1;
        const newInterestRate = interestRate;

        const interest = newInstallment + (extraAmountOrPaymentAtTheEndOfYear || 0) + newAmount - amount;
        return {
            amount: newAmount,
            due: newDue,
            installment: newInstallment,
            iRate: newInterestRate,
            interest: interest,
            // Will be set in the next step
            sumOfPayments: 0,
            sumOfInterest: 0
        }
    }

    calcAnnuityCalendar(input: AnyTypeCompleteMortgageParams, extraAmountOrPaymentAtTheEndOfYear: number[], iRates: number[] = [], dues: number[] = []) {
        const result = [{
            amount: sanitizeInput(input.amount) - (extraAmountOrPaymentAtTheEndOfYear[0] ? extraAmountOrPaymentAtTheEndOfYear[0] : 0),
            due: sanitizeInput(input.due),
            installment: 0, //newInstallment,
            iRate: sanitizeInput(input.iRate),
            interest: 0,
            sumOfPayments: 0
        }];

        let yearlyInterest = 0;
        let sumOfPayments = 0;
        let sumOfInterest = 0;

        let remainingDue = result[0].due;
        let currentIrate = result[0].iRate;

        // Note that dues contain zero as well
        for (let i = 1; remainingDue > 0; i++) {
            if (dues[i-1] !== undefined && !Number.isNaN(dues[i-1])) {
                remainingDue = dues[i-1];
            } else {
            }

            if (iRates[i] !== undefined) {
                currentIrate = iRates[i];
            }

            const yearlyResult = this.calcOneYear(result[i-1], extraAmountOrPaymentAtTheEndOfYear[i], currentIrate, remainingDue);
            if (i % 12 !== 1) {
                yearlyInterest += yearlyResult.interest;
            } else {
                yearlyInterest = yearlyResult.interest;
            }

            // Calc sum of interest before setting yearly result interest to yearly interest
            sumOfInterest += yearlyResult.interest;
            yearlyResult.sumOfInterest = sumOfInterest;

            yearlyResult.interest = yearlyInterest;
            sumOfPayments += yearlyResult.installment + (extraAmountOrPaymentAtTheEndOfYear[i] || 0);
            yearlyResult.sumOfPayments = sumOfPayments;
            result.push(yearlyResult);

            remainingDue--;
        }

        return result;
    };
}
