import * as React from "react";
import {observer} from "mobx-react-lite";
import { useInjection } from "../../../react-binding";
import MortgageMathService from "../../../services/MathServices/MortgageMath.service";
import { MortgageParams, StringMortgageParams, AnyTypeMortgageParams } from "../../../constants/types";
import { InputWithSlider } from "../../Alpha/partials/InputWithSlider";
import UserInfo from "../../../services/UserInfo.service";
import { Select } from "../../Alpha/partials/Select";
import { possibleFixations, bankListOptions, propertyTypeList, purposeList } from "../../../constants/constants";
import { MortgageTypesNr, MortgageTypes } from "../../../constants/types";
import { CustomerInfoInput } from "../../Alpha/UserInfoInput/CustomerInfoInput";
import { BankService } from "../../../services/Bank/BankService";
import { useEffect } from "react";
import { CheckBoxInput } from "../../Alpha/partials/CheckBoxInput";
//import { LetterForArgumentationService } from "../../../services/Bank/LetterForArgumentationService";
import { history } from "../../FrontendIndex";
import { observable } from "mobx";
import { useState } from "react";
import { TextInput } from "../../Alpha/partials/TextInput";
import { cloneDeep, cond } from "lodash";
import {
    genericVariableCondition,
    genericVariable,
    genericIfStatement,
    genericThenOrElseStatement,
    genericRateModifier,
    genericBonityVariable,
    genericBank
} from "./ToolConstants";

const dataToolStore: any = observable({
    data: undefined
});

//@observer
export const DataCreationTool = observer(() => {
    const bankService = useInjection(BankService);
    const userInfo = useInjection(UserInfo);
    const bankData = dataToolStore.data;

    async function loadCurrentBankData () {
        const input = document.getElementById('data') as HTMLInputElement;
        const files = input.files;
        if (files) {
            const text = await files[0].text();
            const result = JSON.parse(text);
            dataToolStore.data = result.data;
        } else {
            console.error("FILE INPUT IS EMPTY!");
        }
//        dataToolStore.data = cloneDeep(bankService.dataService.bankData);
    }

    async function loadStandardBankData() {
        dataToolStore.data = cloneDeep(bankService.dataService.bankData);
    }

    async function useDataAndRedirectToArgumentation() {
        bankService.dataService.bankData = cloneDeep(dataToolStore.data);
        bankService.initializing = false;
        bankService.banks = [];
        bankService.initialize(false);
        history.push('/argumentation');
    }

    async function downloadData() {
        const fileName = "generatedData.json";
        const json = await JSON.stringify({data:bankData});

        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(json));
        element.setAttribute('download', fileName);
        
        element.style.display = 'none';
        document.body.appendChild(element);
        
        element.click();
        
        document.body.removeChild(element);
    }

    function renderNumericInput(observable: any, field: string, step: number) {
        return <TextInput
            placeholder=""
            observable={observable}
            field={field}
            textAfterInput={" %"}
            type={"number"}
            onBlurCallback={()=>{}}
        />
    }

    function renderFixationsPart(observable: any) {
        return possibleFixations.map(fixation => {
            return <div key={Math.random()}>
                {fixation}
                {renderNumericInput(observable, fixation.toString(), 0.1)}
            </div>
        })
    }

    function renderVariableField(variable: any, bonityVariable?:boolean) {
        return <div className="variable-field">
            {variable.conditions.map((condition: any, index: number) => {  
                return <div className="field-path" key={Math.random()}>
                    <Select
                        options={!bonityVariable ? [
                            {
                                value: "userInfo",
                                label: "userInfo"
                            },
                            {
                                value: "mortgageParams",
                                label: "mortgageParams"
                            },
                            {
                                value: "extraFields",
                                label: "extraFields"
                            }
                        ]:[
                            {
                                value: "",
                                label: "bank result"
                            },
                            {
                                value: "extraFields",
                                label: "extraFields"
                            }
                        ]}
                        observable = {condition.field}
                        field = "0"
                        onChangeCallback = {() => {
                            if (!bonityVariable) {
                                return;
                            } else if (condition.field[0] !== "extraFields") {
                                if (condition.field[1]) {
                                    condition.field[0] = condition.field.pop()
                                }
                            }
                        }}
                    />
                    <span> {"->"} </span> 
                    <TextInput
                        placeholder="Zadej název vstupu"
                        observable={condition.field}
                        field = {(!bonityVariable || (bonityVariable && condition.field[0] === "extraFields")) ? "1" : "0"}
                    />

                    <div>
                    <Select
                        observable={condition}
                        field="comparisonType"
                        options={[
                            {
                                value:'greaterOrEqualThan',
                                label: 'větší nebo rovno'
                            },
                            {
                                value:'lessOrEqualThan',
                                label: 'menší nebo rovno'
                            },
                            {
                                value:'exact',
                                label: 'rovno'
                            },
                            {
                                value:'differentFrom',
                                label: 'nerovná se'
                            },
                        ]}
                    />
                    <TextInput
                        observable = {condition}
                        field = "conditionValue"
                        placeholder = "zadej hodnotu"
                    />
                    </div>
                    {variable.conditions.length > 1 ?
                     <button onClick={() => removeArrayItem(variable.conditions, index)}>Odstranit podmínku</button> : ''
                    }
                </div>
                    
            })}
            <button onClick ={() => addArrayItem(variable.conditions, genericVariableCondition)}>Přidat podmínku</button>
        </div>
    }

    function addField(observable: any, field: string, value: any) {
        observable[field] = value;
    }

    function removeField(observable: any, field: string) {
        delete observable[field];
    }

    function removeArrayItem(array: any[], index: number) {
        array.splice(index, 1);
    }

    function addArrayItem(array: any[], value: any) {
        array.push(value);
    }

    function renderVariable(variable: any, bonityVariable?:boolean) {
        return <div key={Math.random()} className="variable-section">
            <h3>
                Název proměnné:
                <TextInput
                    placeholder="Zadej název proměnné"
                    observable={variable}
                    field="variableName"
                    onBlurCallback={() => {}}
                />
            </h3>
            <div>
                Podmínky musí být splněny:
                <Select
                    options={[
                        {
                            value: "all",
                            label: "všechny"
                        },
                        {
                            value: "atLeastOne",
                            label: "alespoň jedna"
                        },
                        {
                            value: "none",
                            label: "žádná"
                        }
                    ]
                    }
                    observable = {variable}
                    field = {"mustPassConditions"}
                    onChangeCallback = {() => {}}
                />

                <div>
                    Podmínky:
                    <div>
                        {renderVariableField(variable, bonityVariable)}
                    </div>
                </div>
            </div>
        </div>
    }

    function renderIfStatement(ifStatement: any, renderName:boolean = true): any {
                /*
                        {
                    "variableName": "nad5MKcNebomezi100Ka10MKcNeboDo500kKc",
                    "variablesThatMustPass": ["nad5MKc", "mezi100Ka10MKc"],
                    "then": {
                        "variablesThatMustPass": ["nad8MKc"]
                    },
                    "else": {
                        "variablesThatMustPass": ["do500kKc"]
                    }
                }
        */
        return <>
            {renderName && <h3>
                    Název IF statementu:
                    <TextInput
                        placeholder="Zadej název proměnné"
                        observable={ifStatement}
                        field="variableName"
                        onBlurCallback={() => {}}
                    />
                </h3>
            }

            <div>
                <span>Variables, které musí být splněny - oddělené čárkou</span>
                <TextInput
                    placeholder="Zadej proměnné, oddělené čárkou"
                    observable={ifStatement}
                    field="variablesThatMustPass"
                />
            </div>
            {ifStatement.then ? <div className="then-or-else-statement">
                <h4>
                    THEN
                </h4>
                {renderIfStatement(ifStatement.then, false)}
                <button onClick={() => removeField(ifStatement, "then")}>Odstranit THEN</button>
            </div>:<button onClick ={() => addField(ifStatement, "then", genericThenOrElseStatement)}>Přidat THEN</button>

            }

            {ifStatement.else ? <div className="then-or-else-statement">
                <h4>
                    ELSE
                </h4>
                {renderIfStatement(ifStatement.else, false)}
                <button onClick={() => removeField(ifStatement, "else")}>Odstranit ELSE</button>
            </div>:<button onClick ={() => addField(ifStatement, "else", genericThenOrElseStatement)}>Přidat ELSE</button>}

        </>
    }

    function renderGeneralRule(rule: any) {
        return <>
            <h3>
                Název pravidla:
                <TextInput
                    placeholder="Zadej název pravidla"
                    observable={rule}
                    field="rateRuleName"
                    onBlurCallback={() => {}}
                />
            </h3>
            <div className="bank-input-wrapper">
                <span>Modifikátor:</span>
                <TextInput
                    placeholder="Zadej modifikaci sazby v p.b."
                    observable={rule}
                    field="modifier"
                    onBlurCallback={() => {}}
                    type='number'
                    textAfterInput=" %"
                />
            </div>

            <div>
                <span>Variables, které musí být splněny - oddělené čárkou</span>
                <TextInput
                    placeholder="Zadej proměnné, oddělené čárkou"
                    observable={rule}
                    field="listOfAllVariablesThatMustPass"
                />
            </div>

        </>
    }

    function renderVariablesPart(variables: any, bonityVariables: boolean = false) {
        return variables.map((variable:any, index: number) => {
            return <>
                {renderVariable(variable, bonityVariables)}
                <button onClick ={() => removeArrayItem(variables, index)}>Odstranit proměnnou {variable.name}</button>
            </>
        })
    }

    function renderIfStatementsPart(ifStatements: any) {
        return ifStatements ? <>
            {ifStatements.map((ifStatement:any, index: number) => {
                return <> {renderIfStatement(ifStatement, true)}
                <button onClick ={() => removeArrayItem(ifStatements, index)}>Odstranit IF statement</button>
                </>
            })}
        </>:'';
    }

    function renderGeneralRulesPart(generalRules: any) {
        return generalRules.map((rule:any, index: number) => {
            return <>
                {renderGeneralRule(rule)}
                <button onClick ={() => removeArrayItem(generalRules, index)}>Odstranit pravidlo</button>
            </>
        })
    }

    function renderDataTool() {
        return <>
            {
                bankData?.map((bank: any, index: number) => {
                    return  <div key={Math.random()}>
                        <div className="bank-wrapper">
                            <div className="bank-input-wrapper">
                                <h1>Jméno banky:</h1>
                                <TextInput
                                    placeholder="Zadej název banky"
                                    observable={bank}
                                    field="name"
                                    onBlurCallback={() => {}}
                                />
                            </div>

                            <div className="bank-input-wrapper">
                                <h2>
                                    Základní úrokové sazby:
                                </h2>
                                <div>
                                    Účelová hypotéka:
                                </div>
                                    {renderFixationsPart(bank.iRates['1'].baseRates)}
                                    <div>
                                    Neúčelová hypotéka:
                                </div>
                                    {renderFixationsPart(bank.iRates['2'].baseRates)}
                            </div>
                            <div className="bank-input-wrapper">
                                <h2>
                                    Proměnné (variables)
                                </h2>
                                    {renderVariablesPart(bank.variables)}
                                    <button className="button-big-margin" onClick ={() => addArrayItem(bank.variables, genericVariable)}>Přidej proměnnou</button>
                            </div>

                            <div className="bank-input-wrapper">
                                <h2>
                                    IF statementy
                                </h2>
                                    {renderIfStatementsPart(bank.ifStatements)}
                                    <button className="button-big-margin" onClick={() => addArrayItem(bank.ifStatements, genericIfStatement)}>Přidej IF statement</button>
                            </div>

                            <div className="bank-input-wrapper">
                                <h2>
                                    Úpravy sazeb
                                </h2>
                                    {renderGeneralRulesPart(bank.generalRatesRules)}
                                    <button onClick ={() => addArrayItem(bank.generalRatesRules, genericRateModifier)}>Přidat pravidlo</button>
                            </div>

                            <div className="bank-input-wrapper">
                                <h2>
                                    Bonitní variables
                                </h2>
                                    {renderVariablesPart(bank.bonityVariables, true)}
                                    <button className="button-big-margin" onClick ={() => addArrayItem(bank.bonityVariables, genericBonityVariable)}>Přidej bonitní proměnnou</button>
                            </div>

                            <div className="bank-input-wrapper">
                                <h2>
                                    Bonitní IF statementy
                                </h2>
                                    {renderIfStatementsPart(bank.bonityIfStatements)}
                                    <button className="button-big-margin" onClick={() => addArrayItem(bank.bonityIfStatements, genericIfStatement)}>Přidej bonitní IF statement</button>
                            </div>

                            <div className="bank-input-wrapper">
                                <h2>
                                    Variables, které musí být splěnny, aby prošla bonita
                                </h2>
                                    <TextInput
                                    placeholder="Zadej proměnné, oddělené čárkou"
                                    observable={bank}
                                    field="bonityVariablesThatMustPass"
                                    />
                            </div>
                        </div>

                        <button onClick ={() => removeArrayItem(bankData, index)}>Odstranit banku</button>
                    </div>
                })
            }
            {
                bankData && <button className="button-big-margin" onClick={() => addArrayItem(bankData, genericBank)}>Přidej Banku</button>
            }

        </>

    }

    return <div className="data-tool">
        <h1>
            Vytvářeč bankovních dat
        </h1>
        <button onClick={() => loadCurrentBankData()}>NAČTI ZVOLENÝ SOUBOR</button>
        <button onClick={() => loadStandardBankData()}>NAČTI STANDARDNÍ DATA</button>
        <button onClick={() => downloadData()}>Generuj a stáhni JSON</button>
        <button onClick={() => console.log("CURRENT DATA:", dataToolStore.data)}>Ukaž data v konzoli</button>
        <button onClick={() => useDataAndRedirectToArgumentation()}>Použij data a přejdi do argumentace</button>

        <input type="file"
       id="data" name="data"
       accept="json"></input>

        {dataToolStore ? renderDataTool() : ''}
    </div>
})