import * as React from "react";
import {observer} from "mobx-react-lite";
import { useInjection } from "../../../react-binding";
import MortgageMathService from "../../../services/MathServices/MortgageMath.service";
import { MortgageParams, StringMortgageParams, AnyTypeMortgageParams } from "../../../constants/types";
import { InputWithSlider } from "../../Alpha/partials/InputWithSlider";
import UserInfo from "../../../services/UserInfo.service";
import { Select } from "../../Alpha/partials/Select";
import { possibleFixations, bankListOptions, propertyTypeList, purposeList, calcDisclaimer } from "../../../constants/constants";
import { MortgageTypesNr, MortgageTypes } from "../../../constants/types";
import { CustomerInfoInput } from "../../Alpha/UserInfoInput/CustomerInfoInput";
import { BankService } from "../../../services/Bank/BankService";
import { useEffect, useMemo } from "react";
import { CheckBoxInput } from "../../Alpha/partials/CheckBoxInput";
import { LetterForArgumentationService } from "../../../services/Bank/LetterForArgumentationService";
import { useState } from "react";
import { PDFLetter } from "../../../services/PDFLetter";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ReactDOM from "react-dom";
import { NavigationService } from "../../../services/NavigationService/NavigationService";

//@observer
export const Argumentation = observer(() => {

    const bankService = useInjection(BankService);
    const userInfoService = useInjection(UserInfo);
    const navigationService = useInjection(NavigationService);
    const letterForArgumentationService = useInjection(LetterForArgumentationService);
    const mortgageMathService = useInjection(MortgageMathService);
    const checkBestRateService = bankService.checkBestRateService;

    const [resultCollapsed, setResultCollapsed] = useState(true);
    const [generalInfoCollapsed, setGeneralInfoCollapsed] = useState(false);
    const [detailedInfoCollapsed, setDetailedInfoCollapsed] = useState(true);

    function recompute() {
        checkBestRateService.generateBestResultForUserParams(userInfoService, true);
        return;
    }

    function handleGeneralInsuranceCheckboxChangeAndRecompute() {
        bankService.setGeneralInsuranceToAllBanks(userInfoService.mortgageParams.useGenericInsurance);
        recompute();
    }

    function redirectToContactPage() {
        navigationService.navigateToPage('lead');
    }

    function computeLetterParams() {
        if (!checkBestRateService.bestResultForUserParams?.data?.interestRate) {
            console.error('New iRate is null, cannot compose letter');
            return;
        }

        const { due, amount, fixation } = userInfoService.mortgageParams;
        const originalIRate = userInfoService.userInfo.benchmarkRate;
        const newIRate = checkBestRateService.bestResultForUserParams.data.interestRate;
        const newBank = checkBestRateService.bestResultForUserParams.data.name;
        const startingFee = checkBestRateService.bestResultForUserParams.data.startingFee ?? 0;
        const monthlyFee = checkBestRateService.bestResultForUserParams.data.monthlyFee ?? 0;
        const sumOfFeesNew = startingFee + monthlyFee*fixation*12;

        const insurance = bankService.checkIfGeneralInsuranceIsSet();

        if (newIRate === null) {
            console.error('New iRate is null, cannot compose letter');
            return;
        }

        const originalInstallment = Math.ceil(mortgageMathService.calcMortgageViaMissingParam({
            due,
            iRate: originalIRate,
            amount
            })
        );

        const newInstallment = Math.ceil(mortgageMathService.calcMortgageViaMissingParam({
            due,
            iRate: newIRate.toString(),
            amount
        })
        );

        const originalFutureAmount = mortgageMathService.calcFutureValue({
            amount,
            iRate: originalIRate,
            installment: originalInstallment
        }, fixation);

        const newFutureAmount = mortgageMathService.calcFutureValue({
            amount,
            iRate: newIRate,
            installment: newInstallment
        }, fixation);

        if (originalFutureAmount === undefined || newFutureAmount === undefined) {
            console.error('Could not compute future amounts correctly');
            return;
        }

        return {
            originalFutureAmount,
            originalInstallment,
            newFutureAmount,
            newInstallment,
            originalIRate,
            newIRate,
            newBank,
            insurance,
            sumOfFeesNew
        }

    }

    useEffect(() => handleGeneralInsuranceCheckboxChangeAndRecompute(), [bankService.bankServiceInitialized.value]);

    const params: any = useMemo(() => {
        const params = computeLetterParams();
        return params;
    }, [bankService.checkBestRateService.bestResultForUserParams.data])

    function renderBestRateSection() {
        const isTheBestRateBetterThanUsersOffer =
            checkBestRateService.bestResultForUserParams.data &&
            (
                checkBestRateService.bestResultForUserParams.data.name.toLowerCase() !==
                userInfoService.mortgageParams.filterBank.toLowerCase()
            );
        
        const startingFee = checkBestRateService.bestResultForUserParams?.data?.startingFee ?? 0;
        const regularFee = (checkBestRateService.bestResultForUserParams?.data?.monthlyFee ?? 0) * userInfoService.mortgageParams.fixation*12;
        const sumOfNewInstallments = checkBestRateService.bestResultForUserParams?.data?.sumOfInstallments ?? 0;
        const sumOfOriginalInstallments = checkBestRateService.benchmarkBankResult?.data?.sumOfInstallments ?? 0;
        const originalFutureAmount = checkBestRateService.benchmarkBankResult?.data?.futureValue ?? 0;
        const newFutureValue = checkBestRateService.bestResultForUserParams?.data?.futureValue ?? 0;
        const sumOfFeesNew = startingFee + regularFee;

        const advantage = Math.round((sumOfOriginalInstallments - sumOfNewInstallments) + (originalFutureAmount - newFutureValue) - sumOfFeesNew);

        /*
        const isTheBestRateBetterThanUsersOffer = checkBestRateService.bestResultForUserParams.data &&
         checkBestRateService.bestResultForUserParams.data.interestRate !== null &&
            checkBestRateService.bestResultForUserParams.data.interestRate < parseFloat(userInfoService.userInfo.benchmarkRate.replace(',','.'));
*/

        return (
            checkBestRateService.bestResultForUserParams?.data?.passesBonityCheck &&
            checkBestRateService.bestResultForUserParams?.data?.interestRate !== null &&
            checkBestRateService.bestResultForUserParams?.data?.interestRate !== undefined &&
            checkBestRateService.bestResultForUserParams?.data?.interestRate + 0.2 <= parseFloat(userInfoService.userInfo.benchmarkRate.replace(",", ".")) &&
            (advantage > 0 || userInfoService.mortgageParams.fixation.toString() === "0")
        ) ? <>
            {/* 
                This means that the best result passes bonity check,
                has interest rate and is at least 0.2 RPSN better than the benchmark

                NOW we check if the advantage is > 8000 Kč. If not, then display another result...
            */}
            {isTheBestRateBetterThanUsersOffer ? <div className="argumentation-result">
                <div className="result-text">
                    Nejlepší výsledek jsme našli u banky: <b>{checkBestRateService.bestResultForUserParams.data.name}</b>.
                </div>
                <div className="result-text">
                    {/* A bit earlier there is the checkBestRateService.bestResultForUserParams?.data?.interestRate check... */}
                    Nabídková Sazba: {checkBestRateService.bestResultForUserParams.data.interestRate.toString().replace(".", ",")} %.
                </div>
                <div>
                    {advantage >= 8000 ? 
                        "Podle našich dat lze pro zadaný případ na trhu dosáhnout na výhodnější úrokovou sazbu. Níže si můžete zcela zdarma vygenerovat arugmentaci pro vaši stávající banku, nebo se s pomocí s refinancováním obrátit na nás.":
                        "Celkové náklady související s refinancováním jsou proti úspoře na sazbě relativně vysoké a z výsledků se domníváme, že pro Vás nemá smysl odcházet od současné banky. Nicméně si níže můžete zcela zdarma vygenerovat argumentaci pro vaši stávající banku, třeba bude ochotna nabídku zlepšit. Pokud byste chtěli od své stávající banky odejít, rádi se na Váš případ podíváme detailně. Stačí použít náš náš kontaktní formulář."
                    }

                </div>
                <div className="buttons-wrapper">
                    <button className="argumentation-button">
                        <PDFDownloadLink document={<PDFLetter params={{params, letterForArgumentationService, userInfoService}} />} fileName="Argumentace.pdf">
                        {({ blob, url, loading, error }) =>
                            loading ? 'Generuji...' : 'Stáhnout argumentaci'
                        }
                        </PDFDownloadLink>
                    </button>
                    <button className="argumentation-button" onClick={() => redirectToContactPage()}>Chci pomoc s refinancováním</button>
                </div>
            </div>:<>
                <div className="argumentation-result"> Vaše banka vám dle dostupných informací nabídla dobrou sazbu. Nevidíme důvod, proč od ní odcházet. Pokud přesto uvažujete o refinancování, rádi pomůžeme. Stačí použít náš náš kontaktní formulář. </div>
            </>}
        </> : <>
            <div className="argumentation-result"> Vaše banka vám dle dostupných informací nabídla dobrou sazbu. Nevidíme důvod, proč od ní odcházet. Pokud přesto uvažujete o refinancování, rádi pomůžeme. Stačí použít náš náš kontaktní formulář. </div>
        </>
    }

    return (
        <div className='app-screen argumentation'>
            <h1>Argumentace pro lepší sazbu</h1>
            <p>
                Vyplňte níže uvedené údaje a dozvíte se, zdali je nabídka vaší současné banky tou nejvýhodnější.
            </p>

            <button className="full-width clickable result-button" onClick={() => setResultCollapsed(!resultCollapsed)}>{`${resultCollapsed ? "Zobrazit výsledek" : "Skrýt výsledek"}`}</button>
            <div className={`result-wrapper collapsible ${resultCollapsed ? "collapsed" : ""}`}>
                {checkBestRateService.bestResultForUserParams && renderBestRateSection()}

            </div>

            <h3 className="loan-info-title">
                Vstupní informace
            </h3>

            <div className="inputs-section">

                <div className="general-info block-of-inputs">
                    <div className="field-wrapper">
                        <span className="field-name">
                                Vaše současná banka:
                        </span>
                        <Select
                            options={bankListOptions}
                            observable = {userInfoService.mortgageParams}
                            field = "filterBank"
                            onChangeCallback = {recompute}
                        />
                    </div>
                    
                    <div className="field-wrapper">
                        <span className="field-name">
                                Typ hypotéky:
                        </span>
                        <Select
                            options={[
                                {
                                    value: MortgageTypesNr.standard,
                                    label: MortgageTypes.standard
                                },
                                {
                                    value: MortgageTypesNr.american,
                                    label: MortgageTypes.american
                                }
                            ]
                            }
                            observable = {userInfoService.mortgageParams}
                            field = "mortgageType"
                            onChangeCallback = {recompute}
                        />
                    </div>

                    {userInfoService.mortgageParams.mortgageType.toString() === "1" && <>
                        <div className="field-wrapper">
                            <span className="field-name">
                                    Financovaná nemovitost:
                            </span>
                            <Select
                                options={propertyTypeList}
                                observable = {userInfoService.mortgageParams}
                                field = "propertyType"
                                onChangeCallback = {recompute}
                            />
                        </div>
{/*
                        <div className="field-wrapper">
                            <span className="field-name">
                                    Zastavená nemovitost:
                            </span>
                            <Select
                                options={propertyTypeList}
                                observable = {userInfoService.mortgageParams}
                                field = "mortgagedPropertyType"
                                onChangeCallback = {recompute}
                            />
                        </div>
*/}
                    </>}

                </div>


                <div className="detailed-info block-of-inputs">
                    <div className="field-wrapper">
                        <span className="field-name">
                            Fixace:
                        </span>
                            <Select
                                observable = {userInfoService.mortgageParams}
                                field = "fixation"
                                options = {possibleFixations.slice(1).map((item, index) => {
                                    return {
                                        value: item,
                                        label: item,
                                    }
                                })}
                                onChangeCallback = {recompute}
                            />
                    </div>

                    <div className="field-wrapper">
                        <span className="field-name">
                            Výše úvěru:
                        </span>
                        <InputWithSlider
                            onChangeCallback = {recompute}
                            placeholder = "Výše hypotéky"
                            observable = {userInfoService.mortgageParams}
                            field = "amount"
                            min = {100000}
                            max = {12000000}
                            step = {100000}
                            textAfterInput = " Kč"
                            disableBtn = {true}
                        />
                    </div>

                    <div className="field-wrapper">
                        <span className="field-name">
                            Hodnota zástavy:
                        </span>
                        <InputWithSlider
                            onChangeCallback = {recompute}
                            placeholder = "Hodnota zástavy"
                            observable = {userInfoService.mortgageParams}
                            field = "valueOfSecurity"
                            min = {200000}
                            max = {10000000}
                            step = {50000}
                            textAfterInput = " Kč"
                            disableBtn = {true}
                        />
                    </div>

                    <div className="field-wrapper">
                        <span className="field-name">
                            Zbývající splatnost:
                        </span>
                        <InputWithSlider
                            onChangeCallback = {recompute}
                            placeholder = "Splatnost v letech"
                            observable = {userInfoService.mortgageParams}
                            field = "due"
                            min = {1}
                            max = {30}
                            step = {1}
                            textAfterInput = " let"
                            disableBtn = {true}
                        />
                    </div>

                    <div className="field-wrapper">
                        <span className="field-name">
                            Sazba nabídnutá vaší bankou:
                        </span>
                        <InputWithSlider
                            onChangeCallback = {recompute}
                            placeholder = "Sazba"
                            observable = {userInfoService.userInfo}
                            field = "benchmarkRate"
                            min = {0.09}
                            max = {9.99}
                            step = {0.1}
                            textAfterInput = " %"
                            disableBtn = {true}
                            showDecimals = {false}
                        />
                    </div>

                
                    <div className="field-wrapper checkbox-wrapper">
                        <span className="field-name">
                            Sazba obsahuje i slevu za pojištění, která jsem ochoten změnit
                        </span>
                        <CheckBoxInput 
                            observable={userInfoService.mortgageParams}
                            onChangeCallback={handleGeneralInsuranceCheckboxChangeAndRecompute}
                            field='useGenericInsurance'
                        />
                    </div>
                </div>
            </div>

            <CustomerInfoInput recompute={recompute}></CustomerInfoInput>

            <div className="calc-disclaimer">{calcDisclaimer}</div>

        </div>
    )
})