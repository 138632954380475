import { observer } from "mobx-react-lite";
import { useInjection } from "../../../react-binding";
import UserInfo from "../../../services/UserInfo.service";
import { action } from "mobx";
import { useCallback } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Download, Save, Upload } from "react-bootstrap-icons";
import { sanitizeExportedArrayThatMightHaveMissingPieces } from "../../../helpers/helpers";

export const ImportExport = observer(() => {
    const userInfoService = useInjection(UserInfo);

    const exportToFile = useCallback(() => {
        const data = userInfoService;
        const fileName = data.general.name || 'Kalkulace';
        const json = JSON.stringify(data, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }, []);

    const setOneFromJson = action((data: any, fieldName: string) => {
        Object.keys(data).forEach(key => {
            // @ts-ignore
            userInfoService[fieldName][key] = data[key];
        });
    });

    const setUserInfo = action((data: UserInfo) => {
        const annuityCalcData = data.annuityCalcData;
        annuityCalcData.dues = sanitizeExportedArrayThatMightHaveMissingPieces(annuityCalcData.dues);
        annuityCalcData.iRates = sanitizeExportedArrayThatMightHaveMissingPieces(annuityCalcData.iRates);
        annuityCalcData.extraAmountsOfPayments = sanitizeExportedArrayThatMightHaveMissingPieces(annuityCalcData.extraAmountsOfPayments);
        annuityCalcData.notes = sanitizeExportedArrayThatMightHaveMissingPieces(annuityCalcData.notes);

        setOneFromJson(data.general, 'general');
        setOneFromJson(data.userInfo, 'userInfo');
        setOneFromJson(data.mortgageParams, 'mortgageParams');
        setOneFromJson(data.simpleMortgageData, 'simpleMortgageData');
        setOneFromJson(annuityCalcData, 'annuityCalcData');
        setOneFromJson(data.simpleInvestmentData, 'simpleInvestmentData');
        setOneFromJson(data.mortgageVsRentData, 'mortgageVsRentData');
        setOneFromJson(data.extraFields, 'extraFields');
    });

    function tooltipsSection() {
        return <>
            <ReactTooltip
                id="import-tooltip"
                place="top"
                content="Nahrát data ze souboru"
                className="tooltip"
            />

            <ReactTooltip
                id="export-tooltip"
                place="top"
                content= {`Uložit jako ${userInfoService.general.name || 'Kalkulace'}.json`}
                className="tooltip"
            />
        </>
    }

    return <div className="import-export print-hidden">

        <input 
            type="text" className="file-name" placeholder="Kalkulace" value={userInfoService.general.name}
            onChange={(e) => {
                userInfoService.general.name = e.target.value;
            }}
        />

        <div className="buttons-wrapper">

            <label className="custom-file-upload"
                data-tooltip-id="export-tooltip"
                onClick={() => {
                exportToFile();
            }}>
                <Download/>
            </label>

            <label htmlFor="file-upload" className="custom-file-upload btn btn-primary"
                data-tooltip-id="import-tooltip"
            >
                <Upload/>
            </label>
            <input
                id="file-upload" type="file" accept=".json" multiple={false}
                onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (!file) return;
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const data = JSON.parse(e.target?.result as string) as UserInfo;
                        setUserInfo(data);
                    };
                    reader.readAsText(file);
                }}
            />
        </div>
        {tooltipsSection()}
    </div>
});