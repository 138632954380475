import { observer } from "mobx-react-lite";
import { useInjection } from "../../react-binding";
import { BankService } from "../../services/Bank/BankService";
import { Envelope, Globe2, TelephoneFill } from "react-bootstrap-icons";

interface PrintHeaderProps {
    title: string;
    subtitle?: string;
    hideDetails?: boolean;
}

export const PrintHeader = observer((props: PrintHeaderProps) => {
    const bankService = useInjection(BankService) as BankService;

    return <div className="print-visible print-header-main">
        <div className="print-header">
            <div className="titles-wrapper">
                <h2 className="title">
                    {props.title} {props.subtitle && <span className="subtitle">{props.subtitle}</span>}
                </h2>
            </div>
        </div>
    </div>
})