import { BankService } from "./Bank/BankService";
import MockData from "../constants/mockBankData.json";
import { action, observable } from "mobx";
import { WordPressUIData } from "../constants/types";

export default class DataService {

    dataEndpoint = 'https://data.hypokalk.cz/';
    phpScriptEndpoint = 'https://phpscripts.hypokalk.cz/';
    dataListName = 'dataList.json';
    sanitizedDomain = "hypokalk_cz";

    userId?: string;
    fincalcPhpEndpoint = 'https://phpscripts.fincalc.cz/';

    bankService: BankService;

    bankData?: any[];

    dataList: [] = [];

    testing: boolean = false;

    priborRates?: any[];

    constructor(bankService: BankService) {
        this.bankService = bankService;
    }

    /*
    !!!!!!!!!!!!!!!!!!!!
    SET THIS ONLY BY QUERY PARAMS, NOTHING ELSE!
    !!!!!!!!!!!!!!!!!!!!
    */
    wordPressUIData = observable({
        telNumber: undefined,
        email: undefined,
        name: undefined,
        url: undefined,
        hypoVsNajemNazev: undefined,
        annuityCalcName: undefined,
    }) as {[key: string]: string | undefined | null};

/*
    !!!!!!!!!!!!!!!!!!!!
    OBSOLETE !!!!!!!!!!!
    !!!!!!!!!!!!!!!!!!!!
*/
    parseWordPressUIData = action((wordPressUIData: WordPressUIData[]) => {
        const zakladni_barva_item = wordPressUIData.find(item => 
            item.meta_key === 'zakladni_barva');

        const sekundarni_barva_item = wordPressUIData.find(item => 
            item.meta_key === 'druha_barva');

        const telNumber = wordPressUIData.find(item =>
            item.meta_key === 'tp_phone_number');

        const email = wordPressUIData.find(item =>
            item.meta_key === 'zobrzovany_mail');

        const name = wordPressUIData.find(item =>
            item.meta_key === 'zobrazovane_jmeno');

        const hypoVsNajemName = wordPressUIData.find(item =>
            item.meta_key === 'hypo_vs_najem_nazev');

        const url = wordPressUIData.find(item =>
            item.meta_key === 'zobrazovane_url');


        const result = {
            zakladni_barva: zakladni_barva_item?.meta_value,
            sekundarni_barva: sekundarni_barva_item?.meta_value,
            telNumber: telNumber?.meta_value,
            email: email?.meta_value,
            name: name?.meta_value,
            url: url?.meta_value,
            hypoVsNajemName: hypoVsNajemName?.meta_value
        };

        const r = document.querySelector(':root') as HTMLElement;
        if (r && result.zakladni_barva) {
            r.style.setProperty('--primary-color', result.zakladni_barva);
        }
        if (r && result.sekundarni_barva) {
            r.style.setProperty('--secondary-color', result.sekundarni_barva);
        }

        this.wordPressUIData = result;
    });

    async fetchWordPressInfo() {
        if (!this.userId) {
            console.log("No user ID detected");
            return;
        }
        const endpoint = this.fincalcPhpEndpoint + `/user_ui_data.php?id=${this.userId}`;

        try {
            const result = await fetch(endpoint, {
                method: 'GET'
            })

            const json = await result.json();
            this.parseWordPressUIData(json);
        } catch(e) {
            console.error("Failed to fetch WordPress info", e);
        }

    }

    async initialize(shouldFetchData: boolean = true) {
        try {
        // FETCHING WORDPRESS INFO IS OBSOLETE !!!
//            this.fetchWordPressInfo();
            if (!shouldFetchData) {
                return this.bankData;
            }
    
            if (this.testing) {
                console.error("WARNING: USING TESTING BANK DATA!");
    //            const bankData = MockData.data;
                const bankData = await this.fetchBankData('testing_data.json');
                await this.fetchPribor();
                this.bankData = bankData;
                return bankData;
            }
    
            const now = new Date();
            const year = now.getFullYear().toString();
            let month: number | string = now.getMonth()+1;
            if (month < 10) {
                month = "0"+month.toString();
            } else {
                month = month.toString();
            }
            let date: number | string = now.getDate();
            if (date < 10) {
                date = "0"+date.toString();
            } else {
                date = date.toString();
            }

            const nowDateString = `${year}-${month}-${date}`;
            const dataList = await this.fetchDataList();
            this.dataList = dataList;
            const latestFileName = this.getLatestDataFileName(this.dataList, nowDateString);
            console.log('Latest file name detected:', latestFileName);
    //        const bankData = MockData.data;
            const bankData = await this.fetchBankData(latestFileName+".json");
            this.bankData = bankData;
            await this.fetchPribor();
            return bankData;
        } catch(e) {
            this.bankService.navigationService?.crashTheApp("Obsah nelze zobrazit: nepovedlo se nám stáhnout data klíčová pro správný chod aplikace.")
            console.error(e);
        }
    }

    async fetchPribor() {
        const endpoint = `${this.dataEndpoint}pribor.txt`;
        const fetchedTxtResponse = await fetch(endpoint);
        const text = await fetchedTxtResponse.text();
        const interestingText = text.split("n|PRIBID|PRIBOR")[1];
        const parsedInterestingText = interestingText.split("\n");
        const result = parsedInterestingText.map(item => {
            if (!item) {
                return "";
            }

            const data = item.split("||");
            const itemResult = {Typ: data[0], Sazba: parseFloat(data[1].replace(",", "."))};
            return itemResult;
        }).filter(item => !!item);
        this.priborRates = result;
    }

    async fetchDataList() {
        const endpoint = `${this.dataEndpoint}${this.dataListName}?randomquerystring=${Math.random().toString()}`;
        const result = await (await fetch(endpoint)).json();
        console.log('Fetched data list');
        return result.list;
    };

    getLatestDataFileName(dataList: string[], nowDateString: string):string {
        // Sort them descending so that we find the FIRST item that is OLDER than
        // today's date easily
        const sorted = dataList.sort().reverse();
        let result = sorted.find(item => {
            return nowDateString >= item;
        })

        if (!result) {
            result = sorted[0];
        }

        return result;
    }

    async fetchBankData(fileName: string) {
        const endpoint = `${this.dataEndpoint}${fileName}`;
        const result = await (await fetch(endpoint)).json();
        console.log('Successfully fetched bank data');
        return result.data;
    }
}