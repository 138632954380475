import {observer} from "mobx-react-lite";
import { action } from 'mobx';

type InputWithSliderProps = {
    placeholder: string,
    observable: any,
    field: string
    onChangeCallback?: Function,
    defaultDisabled?: boolean,
    disableBtn?: boolean,
    errorMessage?: string
    dontShowSlider?: boolean,
    textAfterInput?: string,
    showDecimals?: boolean,
    onBlurCallback?: Function
    type?: string
}

export const TextInput = observer((props:InputWithSliderProps) => {


    const changeValue = action((value: React.ChangeEvent<HTMLInputElement>, observable: any, field: any) => {
        doChangeValue(value, observable, field);
        if (props.onChangeCallback) {
            props.onChangeCallback();
        }
    })

    const onBlur = action((value: React.ChangeEvent<HTMLInputElement>, observable: any, field: any) => {
        doChangeValue(value, observable, field);
        if (props.onBlurCallback) {
            props.onBlurCallback();
        }
    })

    const doChangeValue = action((event: React.ChangeEvent<HTMLInputElement>, observable: any, field: any) => {
        const value = event.target.value;
        observable[field] = value;
    })


    return (
        <div className='input-text'>
                <span className='input-wrapper'>
                    <input
                        type= {props.type ? props.type : 'text'}
                        placeholder={props.placeholder}
                        defaultValue={props.observable[props.field]}
                        onChange={props.onChangeCallback ? (e) => changeValue(e, props.observable, props.field) : () => {}}
                        onBlur={(e) => onBlur(e, props.observable, props.field)}
                    >
                    </input>
                    {props.textAfterInput && 
                        <span className='text-after-input'>
                            {props.textAfterInput}
                        </span>
                    }          
                </span>
        </div>
    )
})