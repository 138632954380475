import { action, observable } from "mobx";
import { history } from "../../components/FrontendIndex";
import { Provisioning } from "../../constants/types";

export class NavigationService {
    domainSettings?: Provisioning;
    errorMessage = observable({message: ""});
    defaultErrorMessage = "Požadovaný obsah nelze zobrazit";
    selectedPageByQueryParam = observable({page: ""});

    crashTheApp = action((message?: string) => {
        if (message && !this.errorMessage.message) {
            this.errorMessage.message = message ? message : this.defaultErrorMessage;
        }
        history.push('error');
    })

    navigateToPage(path: string, messageOnFailure?: string) {
        const canNavigate = this.checkIfPathIsAllowed(path);
        if (canNavigate) {
            history.push(`${path}?randomString=${Math.floor(Math.random()*1000)}`);
        } else {
            this.crashTheApp(messageOnFailure ? messageOnFailure : this.defaultErrorMessage);
        }
    }

    checkIfPathIsAllowed(path: string) {
        if (!this.domainSettings) {
            // We did not get settings, supposedly we're
            // not in iframe
            return true;
        } else {
            return this.domainSettings.allowedPages.includes(path);
        }
    }
}