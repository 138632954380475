import {observer} from "mobx-react-lite";
import { action, observable } from 'mobx';

type CheckBoxProps = {
    observable: any,
    field: string,
    onChangeCallback?: Function,
}


export const CheckBoxInput = observer((props: CheckBoxProps) => {

    const changeValue = action((e: any) => {
        const selectedValue = e.target.checked as boolean;
        props.observable[props.field] = selectedValue;
        if (props.onChangeCallback) {
            props.onChangeCallback();
        }
    })

    return (
        <div className='checkbox'>
            <input type={'checkbox'} onChange={(e) => changeValue(e)} checked={props.observable[props.field]}>
            </input>
        </div>
    )
})