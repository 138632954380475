export const globalVariables = [
    {
        variableName: 'nad1MKc',
        mustPassConditions: 'all', // atLeastOne, none
        conditions: [
            {
                field: ['mortgageParams', 'amount'], // field in userInfo
                comparisonType: 'greaterOrEqualThan', // greaterOrEqualThan, lessOrEqualThan, exact, differentFrom
                conditionValue: '1000000'
            }
        ]
    },
    {
        variableName: 'refin',
        mustPassConditions: 'all', // all, atLeastOne, none
        conditions: [
            {
                "field": ["mortgageParams", "purpose"],
                "comparisonType": "exact",
                "conditionValue": "Refinancování"
            }
        ]
    },
    {
        variableName: 'koupe',
        mustPassConditions: 'all', // all, atLeastOne, none
        conditions: [
            {
                "field": ["mortgageParams", "purpose"],
                "comparisonType": "exact",
                "conditionValue": "Koupě"
            }
        ]
    },
    {
        variableName: 'vystavba',
        mustPassConditions: 'all', // all, atLeastOne, none
        conditions: [
            {
                "field": ["mortgageParams", "purpose"],
                "comparisonType": "exact",
                "conditionValue": "Výstavba"
            }
        ]
    },
    {
        variableName: 'pod4MKc',
        mustPassConditions: 'all', // all, atLeastOne, none
        conditions: [
            {
                field: ['mortgageParams', 'amount'], // field in userInfo
                comparisonType: 'lessOrEqualThan', // greaterOrEqualThan, lessOrEqualThan, exact, differentFrom
                conditionValue: '4000000'
            }
        ]
    },
    {
        variableName: 'standardniHypoteka',
        mustPassConditions: 'all', // all, atLeastOne, none
        conditions: [
            {
                field: ['mortgageParams', 'mortgageType'], // field in userInfo
                comparisonType: 'exact', // greaterOrEqualThan, lessOrEqualThan, exact, differentFrom
                conditionValue: 1
            }
        ]
    },
    {
        variableName: 'americkaHypoteka',
        mustPassConditions: 'all', // all, atLeastOne, none
        conditions: [
            {
                field: ['mortgageParams', 'mortgageType'], // field in userInfo
                comparisonType: 'exact', // greaterOrEqualThan, lessOrEqualThan, exact, differentFrom
                conditionValue: 2
            }
        ]
    },
    {
        "variableName": "global_ltvDo80",
        "mustPassConditions": "all",
        "conditions": [
            {
                "field": ["mortgageParams", "ltv"],
                "comparisonType": "lessOrEqualThan",
                "conditionValue": "80"
            }
        ]
    },
    {
        "variableName": "global_finalAgeDo70",
        "mustPassConditions": "all",
        "conditions": [
            {
                "field": ["mortgageParams", "finalAge"],
                "comparisonType": "lessOrEqualThan",
                "conditionValue": "70"
            }
         ]
    },
    // NOTE: the fact that they are in an array means that this will result in the "_OR_" composed variable
    [
        {
            "variableName": "global_DTIdo8.5",
            "mustPassConditions": "all",
            "conditions": [
                {
                    "field": ["mortgageParams", "dti"],
                    "comparisonType": "lessOrEqualThan",
                    "conditionValue": "8.5"
                }
            ]
        },
        {
            "variableName": "global_DTIDo9.5VekDo36Let",
            "mustPassConditions": "all",
            "conditions": [
                {
                    "field": ["mortgageParams", "dti"],
                    "comparisonType": "lessOrEqualThan",
                    "conditionValue": "9.5"
                },
                {
                    "field": ["userInfo", "age"],
                    "comparisonType": "lessOrEqualThan",
                    "conditionValue": "35"
                }
            ]
        }
    ]
]