import {observer} from "mobx-react-lite";
import { action, observable } from 'mobx';

type SelectProps = {
    options: {
        value: any
        label: string | number
    }[],
    observable: any,
    field: string,
    onChangeCallback?: Function,
    textAfterInput?: string
}


export const Select = observer((props:SelectProps) => {

    const changeValue = action((e: any) => {
        const selectedValue = e.target.value as any;
        props.observable[props.field] = selectedValue;
        console.log("Selected:", selectedValue);
        if (props.onChangeCallback) {
            props.onChangeCallback(e);
        }
    })

    return (<>
        <div className='select print-hidden'>
            <select onChange={(e) => changeValue(e)} value={props.observable[props.field]}>
                {props.options.map(option => {
                    return <option value={option.value} key={option.value}>
                        {option.label}
                    </option>
                })}
            </select>
            {props.textAfterInput &&
                <span className='text-after-input'>
                    {props.textAfterInput}
                </span>
            }
        </div>
        <div className="print-visible">
            {props.observable[props.field]}
            {props.textAfterInput &&
                <span className='text-after-input'>
                    {props.textAfterInput}
                </span>
            }
        </div>
    </>)
})