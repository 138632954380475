import { PDFDocument, rgb, StandardFonts  } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit'
import logo from '../../assets/hypokalk_logo.jpg';
//import UbuntuRegular from '../../assets/Ubuntu-Regular.ttf';
//import UbuntuBold from '../../assets/Ubuntu-Bold.ttf';
import UserInfo from "../../services/UserInfo.service";
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';


interface LetterParams {
    originalFutureAmount: number,
    originalInstallment: number,
    newFutureAmount: number,
    newInstallment: number,
    originalIRate: number | string,
    newIRate: number | string,
    newBank: string,
    insurance: boolean,
    sumOfFeesOriginal: number,
    sumOfFeesNew: number
}

export class LetterForArgumentationService {

    irateToString(irate: number | string) {
        if (typeof irate === 'number') {
            irate = Math.round(irate*100)/100;
        }
        irate = irate.toString();
        irate = irate.replace('.',',');
        if (irate.indexOf(',') === -1) {
            irate += ',';
        }

        while (irate.split(',')[1].length < 2) {
            irate += '0';
        };
        // Make sure the irate has 4 characters max (e.g. 1,23)
        return irate.slice(0,4);
    };

    formatWholeNumberToDisplay(number: number) {
        number = Math.round(number);
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    generateLetter(input: LetterParams, userInfo: UserInfo) {
        // většinu chci přečíst z mortgage params asi?
        // a do inputů dát jen original a new hodnoty?
        const {amount, due, fixation, valueOfSecurity} = userInfo.mortgageParams;

        const rateDifference = (typeof input.originalIRate === "number" ? input.originalIRate : parseFloat(input.originalIRate.replace(',', '.'))) - 
            (typeof input.newIRate === "number" ? input.newIRate : parseFloat(input.newIRate.replace(',', '.')));

        const originalIrateToDisplay = this.irateToString(input.originalIRate);
        const newIrateToDisplay = this.irateToString(input.newIRate);
        const irateDiffToDisplay = this.irateToString(rateDifference);

        const sumOfOriginalInstallments = Math.round(input.originalInstallment*fixation*12);
        const sumOfNewInstallments = Math.round(input.newInstallment*fixation*12);

        const advantage = Math.round((sumOfOriginalInstallments - sumOfNewInstallments) + (input.originalFutureAmount - input.newFutureAmount));
        const sumOfFeesNew = input.sumOfFeesNew;

        return `
Vážení, \n 
byli jsme požádáni o analýzu současné úrovně úrokových sazeb hypotečních úvěrů na bydlení pro účely porovnání navržené sazby. Pracovali jsme s těmito parametry: \n 
Zbývající splatnost úvěru: ${userInfo.mortgageParams.due}
Délka fixace v letech: ${fixation}
Objem úvěru: ${amount} Kč
Hodnota zástavy: ${valueOfSecurity} Kč
Měsíční příjmy: ${userInfo.userInfo.income} Kč
Se slevou za pojištění: ${input.insurance ? 'ano' : 'ne'}
Navrhovaná úroková sazba: ${originalIrateToDisplay} % \n 
Dle poskytnutých informací a aktuální nabídky úrokových sazeb se domníváme, že pro daný případ je možné na trhu získat sazbu ${newIrateToDisplay} % (${input.newBank}), což je o ${irateDiffToDisplay} p. b. sazba nižší, než jste navrhli.
V průběhu fixace by tak na základě Vašeho návrhu došlo k zaplacení ${this.formatWholeNumberToDisplay(sumOfOriginalInstallments)} Kč, přičemž na konci fixace by byla zbývající jistina ${this.formatWholeNumberToDisplay(input.originalFutureAmount)} Kč. Alternativní nabídka vychází po celou dobu fixace na ${this.formatWholeNumberToDisplay(sumOfNewInstallments)} Kč splátek při ${this.formatWholeNumberToDisplay(input.newFutureAmount)} Kč zbývající jistiny. Na konci fixace tak činí rozdíl ${this.formatWholeNumberToDisplay(advantage)} Kč. Věříme, že jste schopni svoji nabídku ještě vylepšit. Pokud ne, rádi na tento případ doporučíme vhodné zprostředkovatele, kteří dokáží najít zajímavější nabídku.  \n 
S pozdravem
Tým Hypokalk
`
/*
Pro: ČSOB Datum: 24.6.2022
Vážení,
byli jsme požádáni o analýzu aktuální úrovně úrokových sazeb hypotečních úvěrů na
bydlení pro účely porovnání navržené sazby. Pracovali jsme s těmito parametry:
Zadavatel: mjurek Délka fixace v letech: 3
Objem úvěru: 1 000 000 Kč Hodnota zástavy: 2 000 000 Kč
Měsíční příjmy: 1 000 000 Kč Se slevou za pojištění: ano
Dle poskytnutých informací a aktuální nabídky úrokových sazeb se domníváme, že pro
daný případ je možné na trhu získat sazbu 4,29 % (Česká spořitelna), což je o 5,71 p. b.
sazba nižší, než jste navrhli. Úspora výdajů na zaplacené úroky by se tak jen v prvním 
roce mohla pohybovat okolo 57 100 Kč. Věříme, že jste schopni svoji nabídku ještě vylepšit. 
Pokud ne, rádi na tento případ doporučíme vhodné zprostředkovatele, kteří dokáží najít 
zajímavější nabídku.
S pozdravem
Tým Hypokalk
Upozornění: Tento dokument není nabídkou ke sjednání spotřebitelského úvěru. Společnost Hypokalk
s. r. o. poskytuje služby rešerží a analýz trhu. Úroveň sazeb na trhu byla zjištěna z dostupných
nabídkových sazeb a závisí na informacích dodaných zadavatelem
*/


    }

    generateDisclaimer() {
        return 'Upozornění: Tento dokument není nabídkou ke sjednání spotřebitelského úvěru. Společnost Hypokalk s. r. o. poskytuje služby rešerží a analýz trhu. Úroveň sazeb na trhu byla zjištěna z dostupných nabídkových sazeb a závisí na informacích dodaných zadavatelem.'
    }

    generateDateString() {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth()+1;
        const day = now.getDate();
        return `${day}. ${month}. ${year}`;
    }
}