import { isSafari } from "react-device-detect";
import { Tooltip as ReactTooltip } from "react-tooltip";

export const PrintButton = () => {
    function tooltipsSection() {
        return <>
            <ReactTooltip
                id='safari'
                place="top"
                content="Tisk je optimalizován pro prohlížeč Chrome. Na jiných prohlížečích nemusí správně fungovat."
                className="tooltip"
            />
        </>
    }

    return <>
        <button
            className={`print-hidden print-button ${isSafari ? 'safari' : ''}`}
            data-tooltip-id={`${isSafari ? 'safari' : ''}`}
            onClick={() => {
                window.print()
            }}
        >
            Tisk
        </button>
        {tooltipsSection()}
    </>
}