import { possibleBasicInvestmentParams, InvestmentParams, StringInvestmentParams, AnyTypeInvestmentParams, InvestmentScheduleParams, InvestmentScheduleResult, RepaymentScheduleResult } from '../../constants/types';
import { numberOfIterationsForComupingInterestRate } from '../../constants/constants';
import { sanitizeInput } from '../../helpers/helpers';

export default class InvestmentMathService {

    calcInvestmentViaMissingParam(input: InvestmentParams | StringInvestmentParams) {
        /*
        Vstupy a výstupy:
        - výše počáteční investice
        - výše pravidelné platby
        - investiční horizont
        - předpokládaný výnos
        - hodnota na konci
        Vždy potřebuju 4 tyhle věci a dopočítám z nich tu 5.
        */

        const numericInput: {[key: string]: number} = {};
        Object.keys(input).forEach((key) => {
            // @ts-ignore
            if (typeof input[key] !== "string" && typeof input[key] !== 'number') {
                numericInput[key] = NaN;
            } else {
                // @ts-ignore
                numericInput[key] = sanitizeInput(input[key]);
            }
        })

//        console.log('Calc Investment via missing param:', numericInput);
        const paramToCalc = this.determineParamToCalc(numericInput);
        let result: any;
//        console.log("Param to calc:", paramToCalc);
        switch (paramToCalc) {
            case 'installment':
                result = this.calcAnuity(numericInput); // DONE ?
                break;
            case 'iRate':
                result = this.calcInterestRate(numericInput, true);
                break;
            case 'due':
                result = this.calcHorizon(numericInput); // DONE
                break;
            case 'presentValue':
                result = this.calcPresentValue(numericInput); // DONE
                break;
            case 'futureValue':
                result = this.calcFutureValue(numericInput); // DONE
                break;
            default:
                break;
        }
        
//        console.log('Calc result:', result);
        return result;
    }

    calcPresentValue(input: InvestmentParams) : number {
        const iRate = input.iRate!;
        const iRateIndex = this.getMonthlyIrateIndexFromAnnualIrate(iRate);
        const due = input.due!*12;
        const installment = input.installment!;
        const futureValue = input.futureValue!;

        const presentValueOfFutureValue = futureValue / (Math.pow(iRateIndex , due!) );

        const presentValueOfInstallments = (installment/(iRateIndex - 1))  * (1 - Math.pow(iRateIndex , -due!) ) 

        const result = presentValueOfFutureValue - presentValueOfInstallments;

        if (result < 0 || isNaN(result)) {
            throw "Present value is out of range"
        }

        return Math.round(result);
    }

    calcHorizon(input: InvestmentParams) : number {
        const paymentsAtTheEndOfTheMonth = 1;
        const amount = input.futureValue!;
        const installment = input.installment!;
        let presentValue = input.presentValue!;
        const iRate = input.iRate!;
        let result = 0;

        let oldPresentValue = presentValue;

        const iRateIndex = this.getMonthlyIrateIndexFromAnnualIrate(iRate);

        while (presentValue < amount) {
            result++;
            presentValue = presentValue*iRateIndex + installment;
            if (Math.floor(presentValue) < oldPresentValue || result > 480 || result < 0) {
                throw new Error('Can not calculate due!')
            }
            oldPresentValue = presentValue;
        }

        return Math.floor((result-1)/12+1);
    }

    calcAnuity(input: InvestmentParams) : number {
        // https://www2.karlin.mff.cuni.cz/~portal/fin_mat/?page=anuita
        /*
        s = V*i/(1-(1+i)^-1)
        */

        const futureValue = input.futureValue!;
        const due = input.due!*12;
        let presentValue = input.presentValue!;
        const iRate = input.iRate!;
        const iRateIndex = this.getMonthlyIrateIndexFromAnnualIrate(iRate);

        const presentValueOfFutureValue = futureValue / (Math.pow(iRateIndex , due!) );
        const combinedPresentValue = presentValueOfFutureValue - presentValue;

        if (!iRate) {
            // iRate is 0
            const result = Math.round(combinedPresentValue!/due);
            return result;
        }
        // Calc installment formula
        const result = combinedPresentValue!*(iRateIndex - 1) / (1 - Math.pow(iRateIndex, -due) ) ;

        if (result < 0 || isNaN(result)) {
            throw "Anuity out of range";
        }

        return Math.round(result);
    }

    calcInterestRate(input: InvestmentParams, shouldReturnRPSN?: boolean) : number {

        // see https://www.gpf.cz/jak-vypocitat-rpsn-rocni-procentni-sazba-nakladu
        // interst rate and RPSN will use the same formula for now

        const futureValue = input.futureValue!;
        const due = input.due!*12;
        let presentValue = input.presentValue!;
        const iRate = input.iRate!;
        const iRateIndex = this.getMonthlyIrateIndexFromAnnualIrate(iRate);
//        const due = input.due;
//        const installment = input.installment;

        let currentEstimate = 0.05;
        const estimateHistory = [-1, 1];
        let sumHelper = this.calcInterestRateSumHelper(input, currentEstimate);
        let tries = 0;

        while (tries < 50 /*numberOfIterationsForComupingInterestRate*/) {
            tries++;
            // First process the latest estimate by pushing it into the history
            estimateHistory.push(currentEstimate);
            estimateHistory.sort(function(a, b) {
                return a - b;
            });
// soucasna vyse budhodnoty - soucasna vyse plateb = pocatecni vklad
// soucasna vyse budhodnoty - soucasna vyse plateb - pocatecni vklad = 0
// 

            // The decide the next estimate
            const resultDiff = sumHelper + presentValue;
            if (resultDiff < 0) {
                // we have to raise the estimate
                const currentEstimateIndex = estimateHistory.indexOf(currentEstimate);
                if (currentEstimateIndex === estimateHistory.length-1) {
                    currentEstimate = currentEstimate*2;
                } else {
                    currentEstimate = (estimateHistory[currentEstimateIndex] + estimateHistory[currentEstimateIndex+1]) / 2
                }
            } else {
                // we have to lower the estimate
                const currentEstimateIndex = estimateHistory.indexOf(currentEstimate);
                if (currentEstimateIndex === 0) {
                    currentEstimate = currentEstimate/2;
                } else {
                    currentEstimate = (estimateHistory[currentEstimateIndex] + estimateHistory[currentEstimateIndex-1]) / 2
                }
            }

            // Then calc the next interest rate helper
            sumHelper = this.calcInterestRateSumHelper(input, currentEstimate);
        }

        if (currentEstimate < - 0.195 || currentEstimate > 0.95) {
            throw new Error('Estimate is too big/small')
        }

        if (shouldReturnRPSN) {
            return Math.round(currentEstimate*10000)/100
        } else {
            /*
            NOTE: the result is now basically RPSN. To transform it to the interest rate, we need to
            un-pow it and multiply by 12 (months)
            If we ever wanted to get RPSN by 
            */
            return Math.round((Math.pow(1+currentEstimate, 1/12)-1)*12*10000)/100;
        }
    }

    calcInterestRateSumHelper(input: InvestmentParams, estimatedRate: number) : number {
        // NOTE: if paymentsAtTheEndOfTheMonth are true, then we will take that into
        // account in the current value computed below
        // TODO: discuss with Pepa what how we want to set this
        const paymentsAtTheEndOfTheMonth = 1;

        const due = input.due!*12;

        const estimatedRateIndex = this.getMonthlyIrateIndexFromAnnualIrate(estimatedRate*100);
        let result = - input.futureValue!/Math.pow(1+estimatedRate, input.due!);

        const installment = input.installment!;

        for (let i = 1; i <= due; i++) {
            result += installment/Math.pow(estimatedRateIndex, i)
        };
        return result;
    }

    determineParamToCalc(input: InvestmentParams) : keyof InvestmentParams | '' | undefined  {
        let result: keyof InvestmentParams | '' = '';
        possibleBasicInvestmentParams.forEach(item => {
            if (!input[item] && input[item] !== 0) {
                if (result) {
                    console.error("Got invalid params", input);
                    return '';
                } else {
                    result = item;
                }
            }
        })
        return result;
    }

    calcFutureValue(numericInput: InvestmentParams) {
        if (
            numericInput.due === undefined ||
            numericInput.iRate === undefined ||
            numericInput.installment === undefined ||
            numericInput.presentValue === undefined
        ) {
            return;
        }

        let futureValue = numericInput.presentValue;

        const irateIndex = this.getMonthlyIrateIndexFromAnnualIrate(numericInput.iRate);
        for (let i = 0; i < numericInput.due*12; i++) {
            futureValue = ((futureValue * irateIndex) + numericInput.installment );
        }

        if (futureValue < 0 || isNaN(futureValue)) {
            throw "Future value out of range";
        }

        return Math.round(futureValue);
    }

    getMonthlyIrateIndexFromAnnualIrate(yearlyIrate: number) {
        return (
           Math.pow((1+ (yearlyIrate / 100)), 1/12)
        )
    }

    calcInvestmentSchedule(input: InvestmentScheduleParams) {
        const result: InvestmentScheduleResult[] = [];
        const {due, rent, rentGrowth, installment, iRate, presentValue, yearlyInvestment} = input;
        const iRateIndex = this.getMonthlyIrateIndexFromAnnualIrate(iRate);
        let currentPresentValue = presentValue;
        let currentInvestment = presentValue;
        let currentRentValue = rent;
        const rentGrowthIndex = this.getMonthlyIrateIndexFromAnnualIrate(rentGrowth);
        for (let i = 0; i < due*12; i++) {
            currentRentValue = currentRentValue * rentGrowthIndex;
            const monthlyInvestment = installment - currentRentValue;
            currentPresentValue = currentPresentValue * iRateIndex + monthlyInvestment + ((i+1)% 12 === 0 ? yearlyInvestment : 0);
            const investmentForCurrentMonth = monthlyInvestment + ((i+1)% 12 === 0 ? yearlyInvestment : 0)
            currentInvestment = currentInvestment + investmentForCurrentMonth;
            const currentResult: InvestmentScheduleResult = {
                month: i+1,
                value: currentPresentValue,
                investment: currentInvestment,
                investmentForCurrentMonth
            }
            result.push(currentResult);
        }

        return result;
    }

    addInvestmentValuesToMortgageSchedule(mortgageScheduleResult: RepaymentScheduleResult[], investmentYield: number) {
        const due = mortgageScheduleResult.length;
        const yieldIndex = this.getMonthlyIrateIndexFromAnnualIrate(investmentYield);

        for (let i = 0; i < due; i++) {
            const item = mortgageScheduleResult[i];
            if (i === 0) {
                item.mortgageInvestmentValue = 0;
            } else {
                item.mortgageInvestmentValue = mortgageScheduleResult[i-1].mortgageInvestmentValue * yieldIndex + item.mortgageInvestment;
            }
        }

        return mortgageScheduleResult;
    }
}
