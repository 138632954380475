import {
    defaultDataDomain,
    defaultPhpDomain,
    sanitizedDataEndpointDomainPairs,
    sanitizedPhpEndpointDomainPairs
} from "../constants/constants";
import {
    AnyTypeCompleteMortgageParams,
    InvestmentScheduleResult,
    RepaymentScheduleResult,
    MortgageVsRentResult
} from "../constants/types";

export function determineDataEndpointDomain(sanitizedDomain: string) {
    let result = '';
    Object.keys(sanitizedDataEndpointDomainPairs).forEach((key) => {
        if (sanitizedDomain.includes(key)) {
            result = sanitizedDataEndpointDomainPairs[key]
        }
    })

    if (!result) {
        result = defaultDataDomain;
    }

    return result;
}

export function determinePhpEndpointDomain(sanitizedDomain: string) {
    let result = '';
    Object.keys(sanitizedPhpEndpointDomainPairs).forEach((key) => {
        if (sanitizedDomain.includes(key)) {
            result = sanitizedPhpEndpointDomainPairs[key]
        }
    })

    if (!result) {
        result = defaultPhpDomain;
    }

    return result;
}

export function sanitizeInput (input: string | number): number {
    if (typeof input === 'number') {
        return input;
    } else if (!input) {
        return NaN
    }
    const valueWithoutSpaces = input.replace(/\s/g, '');
    const valueWithDecimals = valueWithoutSpaces.replace(/,/g, '.');
    let num = Number(valueWithDecimals);
    return num;
}


export function checkIfVariablePasses(userInfo: any, variable: any) {

    if (!Array.isArray(variable)) {
        let passes = false;
        const {mustPassConditions, conditions} = variable;
        const passingConditions = conditions.filter((condition: any) => {
            const {comparisonType, field, conditionValue} = condition;
            let fieldValue = userInfo;
            // as the userInfo is observable we have to do it in this shitty way
            field.forEach((fieldName:string) => {
                fieldValue = fieldValue[fieldName]
            });
            let result;

            let sanitizedConditionValue = parseFloat(conditionValue);
            let sanitizedInput;

            if (isNaN(sanitizedConditionValue)) {
                sanitizedInput = fieldValue;
                sanitizedConditionValue = conditionValue;
            } else {
                sanitizedInput = sanitizeInput(fieldValue);
            }

            switch (comparisonType) {
                case "greaterOrEqualThan":
                    result = sanitizedInput >= sanitizedConditionValue;
                    return result;
                case "lessOrEqualThan":
                    result = sanitizedInput <= sanitizedConditionValue;
                    return result;
                case "exact":
                    result = sanitizedInput === sanitizedConditionValue;
                    return result;
                case "differentFrom":
                    return sanitizedInput !== sanitizedConditionValue;
            }
        });
    
        switch(mustPassConditions) {
            case 'all':
                if (passingConditions.length === conditions.length) {
                    passes = true;
                }
                break;
            case 'atLeastOne':
                if (passingConditions.length) {
                    passes = true;
                }
                break;
            case 'none':
                if (!passingConditions.length) {
                    passes = true;
                }
                break;
        }
    
        return passes ? variable.variableName : '';
    } else {
        // obj is array, in that case if one passes the parent passes as well

        const passes = variable.some(subVar => {
            const result = checkIfVariablePasses(userInfo, subVar);
            return result;
        });

        const result = passes ? variable.map(subVar => subVar.variableName).join('_OR_') : '';
        return result;
    }

}

export function checkIfIfStatementPasses(passingVariables: string[], passingGlobalVariables: string[], ifStatement: any):boolean {
    const variablesThatMustPass = typeof ifStatement.variablesThatMustPass === "string" ? ifStatement.variablesThatMustPass.split(",") : ifStatement.variablesThatMustPass;
    const mustPassConditionsMet = !variablesThatMustPass.some((variable: string) => {
        const doesNotPasssAsRegularVar = passingVariables.indexOf(variable) === -1;
        if (doesNotPasssAsRegularVar) {
            // Not passing as regular var, checking if passes as global
            return passingGlobalVariables.indexOf(variable) === -1;
        } else {
            return false
        }
    });

    if (mustPassConditionsMet) {
        if (!ifStatement.then) {
            return true;
        } else {
            return checkIfIfStatementPasses(passingVariables, passingGlobalVariables, ifStatement.then);
        }
    } else {
        if (!ifStatement.else) {
            return false;
        } else {
            return checkIfIfStatementPasses(passingVariables, passingGlobalVariables, ifStatement.else);
        }
    }
}

export function csvJSON(csv: any){
    var lines=csv.split("\n");
    lines = lines.map((line: string) => {
        return line.replace("\r", "")
    })

    var result = [];

    // NOTE: If your columns contain commas in their values, you'll need
    // to deal with those before doing the next step 
    // (you might convert them to &&& or something, then covert them back later)
    // jsfiddle showing the issue https://jsfiddle.net/
    var headers=lines[0].split(",");

    for(var i=1;i<lines.length;i++){

        var obj = {} as any;
        var currentline=lines[i].split(",");

        for(var j=0;j<headers.length;j++){
            obj[headers[j]] = currentline[j];
        }

        result.push(obj);

    }

    return result; //JavaScript object
}

export function getMonthlyIrateIndexFromAnnualIrate(yearlyIrate: number) {
    return (
       Math.pow((1+ (yearlyIrate / 100)), 1/12)
    )
}


export function mergeHypoVsRentResults(
    mortgageScheduleResult: RepaymentScheduleResult[],
    rentScheduleResult: InvestmentScheduleResult[],
    mortgageData: AnyTypeCompleteMortgageParams,
    rentInvestmentData: any
    ): MortgageVsRentResult[] {
    if (mortgageScheduleResult.length !== rentScheduleResult.length) {
        throw new Error("The two arrays are not of the same length")
    }

    const mortgageLeft = sanitizeInput(mortgageData.amount);
    const valueOfProperty = mortgageLeft + rentInvestmentData.presentValue;

    const result: MortgageVsRentResult[] = [{
        currentPropertyValue: valueOfProperty,
        month: 0,
        mortgageLeft: mortgageLeft,
        netValueDifference: 0,
        investmentValue: rentInvestmentData.presentValue,

        netWorthOfInvestment: rentInvestmentData.presentValue,
        netWorthOfMortgage: rentInvestmentData.presentValue,

        mortgageInterestInYear: 0,
        mortgageTaxDeduction: 0,
        sumOfPayments: rentInvestmentData.presentValue,
        upkeepCosts: 0,
    }];

    for (let i = 11; i < mortgageScheduleResult.length; i=i+12) {
        const mortgageResult = mortgageScheduleResult[i];
        const rentResult = rentScheduleResult[i];

        const monthlyResult = {
            month: mortgageResult.month,
            mortgageLeft: sanitizeInput(mortgageResult.amount),
            investmentValue: rentResult.value,
            currentPropertyValue: mortgageResult.currentPropertyValue,
            netValueDifference: 0,

            netWorthOfInvestment: 0,
            netWorthOfMortgage: 0,

            mortgageInterestInYear: mortgageResult.sumOfYearlyInterest,
            mortgageTaxDeduction: mortgageResult.taxDeduction,
            sumOfPayments: mortgageResult.sumOfPayments,
            upkeepCosts: mortgageResult.upkeepCost
        }

        const mortgageNetValue = mortgageResult.currentPropertyValue - mortgageResult.amount + mortgageResult.mortgageInvestmentValue;
        const rentNetValue = rentResult.value;

        const netValueDifference = mortgageNetValue - rentNetValue;

        monthlyResult.netValueDifference = netValueDifference;

        monthlyResult.netWorthOfInvestment = rentResult.value - mortgageResult.mortgageInvestmentValue;

        monthlyResult.netWorthOfMortgage = mortgageResult.currentPropertyValue - mortgageResult.amount;

        if (monthlyResult.netWorthOfInvestment < 0) {
            monthlyResult.netWorthOfMortgage = monthlyResult.netWorthOfMortgage - monthlyResult.netWorthOfInvestment;
            monthlyResult.netWorthOfInvestment = 0;
        }

        result.push(monthlyResult);
    }

    return result;

}

export function numberToStringWithSpaces(number: number) {
    const numberString = number.toString();
    const numberStringWithSpaces = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return numberStringWithSpaces;
}

export function getMostRecentNumericValue(arr: any[], index: number, withIndexDiffs: boolean = false) {
    for (let i = index; i >= 0; i--) {
      if (typeof arr[i] !== undefined && !isNaN(arr[i])) {

        if (withIndexDiffs) {
            const result = arr[i] - (index - i);
            return result;
        }
        return arr[i];
      }
    }
    return undefined;
}

export function sanitizeExportedArrayThatMightHaveMissingPieces(arr: any[]) {
    const result: any[] = [];
    arr.forEach((item, index) => {
        if (item !== undefined && item !== null) {
            result[index] = item
        }
    });

    return result;
}