import { observable } from "mobx";

export class SimpleLeadScreenState {
    state = observable({
        phoneNr: '',
        name: '',
        email: ''
    })

    valid = observable({value: false});

    consentGranted = observable({value: false});

    disclaimerShown = observable({value: false});

    dataSent = observable({value: false});
}