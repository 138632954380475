import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";
import { getAuth } from "firebase/auth";
import { FirebaseData } from "../constants/types";

export class FireBaseService {
    //FIREBASE

    // TODO: Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional

    dbEndpoint = "https://hypokalk-d4901-default-rtdb.europe-west1.firebasedatabase.app/";

    firebaseConfig = {
        apiKey: "AIzaSyCr3_bYsNUzDtJgyywGl3oiX5wdWL3vmp8",
        authDomain: "hypokalk-d4901.firebaseapp.com",
        projectId: "hypokalk-d4901",
        storageBucket: "hypokalk-d4901.appspot.com",
        messagingSenderId: "857766544491",
        appId: "1:857766544491:web:8fb1a937112f2cae37dd2e",
        measurementId: "G-QG4H013R1K",
        databaseURL: this.dbEndpoint
    };

    constructor() {

        const app = initializeApp(this.firebaseConfig);
        this.app = app;
        const database = getDatabase(app);
        this.database = database;
        this.auth = getAuth();
    }

    app: any;
    database: any;
    auth: any;

    writeLeadToDb(data: FirebaseData) {
        const  {name, email, phoneNr, area, date, time, message, userInfo} = data;
 
        const db = getDatabase();
        set(ref(db, `leads/${date?.substring(0,7)}/${date}/${time}`), {
            jmeno: name,
            email,
            telefon: phoneNr,
            kraj: area,
            cas: time,
            vyrizeno: "ne",
            predano_komu: '',
            predano_kdy: '',
            zprava: message,
            uzivatelske_vstupy: userInfo
        });
      }

}
