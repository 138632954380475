import {observer} from "mobx-react-lite";
import { action } from "mobx";
import { useInjection } from "../../../react-binding";
import { BankResult } from "../../../constants/types";
import UserInfo from "../../../services/UserInfo.service";
import { Select } from "../partials/Select";
import { BankService } from "../../../services/Bank/BankService";
import { Bank } from "../../../services/Bank/Bank";

//@observer
export const ComparisonTable = observer(() => {

    const userInfoService = useInjection(UserInfo);
    const bankService = useInjection(BankService);

    const setProductOnBank = action(() => {
        bankService.recomputeAndReturnGeneralOutput(userInfoService)
    });

    const defaultProductValue = [{value: '--', label:'--'}];

    function renderTableRow(rowName: string, fieldPath: string[], textAfterInput?: string, doNotShowIfBonityFails?: boolean) {
        const bankResults = bankService.result.bankResults;
        return <>
            <tr>
                <td>
                    {rowName}
                </td>
                {bankResults.map((result: BankResult, index: number) => {
                    if (result.visible === 0) {
                        return;
                    }

                    if (doNotShowIfBonityFails && !result.passesBonityCheck) {
                        return <td key={index}></td>
                    }

                    let fieldValue: any = result;
                    fieldPath.forEach(field => {
                        fieldValue = fieldValue[field];
                    })
                    let value = (!!fieldValue || fieldValue === 0) ? fieldValue : '';
                    if ((fieldValue || fieldValue === 0) && textAfterInput) {
                        value +=' '+textAfterInput;
                    }
                    return <td key={index}> {value} </td>
                })}
            </tr>
        </>
    }

    function renderSpecialOfferRow(rowName: string, field: string, type: 'bankProducts' | 'insuranceProducts' | 'others') {
        return <tr>
            <td> {rowName} </td>
            {bankService.banks.map((bank: Bank, index: number) => {
                if (bank.data.visible === 0) {
                    return;
                }
                const productModifiers = bank.data.productsModifiers;
                const {bankProducts, insuranceProducts, others} = productModifiers;
                let products;
                switch (type) {
                    case 'bankProducts': products = bankProducts; break;
                    case 'insuranceProducts': products = insuranceProducts; break;
                    case 'others': products = others; break;
                }
                return <td key={`bank_${index}`}>
                    <Select
                        options={defaultProductValue.concat(products.map((item:string) => {
                            return {
                                value: item,
                                label: item
                            }
                        }))
                        }
                        observable = {bank.selectedProducts}
                        field = {field}
                        onChangeCallback = {setProductOnBank}
                    />
                </td>
            })}
        </tr>
    }

    function renderSpecialOfferRows() {
        return <>
            {renderTableRow('Banka', ['name'])}
            {renderSpecialOfferRow('Bankovní produkt', 'bankProduct', 'bankProducts')}
            {renderSpecialOfferRow('Pojištění', 'insuranceProduct', 'insuranceProducts')}
            {renderSpecialOfferRow('Ostatní', 'others', 'others')}
            {renderTableRow('Sazba', ['interestRate'], '%', true)}
            {renderTableRow('Splátka', ['installment'], 'Kč', true)}
            {renderTableRow('Startovní poplatek', ['startingFee'], 'Kč', true)}
            {renderTableRow('Průběžný poplatek', ['monthlyFee'], 'Kč', true)}
            {renderTableRow('RPSN', ['rpsn'], '%', true)}
        </>
    }

    return <>
        <table>
            <tbody>
                {renderSpecialOfferRows()}
            </tbody>
        </table>
    </>
})