import {Route, Router, Switch, Redirect} from "react-router-dom";
import './FrontendIndex.scss';
import { createBrowserHistory } from 'history';
import { SimpleHypoCalc } from "./Alpha/Calcs/SimpleHypoCalc";
import { SimpleInvestCalc } from "./Alpha/Calcs/SimpleInvestCalc";
import { HomeScreen } from "./main/homeScreen/HomeScreen";
import { Srovnavac } from "./Alpha/Calcs/Srovnavac";
import { DataCreationTool } from "./main/DataCreationTool/DataCreationTool";
import { Argumentation } from "./main/Argumentation/Argumentation";
import { GeneralComparison } from "./main/GeneralComparison/GeneralComparison";
import { Error } from "./main/Error/Error";
import { LeadScreen } from "./main/LeadScreen/LeadScreen";
import { SimpleLeadScreen } from "./main/simpleLeadScreen/SimpleLeadScreen";
import { useInjection } from "../react-binding";
import { BankService } from "../services/Bank/BankService";
import { useEffect, useState } from "react";
import { NavigationService } from "../services/NavigationService/NavigationService";
import { defaultProvisioning, devProvisioning } from "../constants/constants";
import { determineDataEndpointDomain, determinePhpEndpointDomain } from "../helpers/helpers";
import { HypoVsRent } from "./main/HypoVsRent/HypoVsRent";
import { AnnuityCalc } from "./Alpha/Calcs/AnnuityCalc";
import { PrintHeader } from "./main/PrintHeader";

export const history = createBrowserHistory();

const FrontEndIndex = () => {
    const bankService = useInjection(BankService) as BankService;
    const navigationService = useInjection(NavigationService) as NavigationService;

    const [initialized, setInilitalized] = useState(false);
/*
    useEffect(() => {
        bankService.initialize(true, navigationService)
    }, []);
*/
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        let backupMailAdress = params.get("leadmail");
        let backupShowBank = params.get("showbank");
        let backupDisclaimerUrl = params.get("disclaimerurl");
        let backupDomainCheck = params.get("backupDomain");
        if (params.get('testing') === 'true') {
            bankService.dataService.testing = true;
        }

/*
EXAMPLE:
https://betakalk.fincalc.cz/index.html?userid=2&page=hypovsnajem&testing=true&showbank&random=1704018852&color=#2fe218&duraharva=#e218bd&jmeno=Martin&telcislo=+420 733 572 860&email=&url=test.fincalc.cz&hypovsnajemnazev=Hypo-Nájem-Super_Kalkulačka
*/

        const page = params.get('page');
        const userId = params.get('userid');
        const barva = params.get('color');
        const secondaryColor = params.get('druhabarva');
        const name = params.get('jmeno');
        const telNumber = params.get('telcislo');
        const email = params.get('email');
        const url = params.get('url');
        const hypovsnajemnazev = params.get('hypovsnajemnazev');
        const annuityCalcName = params.get('annuitycalcname');
        const tableHighlightColor = params.get('tablehighlightcolor');

        const wordPressUIData = {
            name: name,
            telNumber: telNumber,
            email: email,
            url: url,
            hypovsnajemName: hypovsnajemnazev,
            annuityCalcName: annuityCalcName
        }

        const r = document.querySelector(':root') as HTMLElement;

        if (page) {
            navigationService.selectedPageByQueryParam = {page: page};
        }
        if (userId) {
            bankService.dataService.userId = userId;
        }

        if (r) {
            if (barva) {
                r.style.setProperty('--primary-color', barva);
            }

            if (secondaryColor) {
                r.style.setProperty('--secondary-color', secondaryColor);
            } 
            
            if (tableHighlightColor) {
                r.style.setProperty('--table-highlight-color', tableHighlightColor);
            }
        }

        const ancestorOrigins = window.location.ancestorOrigins;
        if (!ancestorOrigins) {
            // This should load the domain provisioning based on backup
            if (backupDomainCheck) {
                processHostName(backupDomainCheck, page);
            }

            // The code below is PROBABLY not needed as the backup domain should
            // handle everything just fine
            const data = defaultProvisioning;
            if (backupMailAdress) {
                data.leadMail = backupMailAdress;
            }

            if (backupShowBank === 'true') {
                data.showBank = true;
            }

            if (backupDisclaimerUrl) {
                data.disclaimerUrl = backupDisclaimerUrl;
            }

            if (backupDomainCheck) {
                const dataEndpoint = determineDataEndpointDomain(backupDomainCheck);
                const phpEndpoint = determinePhpEndpointDomain(backupDomainCheck);
                bankService.dataService.dataEndpoint = dataEndpoint;
                bankService.dataService.phpScriptEndpoint = phpEndpoint;
                bankService.dataService.sanitizedDomain = backupDomainCheck;
            }

            navigationService.domainSettings = data;
            const currentLocation = window.location.pathname.replace("/", "");
            const browserErrorMessage = ("Obsah nelze zobrazit. Váš browser nepodporuje některé funkce aplikace. Zkuste tuto stránku otevřít například v Chrome, Edge a dalších.")
            if (page) {
                navigationService.navigateToPage(page, browserErrorMessage);
            } else {
                if (currentLocation && !data.allowedPages.includes(currentLocation)) {
                    // we had route like /argumentation, but that is not allowed
                    navigationService.crashTheApp(browserErrorMessage);
                }
            }
            bankService.initialize(true, navigationService, wordPressUIData);
            return setInilitalized(true);
        }

        if (!ancestorOrigins.length) {
            // NOT running in IFRAME. This will likely not happen
            // unless testing in localhost
            // In this case we don't want to do anything special really
            // Note that if we dont fetch allowed pages then we are allowed
            // to navigate wherever we want by default

            navigationService.domainSettings = devProvisioning;
            setInilitalized(true);
            if (page) {
                navigationService.navigateToPage(page);
            }
            
            bankService.initialize(true, navigationService, wordPressUIData);
        } else if (ancestorOrigins.length > 1) {
            // We're running in some iframe-inside-inframe scheme?
            // We don't want that... probably
            // Show error for now, if needed deal with this case later
            setInilitalized(true);
            navigationService.crashTheApp();
        } else {
            const origin = ancestorOrigins[0];
            const url = new URL(origin);
            const hostName = url.hostname;
            processHostName(hostName, page);
            bankService.initialize(true, navigationService, wordPressUIData);
        }
    }, []);

    /*
    THIS IS FOR SETTING COLORS BASED ON DOMAIN PROVISIONING - NOT USED FOR NOW
    IF WE EVER DECIDE TO USE THIS, WE WILL HAVE TO MAKE SURE IT WON'T COLLIDE WITH THE
    QUERY PARAMS WE GET FROM PHP USED IN THE WORDPRESS
    */
    function setStyles() {
        const r = document.querySelector(':root') as HTMLElement;
        const comparisonTableHeaderBgColor = navigationService.domainSettings?.styles?.comparisonTableHeaderBgColor;
        if (comparisonTableHeaderBgColor) {
            r.style.setProperty('--primary-color', comparisonTableHeaderBgColor);
        }
    }

    function processHostName(hostName: string, page: string | null) {
        const sanitizedHostName = hostName.replaceAll('.', '_');
        const dataDomainForHostName = determineDataEndpointDomain(sanitizedHostName);
        const phpEndpoint = determinePhpEndpointDomain(sanitizedHostName);
        bankService.dataService.phpScriptEndpoint = phpEndpoint;
        bankService.dataService.dataEndpoint = dataDomainForHostName;
        bankService.dataService.sanitizedDomain = sanitizedHostName;
        const domainCheckEndpoint = `${dataDomainForHostName}domeny/${sanitizedHostName}.json?randomString=${Math.floor(Math.random()*1000)}`;
        fetch(domainCheckEndpoint)
            .then(async data => {
                const sanitizedData = await data.json();
                navigationService.domainSettings = sanitizedData;
//                setStyles();
                const currentLocation = window.location.pathname.replace("/", "");
                if (page) {
                    navigationService.navigateToPage(page);
                } else {
                    if (currentLocation && !sanitizedData.allowedPages.includes(currentLocation)) {
                        // we had route like /argumentation, but that is not allowed
                        navigationService.crashTheApp();
                        throw "Can not access page " + page;
                    }
                }
                setInilitalized(true);
            })
            .catch(e => {
                navigationService.crashTheApp("Obsah nelze zobrazit: nepovedlo se nám stáhnout data klíčová pro správný chod aplikace.");
                setInilitalized(true);
                throw e;
            });
    }
    
    if (!initialized) {
        return (
            <div className="spinnerLoader">
                <div className="spinner">
                    <div className="animation"></div>
                </div>
            </div>
        )
    }
    return(
        <Router history={history}>
            <div className='index'>
                <div className='app'>
                    {/*<HomeNavigationPanel/>*/}
                    <Switch>
                        <Route exact path={"/home"} component={() => <HomeScreen/>}/>
                        <Route exact path={"/"}> 
                            <Redirect to="/home"/>
                        </Route>
                        <Route exact path={"/argumentation"}
                            component={() => <Argumentation/>}/>
                        <Route exact path={"/comparison"}
                            component={() => <GeneralComparison/>}/>
                        <Route exact path={"/lead"}
                            component={() => <LeadScreen/>}/>
                        <Route exact path={"/simplelead"}
                            component={() => <SimpleLeadScreen/>}/>
                        <Route exact path={"/simplehypo"}
                            component={() => <SimpleHypoCalc/>}/>
                        <Route exact path={"/simpleinvest"}
                            component={() => <SimpleInvestCalc/>}/>
                        <Route exact path={"/srovnavac"}
                            component={() => <Srovnavac/>}/>
                        <Route exact path={"/datatool"}
                            component={() => <DataCreationTool/>}/>
                        <Route exact path={"/error"}
                            component={() => <Error/>}/>
                        <Route exact path={("/hypovsnajem")}
                            component={() => <HypoVsRent/>}
                        />
                        <Route exact path={("/annuitycalc")}
                            component={() => <AnnuityCalc/>}
                        />
                    </Switch>
                </div>
            </div>
        </Router>
    )
};

export default FrontEndIndex;