import { NavigationService } from "../../../services/NavigationService/NavigationService"
import { useInjection } from "../../../react-binding"
import { observer } from "mobx-react-lite";

export const Error = observer(() => {
    const navigationService = useInjection(NavigationService);
    const defualtErrorMessage = "Požadovaný obsah nelze zobrazit.";

return <div className="error-page">
        <h1>
            {navigationService.errorMessage.message ? navigationService.errorMessage.message : defualtErrorMessage}            
        </h1>
    </div>
})