import { BankResult } from "../constants/types";
import { BankService } from "./Bank/BankService";
import UserInfo from "./UserInfo.service";
import { observable, action } from "mobx";
import cloneDeep from 'lodash/cloneDeep';

const blankBankResult: BankResult = {
    passesBonityCheck: false,
    interestRate: null,
    name: ''
};

const blankBestFixationsResult: BestFixationsResult = {
    fixation: 0,
    result: blankBankResult
};

export interface BestFixationsResult {
    fixation: number;
    result: BankResult;
};

export default class CheckBestRateService {

    bankService: BankService;

    constructor(bankService: BankService) {
        this.bankService = bankService;
    }

    setBestResultForUserParams = action((data: BankResult) => {
        this.bestResultForUserParams.data = data;
    })
    
    bestResultForUserParams = observable({data: blankBankResult});

    benchmarkBankResult = observable({data: blankBankResult});

    setBestResultAmongFixations = action((data: BestFixationsResult[]) => {
        this.bestResultAmongFixations.data = data;
    })

    bestResultAmongFixations: {data: BestFixationsResult[]} = observable({data: [blankBestFixationsResult]});

    generateBestResultForUserParams(userInfo: UserInfo, useArgumentationSpecificFees?: boolean) {
        const result = this.bankService.recomputeAndReturnGeneralOutput(userInfo, true, useArgumentationSpecificFees);
        const bestResult = this.getBestRateDataFromResult(result, userInfo.mortgageParams.filterBank, userInfo);
        this.generateBenchmanrkResult(userInfo);
        this.setBestResultForUserParams(bestResult);
        return bestResult;
    }

    generateBenchmanrkResult(userInfo: UserInfo) {
        const installment = this.bankService.mortgageMathService.calcMortgageViaMissingParam({
            amount: userInfo.mortgageParams.amount,
            iRate: userInfo.userInfo.benchmarkRate,
            due: userInfo.mortgageParams.due
        });

        const benchmarkFutureValue = this.bankService.mortgageMathService.calcFutureValue({
            amount: userInfo.mortgageParams.amount,
            iRate: userInfo.userInfo.benchmarkRate,
            installment: installment
        }, userInfo.mortgageParams.fixation);

        let benchmarkBankResult : BankResult = {
            passesBonityCheck: true,
            interestRate: parseFloat(userInfo.userInfo.benchmarkRate.replace(",",".")),
            name: userInfo.mortgageParams.filterBank,
            installment: installment,
            startingFee: 0,
            monthlyFee: 0,
            rpsn: parseFloat(userInfo.userInfo.benchmarkRate.replace(",",".")),
            sumOfInstallments: installment*userInfo.mortgageParams.fixation*12,
            futureValue: benchmarkFutureValue,
        }

        this.benchmarkBankResult = {data: benchmarkBankResult};
    }

    generateArrayOfBestRatesForFixationArray(userInfo: UserInfo, fixations: number[]) {
        const due = parseInt(userInfo.mortgageParams.due);
        const result = fixations.filter(fixation => fixation <= due).map(fixation => {
            const deepClonedUserInfo = cloneDeep(userInfo);
            deepClonedUserInfo.mortgageParams.fixation = fixation;
            const result = this.bankService.recomputeAndReturnGeneralOutput(deepClonedUserInfo);

            if (this.bankService.dataService.testing) {
                console.log('result for fixation:', fixation, result);
            }

            const bestRateDataForResult = this.getBestRateDataFromResult(result);

            return {fixation, result: bestRateDataForResult};
        })

        this.setBestResultAmongFixations(result);
        return result;
    }

    getBestRateDataFromResult(result: BankResult[], bankToFilter?: string, userInfo?: UserInfo) {
        let bestResult = result[0];
        result.forEach(resultItem => {
            if (bankToFilter && resultItem.name.toLowerCase() === bankToFilter.toLowerCase()) {
                this.benchmarkBankResult.data = resultItem;
                resultItem.rpsn = Number(userInfo?.userInfo.benchmarkRate);
                this.benchmarkBankResult.data = resultItem;
            }

            if (bestResult.rpsn === undefined ||
                (
//                    resultItem.name.toLowerCase() !== bankToFilter?.toLowerCase() &&
                    resultItem.passesBonityCheck &&
                    resultItem.rpsn !== undefined &&
                    (
                        // COMPARE RPSN HERE...
                        bestResult.rpsn > resultItem.rpsn ||
                        !bestResult.passesBonityCheck
                    )
                )
            ) {
                bestResult = resultItem;
            }
        })

        return bestResult;
    }

}