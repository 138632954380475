// Interest rate is computed by iterating. By this constant we can set how many
// iterations we want to do. Less leads to less accurate result, but also less
// computing power.
export const numberOfIterationsForComupingInterestRate = 20;

export const maxTaxDeductionBase = 150000;

export const defaultDisclaimerUrl = "https://data.hypokalk.cz/disclaimery/zpracovani-ou.html";
export const defaultLeadMail = "info@hypokalk.cz";

export const defaultDataDomain = "https://data.hypokalk.cz/";

export const sanitizedDataEndpointDomainPairs: {[key: string]: string} = {
    "fincalc": "https://data.fincalc.cz/",
    "zajisteninastari": "https://data.fincalc.cz/"
}

export const defaultPhpDomain = "https://phpscripts.hypokalk.cz/";

export const sanitizedPhpEndpointDomainPairs: {[key: string]: string} = {
    "fincalc": "https://phpscripts.fincalc.cz/",
    "zajisteninastari": "https://phpscripts.fincalc.cz/"
}

export const defaultProvisioning = {
    allowedPages: ["argumentation", "comparison", "simplehypo", "lead", "hypovsnajem", "annuitycalc"],
    showBank: false,
    leadMail: defaultLeadMail,
    disclaimerUrl: defaultDisclaimerUrl,
    disclaimerInfo: {
        company: "CHYBA_společnost_není_nastavena",
        ic: "CHYBA",
        address: "CHYBA"
    },
    styles: {
        "comparisonTableHeaderBgColor": "lightgreen"
    }
}

export const devProvisioning = {
    allowedPages: ["argumentation", "comparison", "simplehypo", "lead", "hypovsnajem", "annuitycalc"],
    showBank: true,
    leadMail: defaultLeadMail,
//    disclaimerUrl: defaultDisclaimerUrl,
    disclaimerInfo: {
        company: "Test_společnost a.s.",
        ic: "123456789",
        address: "Český Peking, Ázerbajdžánská 007"
    },
    styles: {
        comparisonTableHeaderBgColor: "lightblue"
    }
}

export const possibleFixations = [
    "Float",
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    15,
    20,
    25,
    30
];

export const comparisonFixations = [
    0,
    1,
    3,
    5,
    7,
    10,
    15
];

export const krajeCR = [
    "",
    "Hlavní město Praha",
    "Jihočeský kraj",
    "Jihomoravský kraj",
    "Karlovarský kraj",
    "Královehradecký kraj",
    "Liberecký kraj",
    "Moravskoslezský kraj",
    "Olomoucký kraj",
    "Pardubický kraj",
    "Plzeňský kraj",
    "Středočeský kraj",
    "Ústecký kraj",
    "Vysočina",
    "Zlínský kraj"
]

export const bankListOptions = [
    {
        value: 'Air Bank',
        label: 'Air Bank'
    },
    {
        value: 'Banka CREDITAS',
        label: 'Banka CREDITAS'
    },
    {
        value: 'Česká spořitelna',
        label: 'Česká spořitelna'
    },
    {
        value: 'ČSOB',
        label: 'ČSOB'
    },
    {
        value: 'Fio banka',
        label: 'Fio banka'
    },
    {
        value: 'Hypoteční banka',
        label: 'Hypoteční banka'
    },
    {
        value: 'Komerční banka',
        label: 'Komerční banka'
    },
    {
        value: 'mBank',
        label: 'mBank'
    },
    {
        value: 'MONETA Money Bank',
        label: 'MONETA Money Bank'
    },
    {
        value: 'Oberbank',
        label: 'Oberbank'
    },
    {
        value: 'Raiffeisenbank',
        label: 'Raiffeisenbank'
    },
    {
        value: 'UniCredit Bank',
        label: 'UniCredit Bank'
    },
    {
        value: 'Jiná',
        label: 'Jiná'
    }



    /*
    Air Bank a.s.
Banka CREDITAS a.s.
Česká spořitelna, a.s.
Československá obchodní banka, a. s.
Fio banka, a.s.
Hypoteční banka, a.s.
Komerční banka, a.s.
mBank S.A., organizační složka
MONETA Money Bank, a.s.
Oberbank AG pobočka Česká republika
Raiffeisenbank a.s.
UniCredit Bank Czech Republic and Slovakia, a.s.
    */
]

export const propertyTypeList = [
    {
        value: 'Byt',
        label: 'Byt'
    },
    {
        value: 'Rodinný dům',
        label: 'Rodinný dům'
    },
    {
        value: 'Stavební pozemek',
        label: 'Stavební pozemek'
    },
    {
        value: 'Rekreační nemovitost',
        label: 'Rekreační nemovitost'
    }
]

export const purposeList = [
    {
        value: 'Koupě',
        label: 'Koupě'
    },
    {
        value: 'Refinancování',
        label: 'Refinancování'
    },
    {
        value: 'Výstavba',
        label: 'Výstavba'
    },
]

export const hypoVsNajemTableData = [
    ["Výhodnost vlastního bydlení", "Hodnota investice v nájmu", "Čistý majetek při hypotéce", "Zbývající jistina hypotéky", "Hodnota nemovitosti", "Roční úrok", "Daňové zvýhodnění", "Celkové platby", "Náklady na nemovitost"],
    ['netValueDifference', 'netWorthOfInvestment', 'netWorthOfMortgage', 'mortgageLeft', 'currentPropertyValue', "mortgageInterestInYear", "mortgageTaxDeduction", "sumOfPayments", "upkeepCosts"],
    ["Kč", "Kč", "Kč", "Kč", 'Kč', 'Kč', 'Kč', 'Kč', 'Kč']
]
export const hypoVsNajemHelperArray = ["Rok", 1, 2, 3, 4, 5]

export const calcDisclaimer = "Výpočty na této kalkulačce jsou orientační a vychází z vámi zadaných parametrů. Nejedná se o nabídkou k uzavření smlouvy, příslib k uzavření smlouvy, odborné poradenství ani jakékoliv jiné právní jednání provozovatele tohoto webu. Dříve než učiníte jakékoliv rozhodnutí ovlivňující vaše osobní či finanční zájmy a situaci, konzultujte je prosím přímo s odborným specialistou.";