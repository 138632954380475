import {observer} from "mobx-react-lite";
import { action, observable } from 'mobx';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Calculator } from 'react-bootstrap-icons'

type InputWithSliderProps = {
    placeholder: string,
    observable: any,
    field: string
    min: number,
    max: number,
    step: number,
    onChangeCallback: Function,
    defaultDisabled?: boolean,
    disableBtn?: boolean,
    errorMessage?: string
    dontShowSlider?: boolean,
    textAfterInput?: string,
    showDecimals?: boolean,
    disabled?: boolean,
    round?: boolean,
    buttonTooltip?: string
}

export const InputWithSlider = observer((props:InputWithSliderProps) => {


    const changeValue = action((value: React.ChangeEvent<HTMLInputElement>, observable: any, field: any) => {
        doChangeValue(value, observable, field);
        props.onChangeCallback(value);
    })

    function doChangeValue(event: React.ChangeEvent<HTMLInputElement>, observable: any, field: any){
        const value = event.target.value;
        const valueWithoutSpaces = value.replace(/\s/g, '');
        let valueWithDecimals = valueWithoutSpaces.replace(/,/g, '.');
        let num = Number(valueWithDecimals);
        if (isNaN(num)) {
            return;
        }

        let result = Number(valueWithDecimals).toLocaleString('cs');
        if (props.showDecimals && result.indexOf(',') === -1) {
            result +=',0';
        } else if (value.endsWith(",")) {
            // The user added "," manually...
            result +=",";
        } else if (value.endsWith(",0")) {
            result +=",0";
        }

        if (result === "0") {
            result = value;
        }
        observable[field] = result;
    }

    const computeThis = action(() => {
        props.observable.computing = props.field;
        props.observable.error = false;
    })

    let disabled = observable({value: props.observable.computing === props.field || props.disabled});

    function tooltipsSection() {
        return <>
            <ReactTooltip
                id="button-tooltip"
                place="top"
                content={props.buttonTooltip ?? "Počítat tento parametr"}
                className="tooltip"
            />
        </>
    }

    return (<>
        <div className={`input-with-slider print-hidden ${disabled.value && props.observable.error ? 'error' :''}`}>
            {disabled.value ? <>
                {props.observable.error ? <>
                    <div className='input-error-message'>
                        {props.observable.error? (props.errorMessage ? props.errorMessage : 'Nelze spočítat') : props.observable[props.field]}
                    </div>
                </>:<>
                    <span className='input-wrapper disabled'>
                        <input
                            type='text'
                            placeholder={props.placeholder}
                            disabled={true}
                            value={props.observable[props.field]}
                            onChange={(e) => {}}
                        >
                        </input>
                        {props.textAfterInput && 
                            <span className='text-after-input'>
                                {props.textAfterInput}
                            </span>
                        }
                    </span>    

                    <span className='input-misc'>
                        {!props.dontShowSlider &&
                            <input
                                type='range'
                                min={props.min}
                                max={props.max}
                                step={props.step}
                                value={Number(props.observable[props.field].replace(/\s/g, '').replace(/,/g, '.'))}
                                onChange={(e) => {}}
                                disabled={disabled.value}
                                className='input-slider'
                            >
                            </input>
                        }
                    </span>
                </>}


                    {/*

                    <span>
                        {props.observable.error? (props.errorMessage ? props.errorMessage : 'Nelze spočítat') : props.observable[props.field]}
                    </span>
                    {props.textAfterInput && !props.observable.error &&
                        <span className='text-after-input'>
                            {props.textAfterInput}
                        </span>
                    }
                    */}
                
            </> : <>
                <span className='input-wrapper'>
                    <input
                        type='text'
                        placeholder={props.placeholder}
                        value={props.observable[props.field]}
                        onChange={(e) => changeValue(e, props.observable, props.field)}
                    >
                    </input>
                    {props.textAfterInput && 
                        <span className='text-after-input'>
                            {props.textAfterInput}
                        </span>
                    }          
                </span>

                <span className='input-misc'>
                    {!props.dontShowSlider &&
                        <input
                            type='range'
                            min={props.min}
                            max={props.max}
                            step={props.step}
                            value={Number(props.observable[props.field].replace(/\s/g, '').replace(/,/g, '.'))}
                            onChange={(e) => changeValue(e, props.observable, props.field)}
                            disabled={disabled.value}
                            className='input-slider'
                        >
                        </input>
                    }
                    {(props.observable.computing && !props.disableBtn && props.observable.computing !== props.field) &&
                        <Calculator
                            onClick={() => computeThis()} className='icon poninter'
                            data-tooltip-id="button-tooltip"
                        />
                    }
                </span>
            </>}
        </div>
        <span className="print-visible">
            {props.observable[props.field]}
            {props.textAfterInput && 
                <span className='text-after-input'>
                    {props.textAfterInput}
                </span>
            }
        </span>
        {tooltipsSection()}
    </>)
})