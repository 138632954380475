import { observable } from "mobx";
import { AnnuityCalcData, SimpleInvestmentData, SimpleMortgageData } from "../constants/types"

export default class UserInfo {

    /*
    IMPORTANT NOTE:
    here will be a place for ALL the bank's bonity rules
    as things like income to installment etc. make sense
    to be shared across all banks, which might
    then add some custom data to it after
    */

    general = observable({
        name: ""
    })

    userInfo = observable({
            income: "40 000",
            age: "21",
            otherInstallments: "0",
            benchmarkRate: "7,99"
        })

    mortgageParams = observable({
        amount: "2 400 000",
        valueOfSecurity: "3 000 000",
        due: "20",
        fixation: 5,
        ltv: "80",
        finalAge: "41",
        dti: "5",
        computing: 'ltv',
        mortgageType: '1',
        propertyType: 'Byt',
        mortgagedPropertyType: 'Byt',
        purpose: 'Refinancování',
        useGenericInsurance: true,
        filterBank: 'Air Bank'
    })

    extraFields: {[key:string]:any} = {

    };

    simpleMortgageData: SimpleMortgageData = observable({
        amount: "2 000 000",
        iRate: "3,00",
        due: "30",
        installment: "8 432",
        computing: 'installment',
        error: false
    });

    annuityCalcData: AnnuityCalcData = observable({
        amount: "2 000 000",
        iRate: "3,00",
        due: "30",
        installment: "8 432",
        computing: 'installment',
        error: false,
        dataMonthly: "false",
        extraAmountsOfPayments: [],
        iRates: [],
        dues: [],
        notes: [],
        startingYear: 0,
//        startingYear: new Date().getFullYear(),
    });

    simpleInvestmentData: SimpleInvestmentData = observable({
        presentValue: "0",
        iRate: "5,00",
        due: "20",
        installment: "1 000",
        futureValue: "405 804",
        computing: 'installment',
        error: false
    });

    mortgageVsRentData = observable({
        // Needed for mortgage evaluation
        valueOfProperty: "6 000 000",
        initialFunds: "1 200 000",
        due: "30",
        iRate: "4,00",
        // Needed for rent evaluation
        rent: "15 000",
        rentGrowth: "2,00",
        investmentYield: "5,00",
        taxRefundRate: "15,00",
        useTaxRefund: true,
        inflation: "2,00",
        propertyCostsGrowth: "2,00",
        propertyValueGrowth: "2,00",
        propertyUpkeepCosts: "30 000"
    });
}
