import {observer} from "mobx-react-lite";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Line, Chart } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  } from 'chart.js'
import { MortgageVsRentResult } from "../../../constants/types";
import { numberToStringWithSpaces } from "../../../helpers/helpers";
import { useState } from "react";
import { hypoVsNajemHelperArray, hypoVsNajemTableData } from "../../../constants/constants";
import { CheckCircle, PencilSquare } from "react-bootstrap-icons";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  )

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
const data = {
  labels: labels,
  datasets: [{
    axis: 'y',
    label: 'My First Dataset',
    data: [65, 59, 80, 81, 56, 55, 40],
    fill: false,
    backgroundColor: [
      'rgba(255, 99, 132, 0.2)',
      'rgba(255, 159, 64, 0.2)',
      'rgba(255, 205, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(201, 203, 207, 0.2)'
    ],
    borderColor: [
      'rgb(255, 99, 132)',
      'rgb(255, 159, 64)',
      'rgb(255, 205, 86)',
      'rgb(75, 192, 192)',
      'rgb(54, 162, 235)',
      'rgb(153, 102, 255)',
      'rgb(201, 203, 207)'
    ],
    borderWidth: 1
  }]
};
const config = {
    type: 'line',
    data: data,
    options: {
      indexAxis: 'y',
      scales: {
        x: {
          beginAtZero: true
        }
      }
    }
  };



interface ComparisonTableProps {
    investmentForRentResult: any,
    mortgageCompleteResult: any,
    overallYearlyResult: MortgageVsRentResult[],
}

//@observer
export const HypoVsRentComparisonTable = observer((props: ComparisonTableProps) => {
    if (!props.investmentForRentResult || !props.mortgageCompleteResult) {
        return <></>
    }
    const [selectedOptions, setSelectedOptions] = useState([0, 1, 2, 3, 4]);

    function tooltipsSection() {
        return <>
            <ReactTooltip
                id={hypoVsNajemTableData[0][0].toString()}
                place="top"
                content="Rozdíl mezi variantou vlastního bydlení oproti variantě nájmu s investicí. Kladná hodnota znamená, že vlastní bydlení je v daném roce výhodnější."
                className="tooltip"
            />

            <ReactTooltip
                id={hypoVsNajemTableData[0][1].toString()}
                place="top"
                content="Ve variantě nájmu jsou investovány prostředky, které by ve variantě bydlení byly použity na nákup nemovitosti, splátky hypotéky atd., ponížené o náklady nájmu."
                className="tooltip"
            />
            <ReactTooltip
                id={hypoVsNajemTableData[0][2].toString()}
                place="top"
                content="Rozdíl mezi hodnotou nemovitosti a zbývající jistinou hypotéky ve variantě vlastního bydlení."
                className="tooltip"
            />
            <ReactTooltip
                id={hypoVsNajemTableData[0][3].toString()}
                place="top"
                content="Hodnota, která zbývá na konci daného roku doplatit u hypotéky ve variantě vlastního bydlení."
                className="tooltip"
            />
            <ReactTooltip
                id={hypoVsNajemTableData[0][4].toString()}
                place="top"
                content="Hodnota nemovitosti na konci daného roku."
                className="tooltip"
            />
            <ReactTooltip
                id={hypoVsNajemTableData[0][5].toString()}
                place="top"
                content="Součet úroků hypotéky za daný rok. Z tého hodnoty se mimo jiné počítá daňové zvýhodnění."
                className="tooltip"
            />
            <ReactTooltip
                id={hypoVsNajemTableData[0][6].toString()}
                place="top"
                content="Daňové zvýhodnění za daný rok ve variantě vlastního bydlení. Jeho podmínkou je dostatečně výsoký daňový základ."
                className="tooltip"
            />
            <ReactTooltip
                id={hypoVsNajemTableData[0][7].toString()}
                place="top"
                content="Součet všech plateb ke konci daného roku. Tato hodnota je pro variantu vlastního bydlení i nájmu stejná."
                className="tooltip"
            />
            <ReactTooltip
                id={hypoVsNajemTableData[0][8].toString()}
                place="top"
                content="Průměrné roční náklady na nemovitost v daném roce ve variantě vlastního bydlení."
                className="tooltip"
            />
        </>
    }

    function renderGraph() {
        const options = {
            plugins: {
                legend: {
                    display: true,
                    labels: {
                        color: 'rgb(0, 0, 0)'
                    }
                },
                title: {
                    display: true,
                    text: 'Porovnání variant',
                    font: {
                        size: 25
                    }
                },
                tooltip: {
                    callbacks: {
                        label: function(context: any) {
                            let label = context.dataset.label || '';
    
                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                label += new Intl.NumberFormat('cs-CS', { style: 'currency', currency: 'CZK', maximumFractionDigits:0 }).format(context.parsed.y);
                            }
                            return label;
                        }
                    }
                }
            }
        }
        const data = {
            labels: props.overallYearlyResult.map((result) => ((result.month)/12).toString()),
            datasets: [
            {
                label: 'Výhodnost vlastního bydlení',
                data: props.overallYearlyResult.map((result) => Math.round(result.netValueDifference)),
                borderColor: 'rgb(49, 180, 76)',
                backgroundColor: 'rgba(49, 180, 76, 0.5)',
                labels: props.overallYearlyResult.map((result) => "Kč"),
            },
            {
                label: 'Hodnota investice v nájmu',
                data: props.overallYearlyResult.map((result) => Math.round(result.netWorthOfInvestment)),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Čistý majetek při hypotéce',
                data: props.overallYearlyResult.map((result) => Math.round(result.netWorthOfMortgage)),
                borderColor: 'rgb(255, 99, 0)',
                backgroundColor: 'rgba(255, 99, 0, 0.5)',
            },
            {
                label: 'Zbývající jistina hypotéky',
                data: props.overallYearlyResult.map((result) => Math.round(result.mortgageLeft)),
                borderColor: 'rgb(194, 222, 36)',
                backgroundColor: 'rgba(194, 222, 36, 0.5)',
                hidden: true,
            },
            {
                label: 'Hodnota nemovitosti',
                data: props.overallYearlyResult.map((result) => Math.round(result.currentPropertyValue)),
                borderColor: 'rgb(49, 128, 166)',
                backgroundColor: 'rgba(49, 128, 166, 0.5)',
                hidden: true,
            },
        ]
        };
        return <div className="graph">
            <Line options={options} data={data} width={"600px"} height={"400px"}/>
        </div>

    }

    function renderOptionHeader(index: number) {
        if (editTable) {
            return <select
                value={selectedOptions[index]}
                onChange={(e) => {
                    const newSelectedOptions = [...selectedOptions];
                    newSelectedOptions[index] = parseInt(e.target.value);
                    setSelectedOptions(newSelectedOptions);
                }}
            >
                {hypoVsNajemTableData[0].map((option, i) => {
                    return <option value={i}>{hypoVsNajemTableData[0][i]}</option>
                })}
            </select>
        } else {
            return <span
                data-tooltip-id={hypoVsNajemTableData[0][selectedOptions[index]]}
            >{hypoVsNajemTableData[0][selectedOptions[index]]}</span>
        }

    }

    const [editTable, setEditTable] = useState(false);

    function renderTable() {
        const result = props.overallYearlyResult;
        return <>
            <table className="m-20">
                <tbody>
                    <tr>
                        {
                            hypoVsNajemHelperArray
                                .map((columnName, index) => {
                                return <th key={columnName}>
                                    {
                                        index === 0 ? <span className="w-full inline-block upper-left-cell">
                                            {editTable ?
                                            <CheckCircle onClick={() => setEditTable(!editTable)} className="icon pointer print-hidden"/>
                                            :
                                            <PencilSquare onClick={() => setEditTable(!editTable)} className="icon pointer print-hidden"/>
                                            }
                                            {columnName}
                                        </span> :
                                        renderOptionHeader(index-1)
                                    }
                                </th>
                        })}
                    </tr>

                    {result.map((resultRow: any, index: number) => {
                        return <tr key={index}>
                            <td>{index}</td>
                                {
                                    selectedOptions.map((optionIndex, columnIndex) => {
                                        return <td key={index+columnIndex}>
                                            {
                                                numberToStringWithSpaces(Math.round(
                                                    resultRow[hypoVsNajemTableData[1][optionIndex]]
                                                ))
                                                + ' ' + hypoVsNajemTableData[2][optionIndex]
                                            }
                                        </td>
                                    })
                                }
                            {
                                /*
                                                            {columnNames.map((columnName: string, columnIndex: number) => {
                                return <td key={index+columnName}>
                                    {numberToStringWithSpaces(Math.round(resultRow[fieldPaths[columnIndex]]))}
                                    {textAfterInputs[columnIndex] ? ' '+textAfterInputs[columnIndex] : ''}
                                </td>
                            })}
                                */
                            }
                        </tr>
                    })}
                </tbody>
            </table>
        </>
    }

    function renderInvestmentTable(columnNames: string[], fieldPaths: string[], textAfterInputs: string[]) {

        const result = props.investmentForRentResult;
        return <>
            <table className="m-20">
                <tbody>
                    <tr>
                        {
                            (["Měsíc"].concat(columnNames))
                                .map((columnName: string) => {
                                return <th key={columnName}> {columnName} </th>
                        })}
                    </tr>

                    {result.map((resultRow: any, index: number) => {
                        return <tr key={index}>
                            <td>{index}</td>
                            {columnNames.map((columnName: string, columnIndex: number) => {
                                return <td key={index+columnName}>
                                    {numberToStringWithSpaces(Math.round(resultRow[fieldPaths[columnIndex]]))}
                                    {textAfterInputs[columnIndex] ? ' '+textAfterInputs[columnIndex] : ''}
                                </td>
                            })}
                        </tr>
                    })}
                </tbody>
            </table>
        </>
    }

    function renderMortgageTable(columnNames: string[], fieldPaths: string[], textAfterInputs: string[]) {

        const result = props.mortgageCompleteResult;
        return <>
            <table className="m-20">
                <tbody>
                    <tr>
                        {
                            (["Měsíc"].concat(columnNames))
                                .map((columnName: string) => {
                                return <th key={columnName}> {columnName} </th>
                        })}
                    </tr>

                    {result.map((resultRow: any, index: number) => {
                        return <tr key={index}>
                            <td>{index}</td>
                            {columnNames.map((columnName: string, columnIndex: number) => {
                                return <td key={index+columnName}>
                                    {numberToStringWithSpaces(Math.round(resultRow[fieldPaths[columnIndex]]))}
                                    {textAfterInputs[columnIndex] ? ' '+textAfterInputs[columnIndex] : ''}
                                </td>
                            })}
                        </tr>
                    })}
                </tbody>
            </table>
        </>
    }


    return <div className="hypo-vs-rent-comparison-table-wrapper">
        <div className="middle table-wrapper">
            {renderTable()}
        </div>
        <div className="graph-wrapper">
            {renderGraph()}
        </div>
        {tooltipsSection()}
    </div>
})