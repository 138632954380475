import {observer} from "mobx-react-lite";
import { useInjection } from "../../../react-binding";
import { InputWithSlider } from "../../Alpha/partials/InputWithSlider";
import UserInfo from "../../../services/UserInfo.service";
import { Select } from "../../Alpha/partials/Select";
import { comparisonFixations } from "../../../constants/constants";
import { MortgageTypesNr, MortgageTypes } from "../../../constants/types";
import { CustomerInfoInput } from "../../Alpha/UserInfoInput/CustomerInfoInput";
import { BankService } from "../../../services/Bank/BankService";
import { useEffect } from "react";
import { CheckBoxInput } from "../../Alpha/partials/CheckBoxInput";
import { BestFixationsResult } from "../../../services/CheckBestRateService";
import { propertyTypeList, purposeList } from "../../../constants/constants";
import { useState } from "react";
import { NavigationService } from "../../../services/NavigationService/NavigationService";
import { calcDisclaimer } from "../../../constants/constants";

//@observer
export const GeneralComparison = observer(() => {

    const bankService = useInjection(BankService);
    const userInfoService = useInjection(UserInfo);
    const navigationService = useInjection(NavigationService);
    const checkBestRateService = bankService.checkBestRateService;

    const [resultCollapsed, setResultCollapsed] = useState(true);

    function recompute() {
        checkBestRateService.generateArrayOfBestRatesForFixationArray(userInfoService, comparisonFixations);
        return;
    }

    function handleGeneralInsuranceCheckboxChangeAndRecompute() {
        bankService.setGeneralInsuranceToAllBanks(userInfoService.mortgageParams.useGenericInsurance);
        recompute();
    }

    function redirectToContactPage() {
        navigationService.navigateToPage('lead');
    }

    useEffect(() => handleGeneralInsuranceCheckboxChangeAndRecompute(), [bankService.bankServiceInitialized.value]);

    function renderTableRow(result: BestFixationsResult) {
        return <tr key={`fixation_${result.fixation}`} >
            <td>
                {result.fixation === 0 ? 'Float' : result.fixation}
            </td>
            <td>
                {result.result?.passesBonityCheck ? `${result.result.interestRate} %` : '--'}
            </td>
            <td>
                {result.result?.passesBonityCheck ? `${(result.result.monthlyFee ?? 0) + (result.result.installment ?? 0)} Kč` : '--'}
            </td>
            {navigationService.domainSettings?.showBank &&
                <td>
                    {result.result?.passesBonityCheck ? `${result.result.name}` : '--'}
                </td>
            }
        </tr>
    }

    function renderBestRateSection() {
        return <div className="best-rates-section">
            <div className="best-rates-title">
                Pro zadané parametry jsme nalezli následující nejvýhodnější nabídky:
            </div>
            <table className="best-rates-table">
                <thead>
                    <tr>
                        <th>Délka fixace v letech</th>
                        <th>Sazba</th>
                        <th>Odhad splátky</th>
                        {navigationService.domainSettings?.showBank &&
                            <th>Banka</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {checkBestRateService.bestResultAmongFixations.data?.map(result =>
                        renderTableRow(result)
                    )}
                </tbody>
            </table>
        </div>
    }

    return (
        <div className='app-screen general-comparison'>

            <h1>Srovnání nabídek sazeb hypotečních úvěrů na bydlení</h1>
            <div>Vyplňte níže uvedené údaje a získáte odhad současné úrovně úrokových sazeb na zadaný případ. Jedná se o nejnižší sazby dosažitelné za běžných podmínek, které jsme dohledali z veřejných úrokových lístků jednotlivých bank. Data pro vás aktualizujeme na denní bázi. V základní sazbě je vždy zahrnuta sleva za využívání účtu u banky, která úvěr poskytne, pokud taková sleva existuje. Sazby se slevami za pojištění jsou kalkulovány zvlášť. Případné další slevy, k jejichž uplatnění je třeba splnit složitější podmínky či mít detailnější informace, ve výpočtu obsaženy nejsou.  </div>
            {
                <div className="buttons-wrapper">
                <button className="comparison-button" onClick={() => setResultCollapsed(!resultCollapsed)}>{`${resultCollapsed ? "Zobrazit výsledek" : "Skrýt výsledek"}`}</button>
                {navigationService.checkIfPathIsAllowed('lead') &&
                    <button className="comparison-button" onClick={() => redirectToContactPage()}>Chci pomoc se sjednáním úvěru</button>
                }
            </div>
            }

            <div className={`result-wrapper collapsible ${resultCollapsed ? "collapsed" : ""}`}>
                 {checkBestRateService.bestResultForUserParams && renderBestRateSection()}
            </div>

            <h3 className="loan-info-title">
                        Vstupní informace
            </h3>

            <div className="inputs-section">

                <div className="general-info block-of-inputs">
                    <div className="field-wrapper">
                        <span className="field-name">
                                Typ hypotéky
                        </span>
                        <Select
                            options={[
                                {
                                    value: MortgageTypesNr.standard,
                                    label: MortgageTypes.standard
                                },
                                {
                                    value: MortgageTypesNr.american,
                                    label: MortgageTypes.american
                                }
                            ]
                            }
                            observable = {userInfoService.mortgageParams}
                            field = "mortgageType"
                            onChangeCallback = {recompute}
                        />
                    </div>

                    {userInfoService.mortgageParams.mortgageType === MortgageTypesNr.standard.toString() && <>
                        <div className="field-wrapper">
                            <span className="field-name">
                                    Účel:
                            </span>
                            <Select
                                options={purposeList}
                                observable = {userInfoService.mortgageParams}
                                field = "purpose"
                                onChangeCallback = {recompute}
                            />
                        </div>
                        
                        <div className="field-wrapper">
                            <span className="field-name">
                                    Financovaná nemovitost:
                            </span>
                            <Select
                                options={propertyTypeList}
                                observable = {userInfoService.mortgageParams}
                                field = "propertyType"
                                onChangeCallback = {recompute}
                            />
                        </div>
{/*
                        <div className="field-wrapper">
                            <span className="field-name">
                                    Zastavená nemovitost:
                            </span>
                            <Select
                                options={propertyTypeList}
                                observable = {userInfoService.mortgageParams}
                                field = "mortgagedPropertyType"
                                onChangeCallback = {recompute}
                            />
                        </div>
*/}
                    </>}
                </div>
                <div className="general-info block-of-inputs">
                    <div className="field-wrapper">
                        <span className="field-name">
                            Výše úvěru
                        </span>
                        <InputWithSlider
                            onChangeCallback = {recompute}
                            placeholder = "Výše hypotéky"
                            observable = {userInfoService.mortgageParams}
                            field = "amount"
                            min = {100000}
                            max = {12000000}
                            step = {100000}
                            textAfterInput = " Kč"
                            disableBtn = {true}
                        />
                    </div>

                    <div className="field-wrapper">
                        <span className="field-name">
                            Hodnota zástavy
                        </span>
                        <InputWithSlider
                            onChangeCallback = {recompute}
                            placeholder = "Hodnota zástavy"
                            observable = {userInfoService.mortgageParams}
                            field = "valueOfSecurity"
                            min = {200000}
                            max = {10000000}
                            step = {50000}
                            textAfterInput = " Kč"
                            disableBtn = {true}
                        />
                    </div>

                    <div className="field-wrapper">
                        <span className="field-name">
                            Splatnost v letech
                        </span>
                        <InputWithSlider
                            onChangeCallback = {recompute}
                            placeholder = "Splatnost v letech"
                            observable = {userInfoService.mortgageParams}
                            field = "due"
                            min = {1}
                            max = {30}
                            step = {1}
                            textAfterInput = " let"
                            disableBtn = {true}
                        />
                    </div>
                    <div className="field-wrapper  checkbox-wrapper">
                        <span className="field-name">
                            Sazba obsahuje i slevu za pojištění, která jsem ochoten uzavřít
                        </span>
                        <CheckBoxInput 
                            observable={userInfoService.mortgageParams}
                            onChangeCallback={handleGeneralInsuranceCheckboxChangeAndRecompute}
                            field='useGenericInsurance'
                        />
                    </div>
                    </div>
            </div>
            <div className="general-info block-of-inputs">
                <CustomerInfoInput recompute={recompute}></CustomerInfoInput>
            </div>

            <div className="calc-disclaimer">{calcDisclaimer}</div>
        </div>
    )
})