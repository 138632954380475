import { observable } from "mobx";
import { FirebaseData } from "../../../constants/types";


export class LeadScreenState {

    state: FirebaseData = observable({
        area: '',
        phoneNr: '',
        name: '',
        email: ''
    })

    valid = observable({value: false});

    consentGranted = observable({value: false});

    disclaimerShown = observable({value: false});

    dataSent = observable({value: false});
}