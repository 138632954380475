import {observer} from "mobx-react-lite";
import { action } from "mobx";
import { useInjection } from "../../../react-binding";
import { MortgageTypes, MortgageTypesNr } from "../../../constants/types";
import { InputWithSlider } from "../partials/InputWithSlider";
import UserInfo from "../../../services/UserInfo.service";
import { CustomerInfoInput } from "../UserInfoInput/CustomerInfoInput";
import { SrovnavacState } from "./Srovnavac.state";
import { Select } from "../partials/Select";
import { possibleFixations } from "../../../constants/constants";
import { BankService } from "../../../services/Bank/BankService";
import { ComparisonTable } from "./ComparisonTable";
import { sanitizeInput } from "../../../helpers/helpers";

//@observer
export const Srovnavac = observer(() => {

    const userInfoService = useInjection(UserInfo);
    const srovnavacState = useInjection(SrovnavacState);
    const bankService = useInjection(BankService);

    const toggleDisplayDetailInputs = action(() => {
        srovnavacState.dislayedDetailUserInfo.value = !srovnavacState.dislayedDetailUserInfo.value
    });

    const recompute = action(()=> {
        console.log("RECOMPUTING...");
        const mortgageParams = userInfoService.mortgageParams;
        switch (mortgageParams.computing) {
            case 'ltv':
                const newLtv = 
                    Math.round(10000*
                        sanitizeInput(mortgageParams.amount)/sanitizeInput(mortgageParams.valueOfSecurity)
                    )/100;
                mortgageParams.ltv = newLtv.toString();
                break;
            case 'amount':
                const newAmount = 
                    Math.round(
                        sanitizeInput(mortgageParams.valueOfSecurity)*(sanitizeInput(mortgageParams.ltv)/100)
                    );
                mortgageParams.amount = newAmount.toString();
                break;
        }

        bankService.recomputeAndReturnGeneralOutput(userInfoService);


    });

    recompute();

    return <div className='srovnavac-cmp'>
        <button className='button-inputs-section' onClick={() => toggleDisplayDetailInputs()}>
            Detailní vstupy
        </button>

        {srovnavacState.dislayedDetailUserInfo.value &&
            <CustomerInfoInput recompute={recompute}/>
        }

        <div className='primarni-vstupy'>
            <span className='input-container'>
                <span className='input-header'>
                    Typ hypotéky
                </span>
                <span className='input-part'>
                    <Select
                        options={[
                            {
                                value: MortgageTypesNr.standard,
                                label: MortgageTypes.standard
                            },
                            {
                                value: MortgageTypesNr.american,
                                label: MortgageTypes.american
                            }
                        ]
                        }
                        observable = {userInfoService.mortgageParams}
                        field = "mortgageType"
                        onChangeCallback = {recompute}
                    />
                </span>
            </span>

            <span className='input-container'>
                <span className='input-header'>
                    Fixace
                </span>
                <span className='input-part'>
                    <Select
                        observable = {userInfoService.mortgageParams}
                        field = "fixation"
                        options = {possibleFixations.map((item, index) => {
                            return {
                                value: index === 0 ? 0 : item,
                                label: item,
                            }
                        })}
                        onChangeCallback = {recompute}
                    />
                </span>
            </span>

            <span className='input-container'>
                <span className='input-header'>
                    Hodnota zástavy
                </span>
                <span className='input-part'>
                    <InputWithSlider
                        onChangeCallback = {recompute}
                        placeholder = "Hodnota zástavy"
                        observable = {userInfoService.mortgageParams}
                        field = "valueOfSecurity"
                        min = {200000}
                        max = {10000000}
                        step = {50000}
                        textAfterInput = " Kč"
                        disableBtn = {true}
                    />
                </span>
            </span>

            <span className='input-container'>
                <span className='input-header'>
                    Výše hypotéky
                </span>
                <span className='input-part'>
                    <InputWithSlider
                        onChangeCallback = {recompute}
                        placeholder = "Výše hypotéky"
                        observable = {userInfoService.mortgageParams}
                        field = "amount"
                        min = {100000}
                        max = {12000000}
                        step = {100000}
                        textAfterInput = " Kč"
                    />
                </span>
            </span>

            <span className='input-container'>
                <span className='input-header'>
                    LTV
                </span>
                <span className='input-part'>
                    <InputWithSlider
                        onChangeCallback = {recompute}
                        placeholder = "LTV (poměr zástavy a výše hypotéky)"
                        observable = {userInfoService.mortgageParams}
                        field = "ltv"
                        min = {0}
                        max = {100}
                        step = {1}
                        textAfterInput = " %"
                    />
                </span>
            </span>

            <span className='input-container'>
                <span className='input-header'>
                    Splatnost
                </span>
                <span className='input-part'>
                    <InputWithSlider
                        onChangeCallback = {recompute}
                        placeholder = "Splatnost v letech"
                        observable = {userInfoService.mortgageParams}
                        field = "due"
                        min = {1}
                        max = {30}
                        step = {1}
                        textAfterInput = " let"
                        disableBtn = {true}
                    />
                </span>
            </span>
        </div>

        <div>
            <ComparisonTable/>
        </div>
    </div>
})