import * as React from "react";
import {observer} from "mobx-react-lite";
import { useInjection } from "../../../react-binding";
import { InvestmentParams, StringInvestmentParams } from "../../../constants/types";
import { InputWithSlider } from "../partials/InputWithSlider";
import UserInfo from "../../../services/UserInfo.service";
import { action } from "mobx";
import InvestmentMathService from "../../../services/MathServices/InvestmentMath.service";
import { calcDisclaimer } from "../../../constants/constants";

//@observer
export const SimpleInvestCalc = observer(() => {

    const investMathService = useInjection(InvestmentMathService);
    const userInfoService = useInjection(UserInfo);

    const recompute = action(() => {
        const data: InvestmentParams = {};
        // @ts-ignore
        Object.keys(userInfoService.simpleInvestmentData).forEach((key: keyof StringInvestmentParams) => {
            if (key !== userInfoService.simpleInvestmentData.computing) {
                // @ts-ignore
                data[key] = userInfoService.simpleInvestmentData[key]
            }
        });

        data[userInfoService.simpleInvestmentData.computing] = undefined;
        try {
            const result = investMathService.calcInvestmentViaMissingParam(data);
            userInfoService.simpleInvestmentData[userInfoService.simpleInvestmentData.computing] = Number(result).toLocaleString('cs');
            userInfoService.simpleInvestmentData.error = false;
        } catch(e) {
            userInfoService.simpleInvestmentData.error = true;
            console.warn(e);
        }
    })

    return (
        <div className='app-screen'>
            <div className="simple-calc-wrapper">
            <div className="simple-hypo-input-section">
                <div>
                    Výše počátečního vkladu
                </div>
                <InputWithSlider
                    placeholder='Výše hypotéky'
                    min={0}
                    max={1000000}
                    step={10000}
                    observable={userInfoService.simpleInvestmentData}
                    field={'presentValue'}
                    onChangeCallback = {recompute}
                    textAfterInput = 'Kč'
                />
                </div>
                <div className="simple-hypo-input-section">
                <div>
                    Pravidelný měsíční vklad
                </div>
                <InputWithSlider
                    placeholder='Výše platby'
                    min={100}
                    max={40000}
                    step={100}
                    observable={userInfoService.simpleInvestmentData}
                    field={'installment'}
                    onChangeCallback = {recompute}
                    errorMessage = {'Mimo smysluplné hodnoty'}
                    textAfterInput = ' Kč'
                />
                </div>
                <div className="simple-hypo-input-section">
                <div>
                    Předpokládaný výnos p.a.
                </div>
                <InputWithSlider
                    placeholder='Úroková sazba'
                    min={0.01}
                    max={10}
                    step={0.01}
                    observable={userInfoService.simpleInvestmentData}
                    field={'iRate'}
                    onChangeCallback = {recompute}
                    errorMessage = {'Výsledný výnos je mimo běžné hodnoty'}
                    textAfterInput = ' %'
                    showDecimals = {true}
                />
                </div>
                <div className="simple-hypo-input-section">
                <div>
                    Investiční horizont
                </div>
                <InputWithSlider
                    placeholder='Investiční horizont'
                    min={1}
                    max={40}
                    step={1}
                    observable={userInfoService.simpleInvestmentData}
                    field={'due'}
                    onChangeCallback = {recompute}
                    errorMessage = {'Výsledný horizont je mimo smysluplné hodnoty'}
                    textAfterInput = ' let'
                />
                </div>
                <div className="simple-hypo-input-section">
                <div>
                    Hodnota investice na konci
                </div>
                <InputWithSlider
                    placeholder='Hodnota investice na konci'
                    min={10000}
                    max={10000000}
                    step={50000}
                    observable={userInfoService.simpleInvestmentData}
                    field={'futureValue'}
                    onChangeCallback = {recompute}
                    errorMessage = { "Mimo smysluplné hodnoty" }
                    textAfterInput = ' Kč'
                />
                </div>
            </div>
            <div className="calc-disclaimer">{calcDisclaimer}</div>
        </div>
    )
})