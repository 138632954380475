import { observer } from "mobx-react-lite";
import { useInjection } from "../../react-binding";
import { BankService } from "../../services/Bank/BankService";
import { Envelope, Globe2, TelephoneFill } from "react-bootstrap-icons";

interface PrintHeaderProps {
    hideDetails?: boolean;
}

export const PrintFooter = observer((props: PrintHeaderProps) => {
    const bankService = useInjection(BankService) as BankService;

    return <div className="print-visible print-footer-main">
        <div className="print-footer">
            {!props.hideDetails &&
                <div className="details-section">
                    {bankService.dataService.wordPressUIData?.name &&
                        <span className="detail no-icon">
                            {bankService.dataService.wordPressUIData?.name}
                        </span>
                    }
                    {bankService.dataService.wordPressUIData?.telNumber &&
                        <span className="detail">
                            <TelephoneFill className="mr-20 icon"/>
                            {bankService.dataService.wordPressUIData?.telNumber}
                        </span>
                    }
                    {bankService.dataService.wordPressUIData?.email &&
                    <span className="detail">
                        <Envelope className="mr-20 icon"/>
                        {bankService.dataService.wordPressUIData?.email}
                    </span>
                    }

                    {bankService.dataService.wordPressUIData?.url &&
                        <span className="detail">
                            <Globe2 className="mr-20 icon"/>
                            {bankService.dataService.wordPressUIData?.url}
                        </span>
                    }
                </div>
            }
        </div>
    </div>
})