import { observable } from "mobx";
import { sanitizeInput } from "../../helpers/helpers";
import { Bank } from "./Bank";
import { globalVariables } from "../../constants/GlobalVariables";
import { checkIfVariablePasses } from "../../helpers/helpers";
import { BankResults } from "../../constants/types";
import UserInfo from "../UserInfo.service";
import MortgageMathService from "../MathServices/MortgageMath.service";
import DataService from "../Data.service";
import CheckBestRateService from "../CheckBestRateService";
import DefineExtraResultsService from "../DefineExtraResultsService";
import { NavigationService } from "../NavigationService/NavigationService";

export class BankService {

    banks: Bank[] = observable([]);

    passingGlobalVariables: string[] = [];

    dataService: DataService;
    checkBestRateService: CheckBestRateService;
    mortgageMathService: MortgageMathService;
    defineExtraResultsService: DefineExtraResultsService;
    navigationService?: NavigationService;

    // NOTE: the bankResults are currently for one fixation only...
    result: BankResults = observable({
        bankResults: []
    });

    bankServiceInitialized = observable({value: false});

    initializing: boolean = false;

    initialize(shouldFetchData:boolean = true, navigationService?: NavigationService, wordPressUIData?: any) {
        if (navigationService) {
            this.navigationService = navigationService;
        }

        if (shouldFetchData) {
            this.dataService.bankData = undefined;
            if (wordPressUIData) {
                this.dataService.wordPressUIData = wordPressUIData;
            
            }
        }
        return this.dataService.initialize(shouldFetchData).then(()=> {
            if (this.initializing) {
                return;
            }
            this.initializing = true;
            if (this.dataService.bankData) {
                this.dataService.bankData.forEach(bank => {
                    this.banks.push(new Bank(bank, this, this.mortgageMathService));
                })

                console.log(`Created ${this.banks.length} banks based on bank data`);
                this.bankServiceInitialized.value = true
            }
        })
    }

    constructor() {
        this.dataService = new DataService(this);
        this.checkBestRateService = new CheckBestRateService(this);
        this.mortgageMathService = new MortgageMathService();
        this.defineExtraResultsService = new DefineExtraResultsService(this);
    }

    setGeneralInsuranceToAllBanks(value: boolean) {
        const valueToSet = value ? "GenericInsurance" : "--";
        this.banks.forEach(bank => {
            /*
            NOTE: we might just set the flag for all and it would generally work. But in the future if we
            wanted the user to chose the insurance variant, we don't want to have something out of the list
            as selected insurance as it might cause some problems.
            */
            if (bank.data.productsModifiers.insuranceProducts.some(product => product === "GenericInsurance")) {
                bank.selectedProducts.insuranceProduct = valueToSet;
            }
        })
    }

    checkIfGeneralInsuranceIsSet() {
        return this.banks.some(bank => {
            return bank.selectedProducts.insuranceProduct === "GenericInsurance";
        });
    }

    recomputeAndReturnGeneralOutput(userInfo: UserInfo, saveBankResults: boolean = true, useArgumentationSpecificFees?: boolean) {
        console.log("RECOMPUTING BANK SERVICE...");
        // Eventually we might want to make this conditional if we allow user to set ltvs
        // directly
        this.recomputeLTV(userInfo);
        this.computeFinalAge(userInfo);
        this.recomputeDTI(userInfo);

//        this.recomputeGeneralBonity(userInfo);
        this.checkGlobalVariables(userInfo);
        this.defineExtraResultsService.defineExtraFields(userInfo);
        const result = this.banks.map((bank:Bank) => {
            const info = bank.recomputeAndReturnGeneralOutput(userInfo, useArgumentationSpecificFees);
            return info;
        })
/*
        if (this.dataService.testing) {
            console.log("BANKS:", this.banks);
            console.log("NEW BANK RESULTS:", result);
        }
*/
        if (saveBankResults) {
            this.result.bankResults = result;
        }
        
        return result;
    }

    checkGlobalVariables(userInfo: UserInfo) {
        const passingVariables = globalVariables?.map((variable: any) => {
            return checkIfVariablePasses(userInfo, variable);
        }).filter((item: any) => !!item);
        
        this.passingGlobalVariables = passingVariables;
        console.log("PASSING GLOBAL VARIABLES:", passingVariables);
    }

    recomputeGeneralBonity(userInfo: UserInfo) {
//        const dsti = sanitizeInput(userInfo.userInfo.otherInstallments)/sanitizeInput(userInfo.userInfo.income);
//        userInfo.userInfo.dsti = dsti;
    }

    recomputeLTV(userInfo: UserInfo) {
        const newLTV = sanitizeInput(userInfo.mortgageParams.amount)/sanitizeInput(userInfo.mortgageParams.valueOfSecurity);
        userInfo.mortgageParams.ltv = Math.round(newLTV*100).toString();
    }

    recomputeDTI(userInfo: UserInfo) {
        const newDTI = sanitizeInput(userInfo.mortgageParams.amount)/(sanitizeInput(userInfo.userInfo.income)*12);
        userInfo.mortgageParams.dti = newDTI.toString();
    }

    computeFinalAge(userInfo: UserInfo) {
        const finalAge = sanitizeInput(userInfo.userInfo.age)+sanitizeInput(userInfo.mortgageParams.due);
        userInfo.mortgageParams.finalAge = finalAge.toString();
    }
}