//import logo from './logo.svg';
import './App.css';
import FrontEndIndex from './components/FrontendIndex';

function App() {
  return (<>
    <div className="App">
      <FrontEndIndex/>
    </div>
  </>);
}

export default App;