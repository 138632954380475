import {observer} from "mobx-react-lite";
import { NavLink } from "react-router-dom";
import { CheckCircle, HouseDoorFill, HandThumbsUp } from "react-bootstrap-icons";
import { useEffect } from "react";
import { useInjection } from "../../../react-binding";
import { NavigationService } from "../../../services/NavigationService/NavigationService";


//@observer
export const HomeScreen = observer(() => {

    const navigationService = useInjection(NavigationService);

    useEffect(() => {
        if (navigationService.selectedPageByQueryParam.page) {
            navigationService.navigateToPage(navigationService.selectedPageByQueryParam.page);
        }
    }, [navigationService.selectedPageByQueryParam])

    return <div> </div>

{/*
    return (
        <div className='home-screen'>
            <div className="header-part">
                <h1>
                    Pomůžeme vám v orientaci na hypotečním trhu
                </h1>   
                <p>
                    Sbíráme každý den nabídkové sazby jednotlivých bank a jsme schopni vám dodat informace o stávajících možnostech financování na hypotečním trhu. Vyberte si některou ze služeb níže.
                </p>
            </div>

            <div className="navigation-part">
                <NavLink to={`/argumentation`} className="navigation-item">
                    <CheckCircle className="item-icon"></CheckCircle>
                    <h2>
                        Nová sazba od banky
                    </h2>
                    <p>
                        Mám stávající úvěr a dostal jsem nabídku na novou sazbu. Chci ji porovnat se možnostmi na trhu a získat argumenty pro vyjednávání s bankou
                    </p>
                </NavLink>


                <NavLink to={`/comparison`} className="navigation-item">
                    <HouseDoorFill className="item-icon"></HouseDoorFill>
                    <h2>
                        Možnosti financování nemovitosti
                    </h2>
                    <p>
                        Uvažuji o úvěru a chci orientačně zjistit, jak se aktuálně pohybují sazby na zvolené parametry.
                    </p>
                </NavLink>

                <NavLink to={`/srovnavac`} className="navigation-item">
                    <HandThumbsUp className="item-icon"></HandThumbsUp>
                    <h2>
                        Pomoc se sjednáním či refinancováním
                    </h2>
                    <p>
                        Zajistíme vám zkušeného poradce, který vám pomůže s úvěry na bydlení. Ať již se jedná o nový úvěr či jste se rozhodli refinancovat stávající úvěr.
                    </p>
                </NavLink>
            </div>
        </div>
    )
    */}
})