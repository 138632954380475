import { observer } from "mobx-react-lite";
import { ElementRef, useCallback, useRef, useState } from "react";
import { numberToStringWithSpaces, sanitizeInput } from "../../../helpers/helpers";
import { Check, PencilSquare, X } from "react-bootstrap-icons";

interface AnnuityCalcTableRowProps {
    nrOfPeriod: number;
    data: any;
    index: number;
    installment: string;
    interest: string;
    sumOfInterest: string;
    variableAmount: boolean;
    variableIRate: boolean;
    variableDue: boolean;
    monthlyData: boolean;
    extraAmountOrPayment: number;
    setExtraAmountOrPayment: (newExtraAmountOrPayment: string, index: number) => void;
    iRate: any;
    setNewIRate: (newIRate: number, index: number) => void;
    due: any;
    setNewDue: (newDue: number, index: number) => void;
    notesOn: boolean;
    note: string;
    setNote: (note: string, index: number) => void;
    startingYear: number;
    setStartingYear: (newStartingYear: number) => void;
    edited?: boolean;
};

export const AnnuityCalcTableRow = observer((props: AnnuityCalcTableRowProps) => {
    const {
        nrOfPeriod, 
        data, 
        installment,
        interest,
        sumOfInterest,
        variableAmount,
        variableIRate, 
        variableDue, 
        extraAmountOrPayment,
        setExtraAmountOrPayment,
        monthlyData,
        iRate, 
        setNewIRate, 
        due, 
        setNewDue,
        index,
        notesOn,
        note,
        setNote,
        edited,
        startingYear,
        setStartingYear
    } = props;
    const [noteEditMode, setNoteEditMode] = useState(false);

    const inputRef = useRef<ElementRef<'textarea'>>(null);
    const resizeInput = useCallback(() => {
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.style.setProperty('height', 'auto');
            inputRef.current.style.setProperty('height', inputRef.current.scrollHeight + 'px');
            }
        }, 0);
    }, [inputRef]);

    return <>
        <tr key={index} className= {`${edited ? "edited-bg-color" : ""}`}>
            {
            index === 0 ? <td>
                <span className="print-visible">{startingYear}{startingYear ? "/1" : ""}</span>
                <span className="print-hidden">
                    <input type="number"
                        className="text-center input-number-no-arrows"
                        step={1}
                        min={0}
                        defaultValue={startingYear}
                        onBlur={(e) => {
                            const newStartingYear = e.target.value;
                            setStartingYear(sanitizeInput(newStartingYear));
                        }}
                        onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                                e.target.blur();
                            }
                        }}
                    />
                </span>
            </td>
            :
                <td>{nrOfPeriod + (Math.max(0, startingYear-1))}</td>
            }
            <td>{numberToStringWithSpaces(Math.floor(data.amount || 0))} Kč</td>
            <td>{installment} Kč</td>
            <td>{interest} Kč</td>
            <td>{sumOfInterest}</td>
            {variableAmount && <td>
                <span className="print-visible">{extraAmountOrPayment} Kč</span>
                <span className="print-hidden">
                    <input type="number"
                        step={50000}
                        max={data.amount || 0}
                        defaultValue={extraAmountOrPayment}
                        onBlur={(e) => {
                            const newExtraAmountOrPayment = e.target.value;
                            setExtraAmountOrPayment(newExtraAmountOrPayment, index);
                        }}
                        onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                                e.target.blur();
                            }
                        }}
                    />
                </span>                                    
            </td>}
            {variableIRate && <td>
                <span className="print-visible">{iRate} %</span>
                <span className="print-hidden">
                    <input type="number"
                        defaultValue={iRate}
                        step={0.1}
                        min={0}
                        onBlur={(e) => {
                            setNewIRate(sanitizeInput(e.target.value), index);
                        }}
                        onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                                e.target.blur();
                            }
                        }}
                    />
                </span>
            </td>}
            {variableDue && <td>
                <span className="print-visible">{due} {monthlyData ? "měsíců" : "let"}</span>
                <span className="print-hidden">
                    <input type="number"
                        defaultValue={due}
                        step={1}
                        min={1}
                        max={monthlyData ? 480 : 40}
                        onBlur={(e) => {
                            setNewDue(sanitizeInput(monthlyData ? e.target.value : Number(e.target.value)*12), index);
                        }}
                        onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                                e.target.blur();
                            }
                        }}
                    />
                </span>
            </td>}

            {(notesOn) && <td>
                {!noteEditMode ? 
                    <PencilSquare className="pointer print-hidden" onClick={() => {
                        setNoteEditMode(true);
                        resizeInput();
                    }}/>
                    :
                    <>
                        <Check className="pointer size-25 print-hidden" onClick={() => setNoteEditMode(false)}/>
                        <X className="pointer size-25 print-hidden" onClick={() => {
                            setNote("", index);
                            if (inputRef.current) {
                                inputRef.current.value = "";
                            }
                            setNoteEditMode(false);
                            }
                        }/>
                    </>
                }
            </td>
            }
        </tr>
        {
            (notesOn) && <tr>
                {noteEditMode ?
                    <td colSpan={5 + (variableDue ? 1 : 0) + (variableAmount ? 1: 0) + (variableIRate ? 1:0)}>
                        <textarea
                        ref={inputRef}
                        className="note-textarea p-5 autoresize"
                        defaultValue={note}
                        onBlur={(e) => setNote(e.target.value, index)}
                        onChange={resizeInput}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              if (inputRef.current) {
                                inputRef.current.value += '\n';
                              }
                              resizeInput();
                          }
                        }}
                        />
                    </td>
                    :
                    (note && 
                        <td colSpan={4 + (variableDue ? 1 : 0) + (variableAmount ? 1: 0) + (variableIRate ? 1:0)}>
                            <span className="note">
                                {note}
                            </span>
                        </td>
                    )
                }
            </tr>
        }
    </>
});