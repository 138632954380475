import { observable } from "mobx";
import { sanitizeInput } from "../../helpers/helpers";
import { checkIfVariablePasses, checkIfIfStatementPasses } from "../../helpers/helpers";
import { BankService } from "./BankService";
import { BankData, BankResult, IfStatement } from "../../constants/types";
import UserInfo from "../UserInfo.service";
import MortgageMathService from "../MathServices/MortgageMath.service";

export class Bank {

    mortgageMathService: MortgageMathService;

    bankService: BankService;

    data: BankData;

    result: BankResult = observable({
        passesBonityCheck: false,
        interestRate: 0,
        name: '',
        installment: 0,
        startingFee: 0,
        monthlyFee: 0,
        dsti: 0
    })

    bankSpecificModifiers = observable({
        iRate: 0,
        amount: 0,
        due: 0,

    })

    selectedProducts = observable({
        bankProduct: '--',
        insuranceProduct: '--',
        others: '--'
    })

    passingVariables: string[] = [];

    constructor(bankData: any, bankService: BankService, mortgageMathService: MortgageMathService) {
        this.data = bankData;
        this.bankService = bankService;
        this.mortgageMathService = mortgageMathService;
    }

    recomputeAndReturnGeneralOutput(userInfo: UserInfo, useArgumentationSpecificFees?: boolean) {
        console.log("RECOMPUTING BANK...", this.data.name);
        this.checkVariables(userInfo);
        if (this.bankService.dataService.testing) {
            console.log("PASSING VARIABLES FOR BANK:", this.data.name, this.passingVariables);
        }
        if (this.data.visible === 0) {
            return {
                passesBonityCheck: false,
                interestRate: null,
                name: this.data.name,
                visible: 0
            }
        }
        const result: BankResult = {
            passesBonityCheck: false,
            interestRate: null,
            name: this.data.name,
            startingFee: null,
            monthlyFee: null,
            extraFields: {},
            futureValue: null,
            sumOfInstallments: null
        }

        result.interestRate = this.getInterestRate(userInfo);
        if (isNaN(result.interestRate)) {
            result.interestRate = 0;
            result.passesBonityCheck = false;
            return result;
        }

        const installment = this.generateInputForMortgageParams(userInfo, result.interestRate);
        if (installment) {
            result.installment = installment;
        }

        result.sumOfInstallments = installment * userInfo.mortgageParams.fixation*12;

        const { startingFee, monthlyFee } = this.getFees(userInfo);
        result.startingFee = startingFee;
        result.monthlyFee = monthlyFee;
        const rpsn = this.getRPSN(userInfo, result);
        result.rpsn = rpsn;

        const amount = userInfo.mortgageParams.amount;
        const fixation = userInfo.mortgageParams.fixation;

        result.futureValue = this.mortgageMathService.calcFutureValue({
                amount,
                iRate: result.interestRate,
                installment: result.installment
        }, fixation);

        this.bankService.defineExtraResultsService.defineExtraResultFields(userInfo, result);
        const speficicFee = !useArgumentationSpecificFees ? 0 : result.extraFields?.vkladNaKatastr;
        if (speficicFee) {
            result.startingFee += speficicFee;
        }
        
        const bonityResult = this.recomputeBonity(userInfo, result);

        Object.assign(result, bonityResult);
        this.checkBonitySpecificVariables(result);
//        console.log("BANK:", this.data.name, "passing variables:", this.passingVariables);
        result.passesBonityCheck = this.checkIfBonityRequiredVariablesPass();
        if (!result.passesBonityCheck) {
            this.result = result;
            return result;
        }

        this.result = result;
//        console.log("RESULT:", this.data.name, this.result, this.passingVariables);
        return result;
    }

    getRPSN(userInfo: UserInfo, result: BankResult) {
        const { startingFee, monthlyFee, installment } = result;
        const mortgageParams = userInfo.mortgageParams;
        const params = {
            amount: sanitizeInput(mortgageParams.amount)-(startingFee || 0),
            installment: installment!+(monthlyFee || 0),
            due: sanitizeInput(mortgageParams.due)
        }
        const rpsn = this.mortgageMathService.calcMortgageViaMissingParam(params);
        return rpsn;
    }

    getFees(userInfo: UserInfo) {
        let startingFee: number = 0, monthlyFee: number = 0;
        const mortgageAmount = sanitizeInput(userInfo.mortgageParams.amount);
        const feeRules = this.data.feeRules;

        feeRules.forEach((rule: any) => {
            let passes = true;
            const minStartingFee = rule.startingFee.min;
            const maxStartingFee = rule.startingFee.max;
            const minMonthlyFee = rule.monthlyFee.min;
            const maxMonthlyFee = rule.monthlyFee.max;
            rule.listOfAllVariablesThatMustPass.forEach((variable: string) => {
                if (!this.checkIfVariablePasses(variable)){
                    passes = false;
                };
            })
            if (passes) {
                let startingFeeModifier = 0, monthlyFeeModifier = 0;
                startingFeeModifier += rule.startingFee.fixed || 0;
                startingFeeModifier += (rule.startingFee.percentOfAmount || 0)*mortgageAmount/100;

                monthlyFeeModifier += rule.monthlyFee.fixed || 0;
                monthlyFeeModifier += (rule.monthlyFee.percentOfAmount || 0)*mortgageAmount/100;
                startingFeeModifier = Math.min(maxStartingFee || mortgageAmount, Math.max(minStartingFee || 0, startingFeeModifier));
                monthlyFeeModifier = Math.min(maxMonthlyFee || mortgageAmount, Math.max(minMonthlyFee || 0, monthlyFeeModifier));

                startingFee += startingFeeModifier;
                monthlyFee += monthlyFeeModifier;
            }

        })

        return {
            startingFee,
            monthlyFee
        }
    }

    generateInputForMortgageParams(userInfo: UserInfo, iRate: number | string) {
        iRate = iRate.toString();
        const { amount, due } = userInfo.mortgageParams;
        const input = {
            amount,
            due,
            iRate
        }
        const installment = this.mortgageMathService.calcMortgageViaMissingParam(input);
        return installment;
    }

    checkVariables(userInfo: UserInfo) {
        const data = this.data;
        let passingVariables = data.variables?.map((variable: any) => {
            const result = checkIfVariablePasses(userInfo, variable);
            if (this.bankService.dataService.testing && !result) {
                console.log("VARIABLE DID NOT PASS:", variable);
            }
            return result;
        }).filter((item: any) => !!item);
        
        if (this.selectedProducts.bankProduct !== '--') {
            passingVariables.push(this.selectedProducts.bankProduct);
        }

        if (this.selectedProducts.insuranceProduct !== '--') {
            passingVariables.push(this.selectedProducts.insuranceProduct);
        }

        if (this.selectedProducts.others !== '--') {
            passingVariables.push(this.selectedProducts.others);
        }

        
        const conditionalPassingVariables = data.ifStatements?.map((statement: IfStatement) => {
            const passes = checkIfIfStatementPasses(passingVariables, this.bankService.passingGlobalVariables, statement);
            return passes ? statement.variableName : false;
        }).filter((item: any) => !!item);

        if (conditionalPassingVariables) {
            passingVariables = passingVariables.concat(conditionalPassingVariables);
        }

        this.passingVariables = passingVariables;
    }

    getInterestRate(userInfo: UserInfo) {
        const data = this.data;
        const fixation = userInfo.mortgageParams.fixation;
        const type = userInfo.mortgageParams.mortgageType;
        let rate = data.iRates[parseFloat(type)].baseRates[fixation];

        if ((fixation === 0 || fixation.toString() === "0") && rate !== undefined && this.data.priborTypeForFloat) {
            const priborItem = this.bankService.dataService.priborRates ?
                this.bankService.dataService.priborRates.find(item => {
                    return item.Typ === this.data.priborTypeForFloat;
                }
            ) : undefined;

            if (priborItem) {
                rate += priborItem.Sazba;
            }
        }

        let modifierSum = 0;

        data.generalRatesRules.forEach((rule: any) => {

            let passes = true;
            const variablesThatMustPassArray = typeof rule.listOfAllVariablesThatMustPass === "string" ? rule.listOfAllVariablesThatMustPass.split(',') : rule.listOfAllVariablesThatMustPass;
            variablesThatMustPassArray.forEach((variable: string) => {
                if (!this.checkIfVariablePasses(variable)){
                    passes = false;
                };
            })
            if (passes) {
                modifierSum +=parseFloat(rule.modifier);
            }
        })

        const resultBeforeRounding = rate + modifierSum;
        const resultAfterRounding = Math.round(resultBeforeRounding*100)/100;
        return resultAfterRounding;
    }

    checkIfVariablePasses(variable: string) {
        if (
            this.passingVariables.indexOf(variable) === -1 &&
            this.bankService.passingGlobalVariables.indexOf(variable) === -1
        ) {
            return false;
        }
        return true;
    }

    recomputeBonity(userInfo: UserInfo, result: BankResult) {
        const sumOfInstallments = sanitizeInput(userInfo.userInfo.otherInstallments)+result.installment!;
        const dsti = sumOfInstallments/(sanitizeInput(userInfo.userInfo.income));
        result.dsti = dsti;

        return {
            dsti
        };
    }

    checkBonitySpecificVariables(result: BankResult) {
        if (this.bankService.dataService.testing) {
            console.log("BANK BONITY VARIABLES:", this.data.bonityVariables);
        }
        
        let passingBonityVariables = this.data.bonityVariables?.map((variable: any) => {
            return checkIfVariablePasses(result, variable);
        }).filter((item: any) => !!item);

        if (this.bankService.dataService.testing) {
            console.log("PASSING BONITY VARIABLES:", passingBonityVariables);
        }
        
        if (passingBonityVariables) {
            this.passingVariables = this.passingVariables.concat(passingBonityVariables);
        }

        const conditionalPassingVariables = this.data.bonityIfStatements?.map((statement: IfStatement) => {
            const passes = checkIfIfStatementPasses(this.passingVariables, this.bankService.passingGlobalVariables, statement);
            return passes ? statement.variableName : false;
        }).filter((item: any) => !!item);

        if (conditionalPassingVariables) {
            this.passingVariables = this.passingVariables.concat(conditionalPassingVariables as string[]);
        }
    }

    checkIfBonityRequiredVariablesPass() {
        const data = this.data;
        let passes = true;
        const bonityVariablesThatMustPass = typeof data.bonityVariablesThatMustPass === "string" ? data.bonityVariablesThatMustPass.split(',') : data.bonityVariablesThatMustPass;
        bonityVariablesThatMustPass.forEach((variable: string) => {
            if (
                this.passingVariables.indexOf(variable) === -1 &&
                this.bankService.passingGlobalVariables.indexOf(variable) === -1
            ) {
                passes = false;
            }
        })
        return passes;
    }
}