import { sanitizeInput } from "../helpers/helpers";

export default class DefineExtraResultsService {
    bankService;

    constructor(bankService) {
        this.bankService = bankService;
    }


    defineExtraFields(userInfo) {

        function putIntoUserInfo(fieldName, value) {
            userInfo.extraFields[fieldName] = value;
        }

        let {
            income, // příjem
            age, // věk nejmladšího žadatele
            otherInstallments, // ostatní splátky
            benchmarkRate // sazba u jiné banky
        } = userInfo.userInfo;

        let {
            amount, // výše hypotéky
            valueOfSecurity, // hodnota zástavy
            due, // splatnost
            fixation, // fixace
            ltv, // ltv
            finalAge, // věk ve splatnosti
            dti, // dti
            mortgageType, // typ hypotéky
            propertyType, // financovaná nemovitost
            mortgagedPropertyType, // zastavená nemovitost
            purpose, // účel
            useGenericInsurance, // zdali se používá životní pojištění
            filterBank // kterou banku vynecháváme
        } = userInfo.mortgageParams;

        income = sanitizeInput(income);
        age = sanitizeInput(age);
        otherInstallments = sanitizeInput(otherInstallments);
        benchmarkRate = sanitizeInput(benchmarkRate);
        amount = sanitizeInput(amount);
        valueOfSecurity = sanitizeInput(valueOfSecurity);
        due = sanitizeInput(due);
        fixation = sanitizeInput(fixation);
        ltv = sanitizeInput(ltv);
        finalAge = sanitizeInput(finalAge);
        dti = sanitizeInput(dti);
        mortgageType = sanitizeInput(mortgageType);

        ///////////////////
        ///////////////////
        ///////////////////
        /*
        ZDE JE MOŽNÉ NADEFINOVAT SI SVOJE VLASTNÍ PROMĚNNÉ
        které mají přístup ke všemu výše uvedenému
        Na konci je třeba je přidat do userInfo.extraFields použitím fce putIntoUserInfo
        (příklad viz níže)
        */
        ///////////////////
        ///////////////////
        ///////////////////

        const extraTestResult = income + 10000;
        putIntoUserInfo("zvysenyPrijem", extraTestResult);
    }

    defineExtraResultFields(userInfo, bankResult) {

        function putIntoResult(fieldName, value) {
            if (!bankResult.extraFields) {
                bankResult.extraFields = {};
            }
            bankResult.extraFields[fieldName] = value;
        }
        
        let {
            income, // příjem
            age, // věk nejmladšího žadatele
            otherInstallments, // ostatní splátky
            benchmarkRate // sazba u jiné banky
        } = userInfo.userInfo;

        let {
            amount, // výše hypotéky
            valueOfSecurity, // hodnota zástavy
            due, // splatnost
            fixation, // fixace
            ltv, // ltv
            finalAge, // věk ve splatnosti
            dti, // dti
            mortgageType, // typ hypotéky
            propertyType, // financovaná nemovitost
            mortgagedPropertyType, // zastavená nemovitost
            purpose, // účel
            useGenericInsurance, // zdali se používá životní pojištění
            filterBank // kterou banku vynecháváme
        } = userInfo.mortgageParams;

        let {
            interestRate, // úroková sazba
            name, // jméno banky
            installment, // výše splátky
            dsti //dsti
        } = bankResult;


        income = sanitizeInput(income);
        age = sanitizeInput(age);
        otherInstallments = sanitizeInput(otherInstallments);
        benchmarkRate = sanitizeInput(benchmarkRate);
        amount = sanitizeInput(amount);
        valueOfSecurity = sanitizeInput(valueOfSecurity);
        due = sanitizeInput(due);
        fixation = sanitizeInput(fixation);
        ltv = sanitizeInput(ltv);
        finalAge = sanitizeInput(finalAge);
        dti = sanitizeInput(dti);
        mortgageType = sanitizeInput(mortgageType);
        interestRate = sanitizeInput(interestRate);
        installment = sanitizeInput(installment);
        dsti = sanitizeInput(dsti);

        ///////////////////
        ///////////////////
        ///////////////////
        /*
        ZDE JE MOŽNÉ NADEFINOVAT SI SVOJE VLASTNÍ PROMĚNNÉ
        které mají přístup ke všemu výše uvedenému
        Na konci je třeba je přidat do result.extraFields použitím fce putIntoResult
        (příklad viz níže)
        */
        ///////////////////
        ///////////////////
        ///////////////////





        // přebírané proměnné - jen na testy, následně smazat

        let pocetDospelych=2;
        let pocetDetiDo10=1;
        let pocetDetiNad10=1;
        let vyzivne=0;
        let dalsiPravidelneVydaje=0;
        let objemNesplacenychUveru=0;
        let limityKK=0;
        let limityKTK=0;
        let kraj="Praha";
        let uverovyProduktUmBank=0;
        let limityKKKB=0;

        // konec přebíraných proměnných

        // definice proměnných pro výpočty

        let procentoZKK;
        let procentoZKKKB;
        let procentoZKTK;
        let vydajKK;
        let vydajKTK;
        let bonitniVydaje;
        let ZM;
        let maxUverBonity;
        let maxUverDSTI;
        let maxUverDTI;
        let maxUverDleProduktu;
        let maxUverCelkem;
        let maxUverCelkemRezerva;
        let minUver;
        let maxVek;
        let maxSplatnost;
        let zbyloNaSplatkuKBonite;
        let sazbaKBonite;
        let splatnostKBonite;
        let vsechnySplatky;
        let rattingCHB;
        let parametrDSTI;
        let parametrDTI;
        let jeZP=0;
        let vydajeNaPosudek="";

        let pocetOsob=pocetDospelych+pocetDetiDo10+pocetDetiNad10;

        // Bude se započítavát jen v případě arugmentace
        // Pokud je bank-specific, ve switchi níže stačí přepsat na jinou hodnotu
        let poplatkySpecifickeProArgumentaci = 4000;

        if (useGenericInsurance) {
        jeZP=1;
        }

        // Výdaje na posudek

        switch(name) {
        case "Česká spořitelna":
            

            
            if (mortgageType == 1 && purpose == "Refinancování") {
                vydajeNaPosudek="zdarma"; 
            }
            else if (mortgagedPropertyType=="Byt") {
                vydajeNaPosudek="zdarma pro byty ve městech; jinak 4400 Kč"; 
            }
            else if (mortgagedPropertyType=="Pozemek") {
                vydajeNaPosudek="4400 Kč"; 
            }
            else {
                vydajeNaPosudek="5400 Kč"; 
            }
            break;
        case "Hypoteční banka":
            if (mortgageType == 1) {
            vydajeNaPosudek="zdarma"; 
            }
            else if (mortgagedPropertyType=="Byt") {
            vydajeNaPosudek="900 Kč"; 
            }
            else {
            vydajeNaPosudek="1900 Kč"; 
            }
        break;
        case "Komerční banka":
            if (mortgageType == 1 && purpose == "Refinancování") {
            vydajeNaPosudek="zdarma"; 
            }
            else if (mortgageType == 1 && purpose=="Koupě" && (mortgagedPropertyType=="Byt" || mortgagedPropertyType=="Rodinný dům")) {
            vydajeNaPosudek="1000 Kč vybrané lokality, jinak 4500"; 
            }
            else if (mortgagedPropertyType=="Byt" || mortgagedPropertyType=="Rodinný dům") {
            vydajeNaPosudek="4500 Kč"; 
            }
            else {
            vydajeNaPosudek="individuálně"; 
            }
            break;
        case "mBank":
            if (purpose == "Koupě" || purpose == "Refinancování" || mortgageType==2) {
            if (mortgagedPropertyType=="Byt") {
                vydajeNaPosudek="lze-li on-line ocenění 1900 Kč; jinak 5000 Kč"; 
            }
            else if (mortgagedPropertyType=="Pozemek") {
                vydajeNaPosudek="lze-li on-line ocenění 1900 Kč; jinak 5500 Kč"; 
            }
            
            else {
                vydajeNaPosudek="lze-li on-line ocenění 1900 Kč; jinak 6000 Kč"; 
            }
            }
            else {
            if (mortgagedPropertyType=="Byt") {
                vydajeNaPosudek="lze-li on-line ocenění 1900 Kč; jinak 6000 Kč"; 
            }
            else if (mortgagedPropertyType=="Pozemek") {
                vydajeNaPosudek="lze-li on-line ocenění 1900 Kč; jinak 5500 Kč"; 
            }
            
            else {
                vydajeNaPosudek="lze-li on-line ocenění 1900 Kč; jinak 7500 Kč"; 
            }
            }
            break;
        case "Moneta Money Bank":
            vydajeNaPosudek="2500 Kč"; 
            break;
        case "Raiffeisenbank":
            vydajeNaPosudek="5900 Kč"; 
            break;
        case "Unicredit Bank":
            if (ltv <= 0.8 && jeZP == 1) {
            vydajeNaPosudek="zdarma"; 
            }

            else if (mortgagedPropertyType=="Byt") {
            vydajeNaPosudek="500 Kč vybrané oblasti; jinak 5300 Kč"; 
            }
            else if (mortgagedPropertyType=="Pozemek") {
            vydajeNaPosudek="5300 Kč"; 
            }

            else {
            vydajeNaPosudek="6500 Kč"; 
            }
            break;
        default:
            
        }


        // KK a KTK; pravidla DTI a DSTI a produktove limity

        switch(name) {
            case "Česká spořitelna":
            procentoZKK=0.03;
            procentoZKKKB=0.03;
            procentoZKTK=0.03;
            maxVek=72;

            if (age < 36) {
                parametrDSTI=0.5;
                parametrDTI=9.5;
            } else {
                parametrDSTI=0.45;
                parametrDTI=8.5;
            }

            if (mortgageType == 1) {
                maxUverDleProduktu=25000000;
                minUver=300000;
                maxSplatnost=30;
            } else if (mortgageType==2) {
                maxUverDleProduktu=5000000;
                minUver=150000;
                maxSplatnost=20;
            } else {
                maxUverDleProduktu=0;
            }

            break;
            case "Hypoteční banka":
            procentoZKK=0.05;
            procentoZKKKB=0.05;
            procentoZKTK=0.05;
            maxVek=70;
            
            if (purpose == "Refinancování")
            {
                parametrDSTI=0.7;
                parametrDTI=12;
            }  
            else {
                if (age < 36) {
                parametrDSTI=0.5;
                parametrDTI=9.5;
                } else {
                parametrDSTI=0.45;
                parametrDTI=8.5;
                } 
            } 
            
            if (mortgageType == 1) {
                maxUverDleProduktu=1000000000;
                minUver=300000;
                maxSplatnost=30;
            } else if (mortgageType==2) {
                maxUverDleProduktu=5000000;
                minUver=200000;
                maxSplatnost=20;
            } else {
                maxUverDleProduktu=0;
            }
            break;
            case "Komerční banka":
            procentoZKK=0.083333333;
            procentoZKKKB=0.03;
            procentoZKTK=0.05;
            maxVek=70;
        
            if (age < 36) {
                parametrDSTI=0.5;
                parametrDTI=9.5;
            } else {
                parametrDSTI=0.45;
                parametrDTI=8.5;
            }

            if (mortgageType == 1) {
                maxUverDleProduktu=1000000000;
                minUver=300000;
                maxSplatnost=30;
            } else if (mortgageType==2) {
                maxUverDleProduktu=5000000;
                minUver=200000;
                maxSplatnost=20;
            } else {
                maxUverDleProduktu=0;
            }
            break;
            case "mBank":
            procentoZKK=0.03;
            procentoZKKKB=0.03;
            procentoZKTK=0.03;
            maxVek=70;

            if (age < 36) {
                parametrDSTI=0.5;
                parametrDTI=9.5;
            } else {
                parametrDSTI=0.45;
                parametrDTI=8.5;
            }

            if (mortgageType == 1) {
                maxUverDleProduktu=15000000;
                minUver=200000;
                maxSplatnost=30;
            } else if (mortgageType==2) {
                maxUverDleProduktu=4000000;
                minUver=200000;
                maxSplatnost=20;
            } else {
                maxUverDleProduktu=0;
            }
            break;
            case "Moneta Money Bank":
            procentoZKK=0.05;
            procentoZKKKB=0.05;
            procentoZKTK=0.0555555556;
            maxVek=70;
        
            if (age < 36) {
                parametrDSTI=0.5;
                parametrDTI=9.5;
            } else {
                parametrDSTI=0.45;
                parametrDTI=8.5;
            }

            if (mortgageType == 1) {
                maxUverDleProduktu=1000000000;
                minUver=300000;
                if (fixation == 0) {
                maxSplatnost=25;
                } else {
                maxSplatnost=30;
                }
            } else if (mortgageType==2) {
                maxUverDleProduktu=1000000000;
                minUver=300000;
                maxSplatnost=20;
            } else {
                maxUverDleProduktu=0;
            }
            break;
            case "Raiffeisenbank":
            procentoZKK=0.035;
            procentoZKKKB=0.035;
            procentoZKTK=0.035;
            maxVek=70;
        
            if (age < 36) {
                parametrDSTI=0.5;
                parametrDTI=9.5;
            } else {
                parametrDSTI=0.45;
                parametrDTI=8.5;
            }

            if (mortgageType == 1) {
                maxUverDleProduktu=25000000;
                minUver=500000;
                maxSplatnost=30;
            } else if (mortgageType==2) {
                maxUverDleProduktu=5000000;
                maxSplatnost=20;
            } else {
                maxUverDleProduktu=0;
                minUver=100000;
            }
            break;
            case "Unicredit Bank":
            procentoZKK=0.029;
            procentoZKKKB=0.029;
            procentoZKTK=0.034;
            maxVek=70;
        
            if (age < 36) {
                parametrDSTI=0.5;
                parametrDTI=9.5;
            } else {
                parametrDSTI=0.45;
                parametrDTI=8.5;
            }

            if (mortgageType == 1) {
                maxUverDleProduktu=1000000000;
                minUver=200000;
                maxSplatnost=30;
            } else if (mortgageType==2) {
                maxUverDleProduktu=10000000;
                minUver=200000;
                maxSplatnost=20;
            } else {
                maxUverDleProduktu=0;
            }
            break;
            default:
            
        }



        vydajKK=procentoZKK*Math.max(limityKK-limityKKKB,0)+procentoZKKKB*limityKKKB;
        vydajKTK=procentoZKTK*limityKTK;
        vsechnySplatky=vydajKK+vydajKTK+otherInstallments;

        // ZM a ostatní výdaje

        switch(name) {
            case "Česká spořitelna":
                
            ZM=5500+Math.max(0,pocetDospelych-1)*6000+(pocetDetiDo10+pocetDetiNad10)*3320;
            bonitniVydaje=vyzivne+dalsiPravidelneVydaje+ZM+income*0.125;
            sazbaKBonite=(2.13+interestRate)/1200;
            splatnostKBonite=Math.min(maxSplatnost,maxVek-age,due)*12;
            zbyloNaSplatkuKBonite=income-bonitniVydaje-vsechnySplatky;
            break;
            
            case "Hypoteční banka":

            if (pocetOsob == 1) {
                ZM=4620+6232;
            } else if (pocetOsob > 1) {
                ZM=4250+Math.max(pocetDospelych-1,0)*3840+(pocetDetiDo10+pocetDetiNad10)*3320;
            


                if (pocetOsob == 2) {
                    ZM=ZM+8432;
                } else if (pocetOsob == 3) {
                    ZM=ZM+11561;
                } else if (pocetOsob>3) {
                    ZM=ZM+14368;   
                } else {
                ZM=0;
                }
            }


            bonitniVydaje=vyzivne+dalsiPravidelneVydaje+ZM;
            sazbaKBonite=interestRate/1200;
            splatnostKBonite=Math.min(maxSplatnost,maxVek-age,due)*12;
            zbyloNaSplatkuKBonite=(income-bonitniVydaje-vsechnySplatky)/1.25;
            break;
            
            case "Komerční banka":

            ZM=3010+Math.max(0,pocetDospelych-1)*2400+pocetDetiDo10*1950+pocetDetiNad10*2530;
            
            
            if (pocetOsob == 1) {
                ZM=ZM+1410;
            } else if (pocetOsob == 2) {
                ZM=ZM+2020;
            } else if (pocetOsob == 3) {
                ZM=ZM+2650;
            } else if (pocetOsob == 4) {
                ZM=ZM+2650;
            } else if (pocetOsob > 4) {
                ZM=ZM+3050;
            } else {
                ZM=0;   
            }
            
            if (pocetDospelych == 1) {
                ZM=ZM*1.3;
            } else if (pocetDospelych == 2) {
                ZM=ZM*1.2;
            } else {
                ZM=ZM*1.3;   
            }


            
            bonitniVydaje=vyzivne+dalsiPravidelneVydaje+ZM+income*0.15;
            sazbaKBonite=interestRate/1200;
            splatnostKBonite=Math.min(maxSplatnost,maxVek-age,due)*12;
            zbyloNaSplatkuKBonite=income-bonitniVydaje-vsechnySplatky;
            
            break;
            
            case "mBank":
                
            if (pocetOsob == 1) {
                ZM=4620;
            } else {
                ZM=4250+Math.max(pocetDospelych-1,0)*3840+(pocetDetiDo10+pocetDetiNad10)*2900;   
            }

            ZM=Math.max((pocetOsob*((5000*(pocetOsob**(-0.4)))**(1-0.4))*((income/pocetOsob)**(0.4))),ZM);
            bonitniVydaje=vyzivne+dalsiPravidelneVydaje+ZM;

            if (mortgageType == 1) {
                sazbaKBonite=Math.max((interestRate+2),9.54)/1200;
            } else {
                sazbaKBonite=Math.max((interestRate+2),9.77)/1200;
            }

            
            if (age > 59) {
                splatnostKBonite=Math.min(maxSplatnost,maxVek-age,due,7)*12;
            } else {
                splatnostKBonite=Math.min(maxSplatnost,maxVek-age,due)*12;
            }
                    
            zbyloNaSplatkuKBonite=Math.max((income-bonitniVydaje)*0.95-vsechnySplatky,0);

            break;
        
            case "Moneta Money Bank":

            ZM=Math.max(0,pocetDospelych-1)*3840+(pocetDetiDo10+pocetDetiNad10)*3320;
            
            
            if (pocetOsob == 1) {
                ZM=ZM+9672;
            } else if (pocetOsob == 2) {
                ZM=ZM+11463;
            } else if (pocetOsob == 3) {
                ZM=ZM+14078;
            } else if (pocetOsob > 3) {
                ZM=ZM+16471;
            } else {  
            }
            
            bonitniVydaje=vyzivne+dalsiPravidelneVydaje+ZM;
            sazbaKBonite=(interestRate+2)/1200;
            splatnostKBonite=Math.min(maxSplatnost,maxVek-age,due)*12;
            zbyloNaSplatkuKBonite=income-bonitniVydaje-vsechnySplatky;


            break;
            case "Raiffeisenbank":
            
            ZM=1

            if (pocetDospelych == 1) {
            
            } else if (pocetDospelych > 1) {
                ZM=ZM+0.75;
            } else {  
            }
            
            ZM=ZM+(pocetDetiDo10+pocetDetiNad10)*0.72;
            ZM=ZM*4250;
            sazbaKBonite=interestRate/1200;
            splatnostKBonite=Math.min(maxSplatnost,maxVek-age,due)*12;

            bonitniVydaje=vyzivne+ZM
            zbyloNaSplatkuKBonite=((income-bonitniVydaje-vsechnySplatky)*0.77)/(1+0.089*jeZP);

            break;


            case "Unicredit Bank":

            if (kraj == "Praha") {
            ZM=pocetDospelych*5378+(pocetDetiDo10+pocetDetiNad10)*5109;
            
                if (pocetOsob>0 && pocetOsob<3)
                {
                ZM = ZM + 6222;
                }
                else if (pocetOsob==3)
                {
                ZM=ZM+7466;  
                }
                else if (pocetOsob==4)
                {
                ZM=ZM+8711;  
                }
                else if (pocetOsob==5)
                {
                ZM=ZM+9955;  
                }
                else if (pocetOsob==6)
                {
                ZM=ZM+11200;  
                }
                else if (pocetOsob==7)
                {
                ZM=ZM+12444;  
                }
                else if (pocetOsob>7)
                {
                ZM=ZM+13664;  
                }
                else{
                }
            } else {  
                ZM=pocetDospelych*4706+(pocetDetiDo10+pocetDetiNad10)*4470;
                
                if (pocetOsob>0 && pocetOsob<3)
                {
                ZM = ZM + 5656;
                }
                else if (pocetOsob==3)
                {
                ZM=ZM+6788;  
                }
                else if (pocetOsob==4)
                {
                ZM=ZM+7919;  
                }
                else if (pocetOsob==5)
                {
                ZM=ZM+9050;  
                }
                else if (pocetOsob==6)
                {
                ZM=ZM+10182;  
                }
                else if (pocetOsob==7)
                {
                ZM=ZM+11313;  
                }
                else if (pocetOsob>7)
                {
                ZM=ZM+13664;  
                }
                else{
                }
            
            }
            sazbaKBonite=interestRate/1200;
            splatnostKBonite=Math.min(maxSplatnost,maxVek-age,due)*12;

            bonitniVydaje=income-ZM-vsechnySplatky;
            zbyloNaSplatkuKBonite=(0.8*bonitniVydaje-0.2*vsechnySplatky)/(1+0.8*0.055);


            break;
            default:
            
        }

        // Finální propočty

        maxUverBonity=Math.max(Math.floor((zbyloNaSplatkuKBonite*(1-Math.pow(1+sazbaKBonite,-splatnostKBonite))/(sazbaKBonite))/10000)*10000,0);

        if (name == "Česká spořitelna") {
        maxUverDSTI=Math.max(Math.floor(((income*parametrDSTI-vsechnySplatky)*(1-Math.pow(1+interestRate/1200*1.01461139,-splatnostKBonite))/(interestRate/1200*1.01461139))*(1+interestRate/100*0.04148)/10000)*10000,0);
        } else {
        maxUverDSTI=Math.max(Math.floor(((income*parametrDSTI-vsechnySplatky)*(1-Math.pow(1+interestRate/1200,-splatnostKBonite))/(interestRate/1200))/10000)*10000,0);
        }


        maxUverDTI=Math.max(Math.floor(((income*12*parametrDTI-objemNesplacenychUveru-limityKK-limityKTK))/10000)*10000,0);

        if (pocetDospelych == 1 && income<33334) {
        rattingCHB=1;
        } else {  
        rattingCHB=0;
        }

        maxUverCelkem=Math.min(maxUverBonity,maxUverDSTI,maxUverDTI,maxUverDleProduktu);

        if (maxUverCelkem < minUver) {
        maxUverCelkem=0;
        } else {  
        }

maxUverCelkemRezerva=maxUverCelkem-amount;

        putIntoResult("maxUverCelkem", maxUverCelkem);
        putIntoResult("maxUverCelkemRezerva", maxUverCelkemRezerva);
        putIntoResult("vydajeNaPosudek", vydajeNaPosudek);
        putIntoResult("vkladNaKatastr", poplatkySpecifickeProArgumentaci);

    }
}