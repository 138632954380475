import {observer} from "mobx-react-lite";
import { useInjection } from "../../../react-binding";
import MortgageMathService from "../../../services/MathServices/MortgageMath.service";
import { MortgageParams, StringMortgageParams } from "../../../constants/types";
import { InputWithSlider } from "../partials/InputWithSlider";
import UserInfo from "../../../services/UserInfo.service";
import { action } from "mobx";
import { calcDisclaimer } from "../../../constants/constants";
import { useEffect, useMemo, useState } from "react";
import { PrintHeader } from "../../main/PrintHeader";
import { BankService } from "../../../services/Bank/BankService";
import { getMostRecentNumericValue, numberToStringWithSpaces, sanitizeInput } from "../../../helpers/helpers";
import { Select } from "../partials/Select";
import { Line } from "react-chartjs-2";
import { PrintButton } from "../../main/PrintButton";
import { AnnuityCalcTableRow } from "./AnnuityCalcTableRow";
import { ImportExport } from "../partials/ImportExport";
import { PrintFooter } from "../../main/PrintFooter";

//@observer
export const AnnuityCalc = observer(() => {

    const mortgageMathService = useInjection(MortgageMathService);
    const userInfoService = useInjection(UserInfo);
    const bankService = useInjection(BankService) as BankService;

    const [showPrintHeader, setShowPrintHeader] = useState<boolean>(false);
    const [annuityData, setAnnuityData] = useState([] as any[]);

    const [genericAnnuityData, setGenericAnnuityData] = useState([] as any[]);
//    const [extraAmountsOrPayments, setExtraAmountsOrPayments] = useState([] as any[]);
//    const [iRates, setIrates] = useState([] as any[]);
//    const [dues, setDues] = useState([] as any[]);
    const [monthlyData, setMonthlyData] = useState<boolean>(false);

    const [variableAmount, setVariableAmount] = useState(false);
    const [variableDue, setVariableDue] = useState(false);
    const [variableIRate, setVariableIRate] = useState(false);
    const [notesVisible, setNotesVisible] = useState(false);
//    const [notes, setNotes] = useState([] as any[]);

    const setOneNote = action((note: string, index: number) => {
        const newNotes = [...userInfoService.annuityCalcData.notes];
//        const newNotes = [...notes];
        newNotes[index] = note;
        userInfoService.annuityCalcData.notes = newNotes;
//        debugger;
//        setNotes(newNotes);
    });

    const setStartingYear = action((newStartingYear: number) => {
        userInfoService.annuityCalcData.startingYear = newStartingYear;
    });

    const setOneExtraAmountOrPayment = action((newExtraAmountOrPayment: string, index: number) => {
        const newExtraAmountsOrPayments = [...userInfoService.annuityCalcData.extraAmountsOfPayments];
        newExtraAmountsOrPayments[index] = Number(newExtraAmountOrPayment);
        userInfoService.annuityCalcData.extraAmountsOfPayments = newExtraAmountsOrPayments;
//        setExtraAmountsOrPayments(newExtraAmountsOrPayments);
    });

    // This is a hack to ensure tha the print header is shown after the page is loaded
    useEffect(() => {
        setTimeout(() => {
            setShowPrintHeader(true);
        }, 1000);
    }, []);

    useEffect(() => {
        setArraysWithDefaults(true, true, true);
        recompute();
    }, []);

    useEffect(() => {
        recompute();
    }, [userInfoService.annuityCalcData.extraAmountsOfPayments, userInfoService.annuityCalcData.iRates, userInfoService.annuityCalcData.dues]);

    const setArraysWithDefaults = action((overrideDues = false, overrideExtraPayments = false, overrideIrates = false) => {
        const extraPaymentsResult: number[] = [];
        const iRatesResult: number[] = [];
        const duesResult: number[] = [];
        if (userInfoService.annuityCalcData.due === undefined) {
            return;
        }

//        const due = overrideDues ? sanitizeInput(userInfoService.annuityCalcData.due)*12 : userInfoService.annuityCalcData.dues.length;
//        const iRate = sanitizeInput(userInfoService.annuityCalcData.iRate ?? 0);
/*
        for (let i = 0; i <= due; i++) {

            if (userInfoService.annuityCalcData.extraAmountsOfPayments[i] === undefined || overrideExtraPayments) {
                extraPaymentsResult.push(0);
            } else {
                extraPaymentsResult.push(userInfoService.annuityCalcData.extraAmountsOfPayments[i]);
            }

            if (userInfoService.annuityCalcData.iRates[i] === undefined || overrideIrates) {
                iRatesResult.push(iRate);
            } else {
                iRatesResult.push(userInfoService.annuityCalcData.iRates[i] || userInfoService.annuityCalcData.iRates[i-1]);
            }

            if (userInfoService.annuityCalcData.dues[i] === undefined || overrideDues) {
                duesResult.push(due - i);
            } else {
                duesResult.push(userInfoService.annuityCalcData.dues[i]);
            }
        }
*/
        userInfoService.annuityCalcData.extraAmountsOfPayments = extraPaymentsResult;
//        setExtraAmountsOrPayments(extraPaymentsResult);
        userInfoService.annuityCalcData.iRates =  iRatesResult;
        userInfoService.annuityCalcData.dues = duesResult;
//        setDues(duesResult);
    });

    const setDateType = action((isMonthlyData: boolean) => {
        userInfoService.annuityCalcData.dues = [];
        setMonthlyData(isMonthlyData);
    });

    const setNewDue = action((newDue: number, index: number) => {
        const previousDue = getMostRecentNumericValue(userInfoService.annuityCalcData.dues, index-1, true);
        const diffWithIndex = previousDue - newDue + (monthlyData ? 0 : 11);

        const originalDue = sanitizeInput(userInfoService.annuityCalcData.due ?? 0)*12;

        let newDues = [...userInfoService.annuityCalcData.dues];
        newDues[index] = newDue;
        if (monthlyData) {
            if (index >= 1 && 
                (
                    diffWithIndex === 1
                    ||
                    (!previousDue && originalDue - index === newDue)
                )
            ) {
                delete newDues[index];
            }
        } else {
            if (index >= 12 && 
                (
                    diffWithIndex === 12
                    ||
                    (!previousDue && originalDue - index === newDue)
                )
            ) {
                delete newDues[index];
            }
        }

        userInfoService.annuityCalcData.dues = newDues;
    });

    const setNewIRate = action((newIRate: number, index: number) => {
        const newIRates = [...userInfoService.annuityCalcData.iRates];
        newIRates[index] = newIRate;
        const mostRecentIrate = getMostRecentNumericValue(userInfoService.annuityCalcData.iRates, index-1);

        if (!mostRecentIrate) {
            if (newIRate === sanitizeInput(userInfoService.annuityCalcData.iRate ?? 0)) {
                delete newIRates[index];
            }
        }

        if (newIRate === getMostRecentNumericValue(userInfoService.annuityCalcData.iRates, index-1)) {
            delete newIRates[index];
        }

        userInfoService.annuityCalcData.iRates = newIRates;
    });

    const recompute = action(() => {
        const data: MortgageParams = {};
        // @ts-ignore
        Object.keys(userInfoService.annuityCalcData).forEach((key: keyof StringMortgageParams) => {
            if (key !== userInfoService.annuityCalcData.computing) {
                // @ts-ignore
                data[key] = userInfoService.annuityCalcData[key]
            }
        });

        data[userInfoService.annuityCalcData.computing] = undefined;
        try {
            // Computes the missing parameter
            const result = mortgageMathService.calcMortgageViaMissingParam(data, true);
            userInfoService.annuityCalcData[userInfoService.annuityCalcData.computing] = Number(result).toLocaleString('cs');
            userInfoService.annuityCalcData.error = false;
        } catch(e) {
            userInfoService.annuityCalcData.error = true;
            console.warn(e);
        }

        // We have all data now, we can calculate the annuity calendar

        if (userInfoService.annuityCalcData.error ||
            userInfoService.annuityCalcData.amount === undefined ||
            userInfoService.annuityCalcData.iRate === undefined ||
            userInfoService.annuityCalcData.due === undefined ||
            userInfoService.annuityCalcData.installment  === undefined
        ) {
            return;
        }
        const newAnnuityData = mortgageMathService.calcAnnuityCalendar(
            {
                amount: userInfoService.annuityCalcData.amount,
                iRate: userInfoService.annuityCalcData.iRate,
                due: sanitizeInput(userInfoService.annuityCalcData.due)*12,
                installment: userInfoService.annuityCalcData.installment
            
            },
            userInfoService.annuityCalcData.extraAmountsOfPayments.map((val) => (-Number(val))),
            userInfoService.annuityCalcData.iRates.map((val) => Number(val)),
            userInfoService.annuityCalcData.dues.map((val) => Number(val))
        )

        setAnnuityData(newAnnuityData);

        const genericDues = [];
        const genericDue = sanitizeInput(userInfoService.annuityCalcData.due)*12;
        for (let i = 0; i <= genericDue; i++) {
            genericDues.push(genericDue - i);
        }

        const newGenericAnnuityData = mortgageMathService.calcAnnuityCalendar(
            {
                amount: userInfoService.annuityCalcData.amount,
                iRate: userInfoService.annuityCalcData.iRate,
                due: sanitizeInput(userInfoService.annuityCalcData.due)*12,
                installment: userInfoService.annuityCalcData.installment
            
            },

            newAnnuityData.map((val) => 0),
            newAnnuityData.map((val) => sanitizeInput(userInfoService.annuityCalcData.iRate ?? 0)),
            genericDues
        )

        setGenericAnnuityData(newGenericAnnuityData);
    })

    const setDefaultsAndRecompute = action(() => {
//        setArraysWithDefaults(true, true, true);
        recompute();
    });

    const RenderTable = observer(() => {
        return <span className="flex-center m-20">
            <table>
                <thead>
                    <tr>
                        <th>{monthlyData ? "Měsíc" : "Rok"}</th>
                        <th>Zůstatek úvěru</th>
                        <th>Výše splátky</th>
                        <th>Úrok v roce</th>
                        <th>Úrok celkem</th>
                        {variableAmount &&
                            <th>Změna výše úvěru +-</th>
                        }
                        { variableIRate &&
                            <th>Úroková sazba</th>                        
                        }
                        { variableDue &&
                            <th>Zbývající splatnost</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        annuityData.map((data, index) => {
                            if (!monthlyData && index % 12 !== 0) {
                                return null;
                            }

                            return <AnnuityCalcTableRow key={index}
                                nrOfPeriod={monthlyData ? index : index/12}
                                data={data}
                                index={index}
                                installment={numberToStringWithSpaces(Math.round(annuityData[index]?.installment ? annuityData[index].installment : 0))}
                                interest={numberToStringWithSpaces(Math.round(annuityData[index]?.interest ? annuityData[index].interest : 0))}
                                sumOfInterest={numberToStringWithSpaces(Math.round(annuityData[index]?.sumOfInterest ? annuityData[index].sumOfInterest : 0))}
                                variableAmount={variableAmount}
                                variableIRate={variableIRate}
                                variableDue={variableDue}
                                edited={
                                    !!userInfoService.annuityCalcData.extraAmountsOfPayments[index] || 
                                    !(index === 0 || !userInfoService.annuityCalcData.iRates[index]) ||
                                    !(index === 0 || !userInfoService.annuityCalcData.dues[index])
                                }
                                extraAmountOrPayment={userInfoService.annuityCalcData.extraAmountsOfPayments[index] ? userInfoService.annuityCalcData.extraAmountsOfPayments[index] : 0}
                                setExtraAmountOrPayment={setOneExtraAmountOrPayment}
                                iRate={
                                    getMostRecentNumericValue(userInfoService.annuityCalcData.iRates, index)
                                    ?? (sanitizeInput(userInfoService.annuityCalcData.iRate ?? 0) )
                                }
                                setNewIRate={setNewIRate}
                                due={
                                    monthlyData ?
                                        (
                                            getMostRecentNumericValue(userInfoService.annuityCalcData.dues, index, true) !== undefined ?
                                            getMostRecentNumericValue(userInfoService.annuityCalcData.dues, index, true)
                                            :
                                            (sanitizeInput(userInfoService.annuityCalcData.due ?? index)*12 - index)
                                        ) :
                                        (
                                            getMostRecentNumericValue(userInfoService.annuityCalcData.dues, index, true) !== undefined ?
                                            getMostRecentNumericValue(userInfoService.annuityCalcData.dues, index, true)/12
                                            :
                                            (sanitizeInput(userInfoService.annuityCalcData.due ?? index) - index/12)
                                        )
//                                    monthlyData ? userInfoService.annuityCalcData.dues[index] : userInfoService.annuityCalcData.dues[index]/12
                                }
                                setNewDue={setNewDue}
                                monthlyData={monthlyData}
                                notesOn={notesVisible}
                                note={userInfoService.annuityCalcData.notes[index] ? userInfoService.annuityCalcData.notes[index] : ""}
                                setNote={setOneNote}
                                startingYear={userInfoService.annuityCalcData.startingYear}
                                setStartingYear={setStartingYear}
                            />
                            /*
                            return <tr key={index}>
                                <td>{monthlyData ? index : index/12}</td>
                                <td>{numberToStringWithSpaces(Math.floor(data.amount || 0))} Kč</td>
                                <td>{numberToStringWithSpaces(Math.round(annuityData[index]?.installment ? annuityData[index].installment : 0))} Kč</td>
                                <td>
                                    {numberToStringWithSpaces(Math.round(annuityData[index]?.interest))} Kč
                                </td>
                                {variableAmount && <td>
                                    <span className="print-visible">{extraAmountsOrPayments[index] ? extraAmountsOrPayments[index] : 0} Kč</span>
                                    <span className="print-hidden">
                                        <input type="number"
                                            step={50000}
                                            max={data.amount || 0}
                                            defaultValue={extraAmountsOrPayments[index] ? extraAmountsOrPayments[index] : 0}
                                            onBlur={(e) => {
                                                const newExtraAmountsOrPayments = [...extraAmountsOrPayments];
                                                newExtraAmountsOrPayments[index] = e.target.value;
                                                setExtraAmountsOrPayments(newExtraAmountsOrPayments);
                                            }}
                                            onKeyDown={(e: any) => {
                                                if (e.key === 'Enter') {
                                                    e.target.blur();
                                                }
                                            }}
                                        />
                                    </span>                                    
                                </td>}
                                {variableIRate && <td>
                                    <span className="print-visible">{iRates[index]} %</span>
                                    <span className="print-hidden">
                                        <input type="number"
                                            defaultValue={iRates[index]}
                                            step={0.1}
                                            min={0}
                                            onBlur={(e) => {
                                                setNewIRate(sanitizeInput(e.target.value), index);
                                            }}
                                            onKeyDown={(e: any) => {
                                                if (e.key === 'Enter') {
                                                    e.target.blur();
                                                }
                                            }}
                                        />
                                    </span>
                                </td>}
                                {variableDue && <td>
                                    <span className="print-visible">{monthlyData ? dues[index] : dues[index]/12} {monthlyData ? "měsíců" : "let"}</span>
                                    <span className="print-hidden">
                                        <input type="number"
                                            defaultValue={monthlyData ? dues[index] : dues[index]/12}
                                            step={1}
                                            min={1}
                                            max={monthlyData ? 480 : 40}
                                            onBlur={(e) => {
                                                setNewDue(sanitizeInput(monthlyData ? e.target.value : Number(e.target.value)*12), index);
                                            }}
                                            onKeyDown={(e: any) => {
                                                if (e.key === 'Enter') {
                                                    e.target.blur();
                                                }
                                            }}
                                        />
                                    </span>
                                </td>}
                            </tr>
                            */
                        })
                    }
                </tbody>
            </table>
        </span>
    });

    const multiGraph = useMemo(() => {
        return variableAmount || variableDue || variableIRate;
    }, [variableAmount, variableDue, variableIRate]);

    function renderGraph(generic: boolean = false, small: boolean = false) {

        const usedData = generic ? genericAnnuityData : annuityData;

        const options = {
            plugins: {
                legend: {
                    display: true,
                    labels: {
                        color: 'rgb(0, 0, 0)'
                    }
                },
                title: {
                    display: true,
                    text: `${'Splátkový kalendář'} ${generic ? 's výchozími parametry' : ''}`,
                    font: {
                        size: 25
                    }
                },
                tooltip: {
                    callbacks: {
                        label: function(context: any) {
                            let label = context.dataset.label || '';
    
                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                label += new Intl.NumberFormat('cs-CS', { style: 'currency', currency: 'CZK', maximumFractionDigits:0 }).format(context.parsed.y);
                            }
                            return label;
                        }
                    }
                }
            }
        }

        const processedData = monthlyData ? usedData : usedData.filter((_, index) => index === 0 || index % 12 === 0);

        const data = {
            labels: processedData.map((_, index) => index),
            datasets: [
            {
                label: 'Suma plateb',
                data: processedData.map((result) => Math.round(result.sumOfPayments || 0)),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                hidden: false,
                order: 1
            },
            {
                label: 'Suma úroků',
                data: processedData.map((result) => Math.round(result.sumOfInterest || 0)),
                borderColor: 'rgb(255, 99, 0)',
                backgroundColor: 'rgba(255, 99, 0, 0.5)',
                hidden: false,
                order: 2
            },
            {
                label: 'Zbývající jistina',
                data: processedData.map((result) => Math.round(result.amount || 0)),
                borderColor: 'rgba(49, 128, 166, 0.5)', // Change border color to transparent
                backgroundColor: 'rgba(49, 128, 166, 0.5)', // Set background color for the area
                hidden: false,
                order: 3,
                fill: true,
                pointRadius: 2
            },
        ]
        };
        return <div className={`graph ${small ? "small" : ""}`}>
            {
                small ?
                <Line options={options} data={data} width={"350px"} height={"400px"}/>
                :
                <Line options={options} data={data} width={"350px"} height={"400px"}/>

            }
        </div>

    }

    return (
        <div className='app-screen annuity-calc'>
                {
                showPrintHeader &&
                <PrintHeader 
                    title= {`${bankService.dataService.wordPressUIData.annuityCalcName ? bankService.dataService.wordPressUIData.annuityCalcName : 'Splátkový kalendář'}`}
                    subtitle= {userInfoService.general.name}
                    hideDetails = {true}
                />
            }
                    <PrintFooter
                        hideDetails = {false}
                    />
                    <ImportExport/>
                    <div className="simple-calc-wrapper">
                        <div className="print-visible w-full">
                            <div className="print-inputs">
                                <div className="print-input-wrapper">
                                    <div className="simple-label">
                                        Úroková sazba
                                    </div>
                                    <div className="simple-value">
                                        {userInfoService.annuityCalcData.iRate} %
                                    </div>
                                </div>

                                <div className="print-input-wrapper">
                                    <div className="simple-label">
                                        Splatnost v letech
                                    </div>
                                    <div className="simple-value">
                                        {userInfoService.annuityCalcData.due} let
                                    </div>
                                </div>

                                <div className="print-input-wrapper">
                                    <div className="simple-label">
                                        Výše splátky
                                    </div>
                                    <div className="simple-value">
                                        {userInfoService.annuityCalcData.installment} Kč
                                    </div>
                                </div>

                                <div className="print-input-wrapper">
                                    <div className="simple-label">
                                        Výše úvěru
                                    </div>
                                    <div className="simple-value">
                                        {userInfoService.annuityCalcData.amount} Kč
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className="simple-hypo-input-section print-hidden">
                            <div className="simple-label">
                                Výše úvěru
                            </div>
                            <InputWithSlider
                                placeholder='Výše hypotéky'
                                min={100000}
                                max={10000000}
                                step={50000}
                                observable={userInfoService.annuityCalcData}
                                field={'amount'}
                                onChangeCallback = {setDefaultsAndRecompute}
                                textAfterInput = 'Kč'
                            />
                        </div>
                        <div className="simple-hypo-input-section print-hidden">
                            <div className="simple-label">
                                Úroková sazba
                            </div>
                            <InputWithSlider
                                placeholder='Úroková sazba'
                                min={0.0}
                                max={10}
                                step={0.1}
                                observable={userInfoService.annuityCalcData}
                                field={'iRate'}
                                onChangeCallback = {setDefaultsAndRecompute}
                                errorMessage = {'Výsledná sazba je mimo běžné hodnoty'}
                                textAfterInput = ' %'
                                showDecimals = {true}
                            />
                        </div>
                        <div className="simple-hypo-input-section print-hidden">
                            <div className="simple-label">
                                Splatnost v letech
                            </div>
                            <InputWithSlider
                                placeholder='Splatnost'
                                min={1}
                                max={40}
                                step={1}
                                observable={userInfoService.annuityCalcData}
                                field={'due'}
                                onChangeCallback = {setDefaultsAndRecompute}
                                errorMessage = {'Výsledná splatnost je vyšší než 40 let'}
                                textAfterInput = ' let'
                            />
                        </div>
                        <div className="simple-hypo-input-section print-hidden">
                            <div className="simple-label">
                                Výše splátky
                            </div>
                            <InputWithSlider
                                placeholder='Výše splátky'
                                min={100}
                                max={40000}
                                step={100}
                                observable={userInfoService.annuityCalcData}
                                field={'installment'}
                                onChangeCallback = {setDefaultsAndRecompute}
                                textAfterInput = ' Kč'
                                showDecimals = {false}
                            />
                        </div>

                        <div className="simple-hypo-extra-input-section print-hidden">
                            <div className="extra-input-wrapper">
                                <span>Měnit splatnost</span>
                                <input className="checkbox" type="checkbox" onChange={(e) => {
                                    setVariableDue(e.target.checked);

                                    if (!e.target.checked) {
                                        setArraysWithDefaults(true, false, false);
                                    }
                                }}/>
                            </div>

                            <div className="extra-input-wrapper">
                                <span>Mimořádné splátky/navýšení</span>
                                <input className="checkbox" type="checkbox" onChange={(e) => {
                                    setVariableAmount(e.target.checked);

                                    if (!e.target.checked) {
                                        setArraysWithDefaults(false, true, false);
                                    }
                                }}/>
                            </div>

                            <div className="extra-input-wrapper">
                                <span>Variabilní úroková sazba</span>
                                <input className="checkbox" type="checkbox" onChange={(e) => {
                                    setVariableIRate(e.target.checked);

                                    if (!e.target.checked) {
                                        setArraysWithDefaults(false, false, true);
                                    }
                                }}/>
                            </div>

                            <div className="extra-input-wrapper">
                                <span>Poznámky</span>
                                <input className="checkbox" type="checkbox" onChange={(e) => {
                                    setNotesVisible(e.target.checked);
                                }}/>
                            </div>

                            <div className="extra-input-wrapper">
                                <span className="simple-label">
                                    Kalendář:
                                </span>
                                <Select
                                    options={[
                                        {
                                            value: "false",
                                            label: "Roční"
                                        },
                                        {
                                            value: "true",
                                            label: "Měsíční"
                                        }
                                    ]}
                                    observable = {userInfoService.annuityCalcData}
                                    field = "dataMonthly"
                                    onChangeCallback = {(e: any) => {
                                        setDateType(e.target.value === "true" ? true : false)
//                                        setMonthlyData(e.target.value === "true" ? true : false)
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="results-wrapper">
                        <RenderTable/>
                        
                        <div className={`graph-wrapper ${multiGraph ? "multi-graph" : ""}`}>
                            {renderGraph(false, multiGraph)}
                            { multiGraph &&
                                renderGraph(true, true)
                            }
                        </div>
                    </div>

                    <PrintButton/>

            <div className="calc-disclaimer">{calcDisclaimer}</div>
    </div>
)})