import {observer} from "mobx-react-lite";
import { action, observable } from 'mobx';
import { InputWithSlider } from '../partials/InputWithSlider';
import { useInjection } from "../../../react-binding";
import UserInfo from "../../../services/UserInfo.service";

type CustomerInfoInputProps = {
    recompute: any
}

export const CustomerInfoInput = observer((props: CustomerInfoInputProps) => {

    const userInfoService = useInjection(UserInfo);

    return <div className='inputs-section'>
        <span className='field-wrapper'>
            <div className="field-name">
                Součet čistých příjmů žadatelů
            </div>
            <InputWithSlider
                observable = {userInfoService.userInfo}
                field = 'income'
                min = {0}
                max = {100000}
                step = {500}
                placeholder = {'Čistý měsíční příjem'}
                textAfterInput = ' Kč'
                onChangeCallback = {props.recompute}
            />
        </span>

        <span className='field-wrapper'>
            <div className='field-name'>
                Součet splátek jiných úvěrů
            </div>
            <InputWithSlider
                observable = {userInfoService.userInfo}
                field = 'otherInstallments'
                min = {0}
                max = {100000}
                step = {500}
                placeholder = {'Splátky jiných úvěrů'}
                textAfterInput = ' Kč'
                onChangeCallback = {props.recompute}
            />
        </span>
        <span className='field-wrapper'>
            <div className='field-name'>
                Věk nejmladšího žadatele
            </div>
            <InputWithSlider
                observable = {userInfoService.userInfo}
                field = 'age'
                min = {18}
                max = {70}
                step = {1}
                placeholder = {'Věk nejstaršího žadatele'}
                textAfterInput = ' let'
                onChangeCallback = {props.recompute}
            />
        </span>
    </div>
})