import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import logo from '../assets/hypokalk_logo.jpg';
import UserInfo from "../services/UserInfo.service";
//import { LetterForArgumentationService } from './Bank/LetterForArgumentationService';
//import { useInjection } from '../react-binding';

// Register font
Font.register({ family: 'ubuntu', src: "https://pdf-lib.js.org/assets/ubuntu/Ubuntu-R.ttf" });
Font.register({ family: 'ubuntuBold', src: "https://pdf-lib.js.org/assets/ubuntu/Ubuntu-B.ttf" });

interface LetterParams {
  originalFutureAmount: number,
  originalInstallment: number,
  newFutureAmount: number,
  newInstallment: number,
  originalIRate: number | string,
  newIRate: number | string,
  newBank: string,
  insurance: boolean,
  sumOfFeesOriginal: number,
  sumOfFeesNew: number
}

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    fontFamily: 'ubuntu',
    padding: "40px",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  header: {
    height: "100px",
    width: "100%",
    display: "flex"
  },
  logo: {
    height: "100px",
    width: "100px"
  },
  forAndDateSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "14px"
  },
  mainSection: {
    marginTop: 15
  },
  boldText: {
    fontFamily: "ubuntuBold",
    fontSize: "14px",
    lineHeight: 1.5
  },
  twoTextNextToEachOther: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    marginTop: 0
  },
  paramsSection: {
    width: "250px",
    textAlign: "left",
    fontSize: "14px",
  },
  genericTextSection: {
    fontSize: "14px",
    lineHeight: 1.5
  },
  note: {
    fontSize: "12px",
    lineHeight: 1.1
  },
  disclaimer: {
    fontSize: "12px",
    lineHeight: 1.1,
    marginTop: "10px"
  }
});

// Create Document Component
export const PDFLetter = (props: any) => {
//  const url = URL.createObjectURL(logo);
//const letterForArgumentationService = useInjection(LetterForArgumentationService);
function generateLetterText(input: LetterParams, userInfo: UserInfo) {
  const letterForArgumentationService = props.params.letterForArgumentationService;
  // většinu chci přečíst z mortgage params asi?
  // a do inputů dát jen original a new hodnoty?
  const {amount, due, fixation, valueOfSecurity} = userInfo.mortgageParams;
  const rateDifference = (typeof input.originalIRate === "number" ? input.originalIRate : parseFloat(input.originalIRate.replace(',', '.'))) - 
      (typeof input.newIRate === "number" ? input.newIRate : parseFloat(input.newIRate.replace(',', '.')));

  const originalIrateToDisplay = letterForArgumentationService.irateToString(userInfo.userInfo.benchmarkRate);
  const newIrateToDisplay = letterForArgumentationService.irateToString(input.newIRate);
  const irateDiffToDisplay = letterForArgumentationService.irateToString(rateDifference);

  const sumOfOriginalInstallments = Math.round(input.originalInstallment*fixation*12);
  const sumOfNewInstallments = Math.round(input.newInstallment*fixation*12);
  const sumOfFeesNew = input.sumOfFeesNew;

  const advantage = Math.round((sumOfOriginalInstallments - sumOfNewInstallments) + (input.originalFutureAmount - input.newFutureAmount) - sumOfFeesNew);
  const advantageToWholeThousands = Math.round(advantage/1000)*1000;

  return(<>
    <Text style={styles.genericTextSection}>{`
      Vážení, \n
      byli jsme požádáni o analýzu současné úrovně úrokových sazeb hypotečních úvěrů na bydlení pro účely porovnání navržené sazby. Pracovali jsme s těmito parametry: 
      `} </Text>
      <View style={styles.twoTextNextToEachOther}>
        <Text style={styles.paramsSection}>{`Zbývající splatnost úvěru: ${userInfo.mortgageParams.due} let`}</Text>
        <Text style={styles.paramsSection}>{`Délka fixace v letech: ${fixation}`}</Text>
      </View>
      <View style={styles.twoTextNextToEachOther}>
        <Text style={styles.paramsSection}>{`Objem úvěru: ${amount} Kč`}</Text>
        <Text style={styles.paramsSection}>{`Hodnota zástavy: ${valueOfSecurity} Kč`}</Text>
      </View>
      <View style={styles.twoTextNextToEachOther}>
        <Text style={styles.paramsSection}>{`Měsíční příjmy: ${userInfo.userInfo.income} Kč`}</Text>
        <Text style={styles.paramsSection}>{`Se slevou za pojištění: ${input.insurance ? 'ano' : 'ne'}`}</Text>
      </View>
      <View style={styles.twoTextNextToEachOther}>
        <Text style={styles.paramsSection}>{`Navrhovaná úroková sazba: ${originalIrateToDisplay} %`}</Text>
      </View>
    
    <Text style={styles.genericTextSection}>{`
      Dle poskytnutých informací a aktuální nabídky úrokových sazeb se domníváme, že pro daný případ je možné na trhu získat sazbu`}
      <Text style={styles.boldText}>{` ${newIrateToDisplay} % `}</Text>
      <Text>{`(${input.newBank}), což je o`}</Text><Text style={styles.boldText}>{` ${irateDiffToDisplay} p. b. `}</Text><Text>{`sazba nižší, než jste navrhli. \n
      Za celou fixaci činí rozdíl mezi nabídkami zhruba`}</Text><Text style={styles.boldText}>{` ${letterForArgumentationService.formatWholeNumberToDisplay(advantageToWholeThousands)} Kč*.`}</Text>
      <Text style={styles.genericTextSection}>{` Věříme, že jste schopni svoji nabídku ještě vylepšit. Pokud ne, rádi na tento případ doporučíme vhodné zprostředkovatele, kteří dokáží najít zajímavější nabídku.  \n 
      S pozdravem
      Tým Hypokalk
    `} </Text>
    </Text>

    <Text style={styles.note}>
      * Rozdíl nabídek je vypočten jako rozdíl mezi součtem splátek za celou dobu fixace {`(cca ${letterForArgumentationService.formatWholeNumberToDisplay(Math.round((sumOfOriginalInstallments - sumOfNewInstallments)))} Kč)`} plus rozdíl v nesplacených jistinách na konci fixace {`(cca ${letterForArgumentationService.formatWholeNumberToDisplay(Math.round((input.originalFutureAmount - input.newFutureAmount)))} Kč)`}. Od výsledku jsme navíc odečetli sumu poplatků {`(${letterForArgumentationService.formatWholeNumberToDisplay(sumOfFeesNew)} Kč)`} u nového úvěru po dobu fixace. Tato částka zahrnuje i 2krát současnou výši poplatku za návrh na vklad zástavního práva do katastru nemovitostí. Výsledek je zaokrouhlen na celé tisíce.
    </Text>

    <Text style={styles.disclaimer}>      
      Upozornění: Tento dokument není nabídkou ke sjednání spotřebitelského úvěru. Společnost Hypokalk s. r. o. poskytuje služby rešerží a analýz trhu. Úroveň sazeb na trhu byla zjištěna z dostupných nabídkových sazeb a závisí na informacích dodaných zadavatelem.
    </Text>


    </>)
}

return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.logo}>
            <Image src={logo}></Image>
          </View>
        </View>
        <View style={styles.forAndDateSection}>
          {props.params.userInfoService.mortgageParams.filterBank !== "Jiná" &&
            <Text>Pro: {props.params.userInfoService.mortgageParams.filterBank}</Text>
          }
          <Text>{props.params.letterForArgumentationService.generateDateString()}</Text>
        </View>
        <View style={styles.mainSection}>
          {generateLetterText(props.params.params, props.params.userInfoService)}
        </View>
      </Page>
    </Document>
)};