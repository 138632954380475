export const genericVariableCondition = {
    "field": ["mortgageParams", "amount"],
    "comparisonType": "lessOrEqualThan",
    "conditionValue": "0"
}

export const genericVariable = {
    "variableName": "GENERIC_NAME",
    "mustPassConditions": "all",
    "conditions": [
        {
            "field": ["mortgageParams", "amount"],
            "comparisonType": "lessOrEqualThan",
            "conditionValue": "0"
        }
    ]
}

export const genericBonityVariable = {
    "variableName": "GENERIC_NAME",
    "mustPassConditions": "all",
    "conditions": [
        {
            "field": ["interestRate"],
            "comparisonType": "lessOrEqualThan",
            "conditionValue": "0"
        }
    ]
}

export const genericIfStatement = {
    "variableName": "GENERIC_NAME",
    "variablesThatMustPass": ["GENERIC_NAME"],
    "then": {
        "variablesThatMustPass": ["nad8MKc"]
    },
    "else": {
        "variablesThatMustPass": ["do500kKc"]
    }
}

export const genericThenOrElseStatement = {
    "variablesThatMustPass": ["GENERIC_NAME"]
}

export const genericRateModifier = {
    "rateRuleName": "GENERIC_NAME",
    "modifier": -0.5,
    "listOfAllVariablesThatMustPass": [
        "GENERIC_NAME"
    ]
}

export const genericBank = {
    "visible": 1,
    "name": "GENERIC BANK",
    "priborTypeForFloat": "3 měsíce",
    "iRates": {
        "1": {
            "baseRates": {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5,
                "6": 6,
                "7": 7,
                "8": 8,
                "9": 9,
                "10": 10,
                "15": 15,
                "20": 20,
                "25": 25,
                "30": 30
            }
        },

        "2": {
            "baseRates": {
                "0": 5,
                "1": 6,
                "2": 7,
                "3": 8,
                "4": 9,
                "5": 10
            }
        }
    },

    "productsModifiers": {
        "bankProducts": ["GenericAccount"],
        "insuranceProducts": ["GenericInsurance"],
        "others": []
    },

    "variables": [
    ],
    "ifStatements": [

    ],
    "generalRatesRules": [
    ],
    "bonityVariables": [
    ],
    "bonityIfStatements": [
    ],
    "bonityVariablesThatMustPass": [],
    "feeRules": [
    ]
}