import {observer} from "mobx-react-lite";
import { useInjection } from "../../../react-binding";
import { FireBaseService } from "../../../services/FirebaseService";
import { LeadScreenState } from "./LeadScreen.state";
import { FirebaseData } from "../../../constants/types";
import { defaultDisclaimerUrl, defaultLeadMail, krajeCR } from "../../../constants/constants";
import { Select } from "../../Alpha/partials/Select";
import { TextInput } from "../../Alpha/partials/TextInput";
import { action } from "mobx";
import UserInfo from "../../../services/UserInfo.service";
import { useState } from "react";
import { NavigationService } from "../../../services/NavigationService/NavigationService";

//@observer
export const LeadScreen = observer(() => {

    const fireBaseService = useInjection(FireBaseService);
    const leadScreenState = useInjection(LeadScreenState);
    const userInfoService = useInjection(UserInfo);
    const navigationService = useInjection(NavigationService);

    const [consentGranted, setConsentGranted] = useState(false);

    const handleSubmit = action(() => {
        try {
            sendMail()
            .then(() => {
                writeToDB()
            })
        } catch(e) {
            console.error("SOMETHING WENT WRONG:", e);
        }
    })

    async function sendMail() {
        return new Promise(resolve => {
            const textAreaElement = document.getElementById('lead-message') as HTMLTextAreaElement;
            const request = new XMLHttpRequest();
            request.onreadystatechange = function() {
                if (this.readyState == 4 && this.status == 200) {
                    return resolve("SUCCESS");
                }
            }


            const sendTo = navigationService.domainSettings?.leadMail ?? defaultLeadMail;
        
            const urlEndpoint = "https://phpscripts.hypokalk.cz/mail_hypokalk_cz.php"
            const queryParams = `?name=${leadScreenState.state.name}&kraj=${leadScreenState.state.area}&telefon=${leadScreenState.state.phoneNr}&mail=${leadScreenState.state.email}&zprava=${textAreaElement?.value}&prijemce=${sendTo}`
            const finalUrl = urlEndpoint+encodeURI(queryParams);
            request.open("POST", finalUrl, true)
            request.send();
        })
    }

    const writeToDB = action(() => {
        const now = new Date().toISOString();
        const date = now.split('T')[0];
        const time = now.split('T')[1].split(".")[0];
        const name = leadScreenState.state.name;
        const email = leadScreenState.state.email;
        const phoneNr = leadScreenState.state.phoneNr;
        const area = leadScreenState.state.date;
        const textAreaElement = document.getElementById('lead-message') as HTMLTextAreaElement;
        const message = textAreaElement.value;
        const userInfo = {
            parametry_hypoteky: userInfoService.mortgageParams,
            uzivatelske_parametry: userInfoService.userInfo
        }

        fireBaseService.writeLeadToDb({date, time, name, email, phoneNr, area: area ? area : '', message, userInfo});
        leadScreenState.dataSent.value = true;
    });

    const checkBoxChange = action((e: React.ChangeEvent<HTMLInputElement>) => {
        leadScreenState.consentGranted.value = e.target.checked;
        validateFields();
    })

    function isPhoneNrValid() {
        return leadScreenState.state.phoneNr.replaceAll(' ','')
        .match(
        /^([\+][0-9]{1,3})?[0-9]{9}$/im
        )
    }

    function isEmailValid() {
        return leadScreenState.state.email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        )
    }

    function isNameValid() {
        // Anything is allowed in the name field
        return !!leadScreenState.state.name;
    }

    function validateFields() {
        const state = leadScreenState.state;
        const phoneNumberValid = isPhoneNrValid();
        const emailValid = isEmailValid();
        const nameValid = isNameValid();

        if (phoneNumberValid && emailValid && nameValid && leadScreenState.consentGranted.value && state.area) {
            leadScreenState.valid.value = true;
        } else {
            leadScreenState.valid.value = false;
        }
    }

    return (<span className="hypolead">

        <h1>Pomoc se sjednáním hypotečního úvěru</h1>

        {!leadScreenState.dataSent.value ? <>
            <p>
                Pokud máte zájem o pomoc se sjednáním úvěru na bydlení či o nezávaznou nabídku v této oblasti, vyplňte níže uvedený formulář. 
            </p>

            <div className="inputs-section">

                <div className={`field-wrapper ${isNameValid() ? '' : 'invalid-input'}`}>
                        <span className="field-name">
                            Jméno a příjmení:
                        </span>
                            <TextInput
                                observable = {leadScreenState.state}
                                field = "name"
                                onChangeCallback = {validateFields}
                                placeholder = "Jméno a příjmení"
                            />
                </div>

                <div className={`field-wrapper ${leadScreenState.state.area ? '' : 'invalid-input'}`}>
                    <span className="field-name">
                        Kraj:
                    </span>
                        <Select
                            observable = {leadScreenState.state}
                            field = "area"
                            options = {krajeCR.map((item, index) => {
                                return {
                                    value: item,
                                    label: item,
                                }
                            })}
                            onChangeCallback = {validateFields}
                        />
                </div>
                    
                <div className={`field-wrapper ${isPhoneNrValid() ? '' : 'invalid-input'}`}>
                    <span className="field-name">
                        Telefon:
                    </span>
                        <TextInput
                            observable = {leadScreenState.state}
                            field = "phoneNr"
                            onChangeCallback = {validateFields}
                            placeholder = "Vaše telefonní číslo"
                        />
                </div>

                <div className={`field-wrapper ${isEmailValid() ? '' : 'invalid-input'}`}>
                    <span className="field-name">
                        Email:
                    </span>
                        <TextInput
                            observable = {leadScreenState.state}
                            field = "email"
                            onChangeCallback = {validateFields}
                            placeholder = "Vaše emailová adresa"
                        />
                </div>
            </div>

            <div className="text-area-wrapper">
                <div className="field-name">
                    Vzkaz (nepovinné, max. 350 znaků)
                </div>
                <textarea
                    id="lead-message"
                    rows={5}
                    cols={50}
                    maxLength={350}
                />
            </div>

            <div className={`consent-check ${leadScreenState.consentGranted.value ? '' : 'invalid-input'}`}>
                <input type="checkbox" onChange={(e) =>
                    checkBoxChange(e)
                }/>
                Souhlasím s <a className="link-like" target="_blank" href={navigationService.domainSettings?.disclaimerUrl ?? defaultDisclaimerUrl}>podmínkami</a> zpracování osobních údajů
            </div>


            <button className="submit-button" onClick={() => handleSubmit()} disabled={!leadScreenState.valid.value}>
                {leadScreenState.valid.value ? 'Odeslat kontakt' : 'Vyplňte všechna povinná pole'}
            </button>
        </> : <>
            <p>
                Děkujeme, váš kontakt byl zaznamenán. V nejbližší době jej předáme finančnímu poradci, který se s vámi spojí.
            </p>
        </>}
    </span>)
})