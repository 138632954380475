import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useInjection } from "../../../react-binding";
import UserInfo from "../../../services/UserInfo.service";
import { InputWithSlider } from "../../Alpha/partials/InputWithSlider";
import { action } from "mobx";
import MortgageMathService from "../../../services/MathServices/MortgageMath.service";
import { getMonthlyIrateIndexFromAnnualIrate, mergeHypoVsRentResults, sanitizeInput } from "../../../helpers/helpers";
import { AnyTypeCompleteMortgageParams, StringMortgageParams } from "../../../constants/types";
import InvestmentMathService from "../../../services/MathServices/InvestmentMath.service";
import { HypoVsRentComparisonTable } from "../../Alpha/Calcs/HypoVsRentComparisonTable";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Select } from "../../Alpha/partials/Select";
import { PrintButton } from "../PrintButton";
import { PrintHeader } from "../PrintHeader";
import { BankService } from "../../../services/Bank/BankService";
import { Info, InfoCircle } from "react-bootstrap-icons";
//import { QuestionCircle } from "react-bootstrap-icons";

// Vzor: https://www.valuo.cz/koupe-nebo-pronajem

/*
Co potřebuju za vstupy:
- Cena nemovitosti
- Vlastní prostředky na začátku
- Výše úrokové sazby
- Předpokládaný výnos investic
- Splatnost hypotéky
- Výše nájmu
- Předpokládaná inflace? - NA KONEC, zatím bych to tam nedával
- Předpokládaný růst cen nemovitostí? - NA KONEC, zatím bych to tam nedával
- Předpokládaný růst nájmu? - NA KONEC, zatím bych to tam nedával

FLOW:
- mám vstupy
- vypočtu si splátku hypotéky, ukážu ji někde
- udělám si splátkový kalendář
- bacha, asi bych tam měl udělat nějakou kolonku, zdali chci počítat s daňovým zvýhodněním z úroků!!!
--- TOHLE UŽ v podstatě mám v anuitní kalkulače (simplehypo), jen musím udělat ten kalendář
- pak musím udělat kalendář pro investice
    - na začátku zainvestuju vlastní prostředky
    - dál budu pravidelně investovat částku, která je rozdíl mezi splátkou a nájmem
*/


export const HypoVsRent = observer(() => {

    const mortgageService = useInjection(MortgageMathService) as MortgageMathService;
    const investmentMathService = useInjection(InvestmentMathService) as InvestmentMathService;
    const bankService = useInjection(BankService) as BankService;
    const [advancedInput, setadvancedInput] = useState<boolean>(false);
    const [result, setResult] = useState<any>(null);
    const [investmentForRentResult, setInvestmentForRentResult] = useState<any>(null);
    const [mortgageCompleteResult, setMortgageCompleteResult] = useState<any>(null);
    const userInfoService = useInjection(UserInfo) as UserInfo;

    const [showPrintHeader, setShowPrintHeader] = useState<boolean>(false);

    // This is a hack to ensure tha the print header is shown after the page is loaded
    useEffect(() => {
        setTimeout(() => {
            setShowPrintHeader(true);
        }, 1000);
    });

    const recompute = action(() => {
        /*
        In case of simple input, inflation rate will be used for both rent and property value growth
        */
        if (!advancedInput) {
            userInfoService.mortgageVsRentData.rentGrowth = userInfoService.mortgageVsRentData.inflation;
            userInfoService.mortgageVsRentData.propertyValueGrowth = userInfoService.mortgageVsRentData.inflation;
            userInfoService.mortgageVsRentData.propertyCostsGrowth = userInfoService.mortgageVsRentData.inflation;
            userInfoService.mortgageVsRentData.taxRefundRate = '15,00';
        }

        const data = userInfoService.mortgageVsRentData;
        const sanitizedPropertyValue = sanitizeInput(data.valueOfProperty);
        const sanizizedInitialFunds = sanitizeInput(data.initialFunds);
        const amount = sanitizedPropertyValue - sanizizedInitialFunds;
        const rent = sanitizeInput(data.rent);
        const investmentYield = sanitizeInput(data.investmentYield);
        const due = sanitizeInput(data.due);
        const propertyUpkeepCosts = sanitizeInput(data.propertyUpkeepCosts);
        const valueOfProperty = sanitizeInput(data.valueOfProperty);
        const taxRefundRate = sanitizeInput(data.taxRefundRate);

        const yearlyValueOfPropertyIndex = getMonthlyIrateIndexFromAnnualIrate(sanitizeInput(data.propertyValueGrowth));
        const yearlyPropertyCostsIndex = getMonthlyIrateIndexFromAnnualIrate(sanitizeInput(data.propertyCostsGrowth));

        const mortgageData : StringMortgageParams = {
            amount: amount.toString(),
            iRate: data.iRate,
            due: data.due,

        }
        /*
        Potřebuju spočítat:
            - výši splátky DONE
            - slpátkový kalendář: budhodnoty a úroky za každý měsíc: DONE
            - investiční kalendář pro nájem
            - investiční / extra platbový kalendář pro hypotéku
            - vývoj hodnoty nemovitosti
        */

        // Mortgage installment
        const installment = mortgageService.calcMortgageViaMissingParam(mortgageData);
        mortgageData.installment = installment;

        const repaymentSchedule = mortgageService.calcRepaymentSchedule(mortgageData as AnyTypeCompleteMortgageParams, true, propertyUpkeepCosts, valueOfProperty, yearlyValueOfPropertyIndex, yearlyPropertyCostsIndex, taxRefundRate);

        const rentInvestmentData = {
            presentValue: sanizizedInitialFunds,
            iRate: investmentYield,
            due: due,
            installment: installment,
            rent: rent,
            rentGrowth: sanitizeInput(data.rentGrowth),
            yearlyInvestment: 0
        }

        const investmentForRent = investmentMathService.calcInvestmentSchedule(rentInvestmentData);
        const mortgageCompleteResult = investmentMathService.addInvestmentValuesToMortgageSchedule(repaymentSchedule, investmentYield);

        const overallYearlyResult = mergeHypoVsRentResults(
            mortgageCompleteResult,
            investmentForRent,
            mortgageData as AnyTypeCompleteMortgageParams,
            rentInvestmentData);


        setResult(overallYearlyResult);
        setInvestmentForRentResult(investmentForRent);
        setMortgageCompleteResult(mortgageCompleteResult);
        // This should take all results, add property values, merge them, apply inflation rate and 
        // maybe compute net worth for both cases?
        const completeResult = {};
    })

    useEffect(() => {
        recompute();
    }, []);

    function ResultSection() {
        return <>
            <HypoVsRentComparisonTable
                investmentForRentResult={investmentForRentResult}
                mortgageCompleteResult={mortgageCompleteResult}
                overallYearlyResult={result}
            />
        </>
    }

    function tooltipsSection() {
        return <>
            <ReactTooltip
                id="inflation-tooltip"
                place="top"
                content="Inflace ovlivňuje vývoj hodnoty nemovitosti, nájmu a roční náklady na vlastnictví nemovitosti."
                className="tooltip"
            />

            <ReactTooltip
                id="value-of-property-index-tooltip"
                place="top"
                content="Ovlivňuje růst ceny nemovitosti a růst ročních nákladů na nemovitost."
                className="tooltip"
            />

            <ReactTooltip
                id="inflation-input-tooltip"
                place="top"
                content="Inflace ovlivňuje vývoj hodnoty nemovitosti, nájmu a roční náklady na vlastnictví nemovitosti. Jednotlivé složky lze upravit v rozšířených vstupech."
                className="tooltip"
            />

            <ReactTooltip
                id="ownership-cost-input-tooltip"
                place="top"
                content="Počáteční průměrné roční náklady pro variantu vlastního bydlení - zahrnují například náklady na údržbu nemovitostí, daně a další poplatky spojené s vlastnictvím apod."
                className="tooltip"
            />

            <ReactTooltip
                id="rent-cost-input-tooltip"
                place="top"
                content="Počáteční výše měsíčních nákladů na bydlení pro variantu nájmu."
                className="tooltip"
            />

            <ReactTooltip
                id="investment-yield-tooltip"
                place="top"
                content="Průměrné očekávané zhodnocení investic pro variantu nájmu. Jedná se o čisté zhodnocení."
                className="tooltip"
            />

            <ReactTooltip
                id="tax-rate-tooltip"
                place="top"
                content="Daňová szba ovlivňuje výpočet daňového zvýhodnění."
                className="tooltip"
            />
        </>
    }

    return( 
        <div className='app-screen'>
            {
                showPrintHeader &&
                <PrintHeader title= {`${bankService.dataService.wordPressUIData.hypovsnajemName ? bankService.dataService.wordPressUIData.hypovsnajemName : 'Porovnání hypotéky a nájmu'}`}/>
            }

            <div className="simple-calc-wrapper hypo-rent-calc-wrapper">
                <div className="simple-hypo-input-section">
                    <div className="simple-label">
                        Hodnota nemovitosti
                    </div>
                    <InputWithSlider
                    observable = {userInfoService.mortgageVsRentData}
                    field = 'valueOfProperty'
                    min = {0}
                    max = {15000000}
                    step = {100000}
                    placeholder = {'Cena nemovitosti'}
                    textAfterInput = ' Kč'
                    onChangeCallback = {() => recompute()}
                    />
                </div>
                <div className="simple-hypo-input-section">
                    <div className="simple-label">
                        Průměrná sazba hypotéky
                    </div>
                    <InputWithSlider
                        placeholder='Úroková sazba'
                        min={0.01}
                        max={10}
                        step={0.01}
                        observable={userInfoService.mortgageVsRentData}
                        field={'iRate'}
                        onChangeCallback = {recompute}
                        errorMessage = {'Výsledná sazba je mimo běžné hodnoty'}
                        textAfterInput = ' %'
                        showDecimals = {true}
                    />
                </div>
                <div className="simple-hypo-input-section">
                    <div className="simple-label">
                        Splatnost v letech
                    </div>
                    <InputWithSlider
                        placeholder='Splatnost'
                        min={1}
                        max={40}
                        step={1}
                        observable={userInfoService.mortgageVsRentData}
                        field={'due'}
                        onChangeCallback = {recompute}
                        errorMessage = {'Výsledná splatnost je vyšší než 40 let'}
                        textAfterInput = ' let'
                    />
                </div>

                <div className="simple-hypo-input-section">
                    <div className="simple-label">
                        Výše vlastních prostředků
                        </div>
                    <InputWithSlider
                        placeholder='Vlastní prostředky'
                        min={0}
                        max={10000000}
                        step={50000}
                        observable={userInfoService.mortgageVsRentData}
                        field={'initialFunds'}
                        onChangeCallback = {recompute}
                        textAfterInput = ' Kč'
                    />
                </div>

                <div className="simple-hypo-input-section">
                    <div className="simple-label">
                        Výše nájmu
                        <InfoCircle
                            data-tooltip-id="rent-cost-input-tooltip"
                            className="tooltip-icon pointer"
                        />
                    </div>
                    <InputWithSlider
                        placeholder='Výše nájmu'
                        min={0}
                        max={100000}
                        step={500}
                        observable={userInfoService.mortgageVsRentData}
                        field={'rent'}
                        onChangeCallback = {recompute}
                        textAfterInput = ' Kč'
                    />
                </div>

                <div className="simple-hypo-input-section">
                    <div className="simple-label">
                        Předpokládaný výnos investic
                        <InfoCircle
                            data-tooltip-id="investment-yield-tooltip"
                            className="tooltip-icon pointer"
                        />
                    </div>
                    <InputWithSlider
                        placeholder='Výnos investic'
                        min={0}
                        max={10}
                        step={0.1}
                        observable={userInfoService.mortgageVsRentData}
                        field={'investmentYield'}
                        onChangeCallback = {recompute}
                        textAfterInput = ' %'
                        showDecimals = {true}
                    />
                </div>

                <div className="simple-hypo-input-section">
                    <div className="simple-label">
                        Roční náklady na vlastnictví nemovitosti
                        <InfoCircle 
                            data-tooltip-id="ownership-cost-input-tooltip"
                            className="tooltip-icon pointer"
                        />
                    </div>
                    <InputWithSlider
                        placeholder='Předpokládané náklady na nemovitost'
                        min={0}
                        max={1000000}
                        step={1000}
                        observable={userInfoService.mortgageVsRentData}
                        field={'propertyUpkeepCosts'}
                        onChangeCallback = {recompute}
                        textAfterInput = ' Kč'
                        showDecimals = {false}
                    />
                </div>

                {advancedInput ? <>
                    <div className={`simple-hypo-input-section`}>
                        <div className="simple-label">
                            Roční růst nákladů na vlastnictví nemovitosti
                            {/*
                            <QuestionCircle 
                                data-tooltip-id="inflation-tooltip"
                                className="tooltip-icon"
                            />
        */}
                        </div>
                        <InputWithSlider
                            placeholder='Roční růst nákladů na nemovitost'
                            min={0}
                            max={10}
                            step={0.1}
                            observable={userInfoService.mortgageVsRentData}
                            field={'propertyCostsGrowth'}
                            onChangeCallback = {recompute}
                            textAfterInput = ' %'
                            showDecimals = {true}
                        />
                    </div>

                <div className={`w-full simple-calc-wrapper collapsible p-bottom-10`}>
                    <div className="simple-hypo-input-section">
                        <div className="simple-label">
                            Předpokládaný růst cen nemovitostí
                        </div>
                        <InputWithSlider
                            placeholder='Předpokládaný růst cen nemovitostí'
                            min={0}
                            max={10}
                            step={0.1}
                            observable={userInfoService.mortgageVsRentData}
                            field={'propertyValueGrowth'}
                            onChangeCallback = {recompute}
                            textAfterInput = ' %'
                            showDecimals = {true}
                        />
                    </div>

                    <div className="simple-hypo-input-section">
                        <div className="simple-label">
                            Předpokládaný růst nájmu
                        </div>
                        <InputWithSlider
                            placeholder='Růst nájmu'
                            min={0}
                            max={10}
                            step={0.1}
                            observable={userInfoService.mortgageVsRentData}
                            field={'rentGrowth'}
                            onChangeCallback = {recompute}
                            textAfterInput = ' %'
                            showDecimals = {true}
                        />
                    </div>

                    <div className="simple-hypo-input-section">
                        <div className="simple-label">
                            Daňová sazba

                            <InfoCircle
                                data-tooltip-id="tax-rate-tooltip"
                                className="tooltip-icon pointer"
                            />
                        </div>
                        <Select
                            options={[
                                {
                                    value: "15,00",
                                    label: '15 %'
                                },
                                {
                                    value: "23,00",
                                    label: '23 %'
                                }
                            ]}
                            observable={userInfoService.mortgageVsRentData}
                            field={'taxRefundRate'}
                            onChangeCallback = {recompute}
                        />
                    </div>
                  </div> 
                </> : <>
                    <div className="simple-hypo-input-section">
                        <div className="simple-label">
                            Předpokládaná inflace
                            <InfoCircle 
                                data-tooltip-id="inflation-input-tooltip"
                                className="tooltip-icon pointer"
                            />
                        </div>
                        <InputWithSlider
                            placeholder='Předpokládaná inflace'
                            min={0}
                            max={10}
                            step={0.1}
                            observable={userInfoService.mortgageVsRentData}
                            field={'inflation'}
                            onChangeCallback = {recompute}
                            textAfterInput = ' %'
                            showDecimals = {true}
                        />
                    </div>
                </>}
                <div className="simple-hypo-input-section w-full print-hidden">
                    <div className='checkbox'>
                        Rozšířené vstupy
                        <input
                            type={'checkbox'}
                            onChange={(e) => {
                            setadvancedInput(!advancedInput);
                        }}
                        checked={advancedInput}>
                        </input>
                    </div>
                </div>


            </div>

            <ResultSection />

            <PrintButton />
            {tooltipsSection()}
        </div>
    )
});