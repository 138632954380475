import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import { Container } from 'inversify';
import UserInfo from './services/UserInfo.service';
import MortgageMathService from './services/MathServices/MortgageMath.service';
import InvestmentMathService from './services/MathServices/InvestmentMath.service';
import { SrovnavacState } from './components/Alpha/Calcs/Srovnavac.state';
import { ContainerProvider } from './react-binding';
import { BankService } from './services/Bank/BankService';
import { FireBaseService } from './services/FirebaseService';
import { NavigationService } from './services/NavigationService/NavigationService';
import { LeadScreenState } from './components/main/LeadScreen/LeadScreen.state';
import { SimpleLeadScreenState } from './components/main/simpleLeadScreen/SimpleLeadScreen.state';
import { LetterForArgumentationService } from "./services/Bank/LetterForArgumentationService";


const container = new Container();
container.bind(UserInfo)
   .toConstantValue(new UserInfo());
container.bind(MortgageMathService)
  .toConstantValue(new MortgageMathService());
  container.bind(InvestmentMathService)
  .toConstantValue(new InvestmentMathService());
container.bind(SrovnavacState)
  .toConstantValue(new SrovnavacState());
container.bind(BankService)
  .toConstantValue(new BankService());
container.bind(FireBaseService)
  .toConstantValue(new FireBaseService());
container.bind(LeadScreenState)
  .toConstantValue(new LeadScreenState());
container.bind(SimpleLeadScreenState)
  .toConstantValue(new SimpleLeadScreenState());
container.bind(LetterForArgumentationService)
  .toConstantValue(new LetterForArgumentationService());
container.bind(NavigationService)
  .toConstantValue(new NavigationService());

const domNode = document.getElementById('root');

if (!domNode) {
  throw new Error("No root element found!");
}

const root = createRoot(domNode);
root.render(
  <ContainerProvider container={container}>
    <App />
  </ContainerProvider>
);
/*
ReactDOM.render(
  <React.StrictMode>
    <ContainerProvider container={container}>
      <App />
    </ContainerProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
