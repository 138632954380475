export interface Provisioning {
    allowedPages: string[],
    showBank: boolean,
    leadMail: string,
    disclaimerUrl?: string,
    showSimpleLeadButtons?: boolean,
    disclaimerInfo: {
        company: string,
        ic: string,
        address: string
    },
    styles?: {
        comparisonTableHeaderBgColor?: string
    }
}

export const possibleBasicInvestmentParams: (keyof InvestmentParams)[] = [
    'presentValue',
    'iRate',
    'due',
    'installment',
    'futureValue'
]

export interface InvestmentParams {
    presentValue?: number,
    iRate?: number,
    due?: number,
    installment?: number,
    futureValue?: number
}

export interface InvestmentScheduleParams {
    presentValue: number,
    iRate: number,
    due: number,
    yearlyInvestment: number,
    rent: number,
    rentGrowth: number,
    installment: number,
}

export interface StringInvestmentParams {
    presentValue?: string,
    iRate?: string,
    due?: string,
    installment?: string,
    futureValue?: string
}

export interface AnyTypeInvestmentParams {
    presentValue?: number | string,
    iRate?: number | string,
    due?: number | string,
    installment?: number | string,
    futureValue?: number | string
}

export interface SimpleInvestmentData extends StringInvestmentParams {
    computing: keyof InvestmentParams,
    error: boolean
}




export const possibleBasicMortgageParams: (keyof MortgageParams)[] = [
    'amount',
    'iRate',
    'due',
    'installment'
]

export interface MortgageParams {
    amount?: number,
    iRate?: number,
    due?: number,
    installment?: number
}

export interface StringMortgageParams {
    amount?: string,
    iRate?: string,
    due?: string,
    installment?: string
}

export interface AnyTypeMortgageParams {
    amount?: number | string,
    iRate?: number | string,
    due?: number | string,
    installment?: number | string
}

export interface AnyTypeCompleteMortgageParams {
    amount: number | string,
    iRate: number | string,
    due: number | string,
    installment: number | string
}

export interface SimpleMortgageData extends StringMortgageParams {
    computing: keyof MortgageParams,
    error: boolean
}

export interface AnnuityCalcData extends SimpleMortgageData {
//    dataMonthly: string,
    extraAmountsOfPayments: number[],
    iRates: number[],
    dues: number[],
    notes: string[],
    startingYear: number
}

export interface UserInfo {
    income: number,
    age: number,
    otherInstallments: number
}

export interface UserStringData {
    income: string,
    age: string,
    otherInstallments: string
}

export enum MortgageTypes {
    standard = 'Účelová hypotéka',
    american = 'Neúčelová hypotéka'
}

export enum MortgageTypesNr {
    standard = 1,
    american = 2
}

export type FixationsType = number | string;

export interface RatesBankData {
    [key: FixationsType]: number
}

export interface BaseInterestRate {
    type: MortgageTypesNr,
    basicInterestRates: RatesBankData
}

interface VariableCondition {
    field: string[],
    comparisonType: 'greaterOrEqualThan' | 'lessOrEqualThan' | 'exact' | 'differentFrom',
    conditionValue: string
}

export interface Variable {
    variableName: string,
    mustPassConditions: 'all' | 'atLeastOne' | 'none',
    conditions: VariableCondition[]
}

export interface GeneralRateRule {
    rateRuleName: string,
    modifier: number,
    listOfAllVariablesThatMustPass: string[]
}

export interface IfStatement {
    variableName?: string,
    variablesThatMustPass: string[],
    then?: IfStatement,
    else?: IfStatement
};

export interface BankData {
    name: string,
    priborTypeForFloat?: string,
    iRates: {
        [key: number] : {
            baseRates: {
                [key: number] : number
            }
        }
    },
    productsModifiers: {
        bankProducts: string[],
        insuranceProducts: string[],
        others: string[]
    },
    variables: Variable[],
    ifStatements?: IfStatement[],
    bonityIfStatements?: IfStatement[],
    generalRatesRules: GeneralRateRule[],
    bonityVariables?: Variable[],
    bonityVariablesThatMustPass: string[] | string,
    visible?: 1 | 0,
    feeRules: any
}

export interface BankResult {
    passesBonityCheck: boolean,
    interestRate: number | null,
    name: string,
    installment?: number,
    startingFee?: number | null,
    monthlyFee?: number | null,
    visible?: number,
    rpsn?: number,
    dsti?: number,
    sumOfInstallments?: number | null,
    futureValue?: number | null,
    extraFields?: {[key: string]: any}
}

export interface BankResults {
    bankResults: BankResult[]
}

export interface FirebaseData {
    area: string;
    phoneNr: string;
    name: string;
    email: string;
    date?: string,
    time?: string,
    message?: string,
    userInfo?: any
}

export type priborRates = [
    {
        Typ: "1M",
        Sazba: number
    },
    {
        Typ: "3M",
        Sazba: number
    },
    {
        Typ: "6M",
        Sazba: number
    },    {
        Typ: "12M",
        Sazba: number
    }
]

export interface WordPressUIData {
    [key: string]: string,
    meta_key: string
}


export interface RepaymentScheduleResult {
    [key: string]: number
}

export interface InvestmentScheduleResult {
    [key: string]: number
}

export interface MortgageVsRentResult {
    currentPropertyValue: any;
    month: number;
    mortgageLeft: number;
    netValueDifference: number;
    investmentValue: any;

    netWorthOfInvestment: number;
    netWorthOfMortgage: number;

    mortgageInterestInYear: number;
    mortgageTaxDeduction: number;
    sumOfPayments: number;
    upkeepCosts: number;
}