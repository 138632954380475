import * as React from "react";
import {observer} from "mobx-react-lite";
import { useInjection } from "../../../react-binding";
import MortgageMathService from "../../../services/MathServices/MortgageMath.service";
import { MortgageParams, StringMortgageParams } from "../../../constants/types";
import { InputWithSlider } from "../partials/InputWithSlider";
import UserInfo from "../../../services/UserInfo.service";
import { action } from "mobx";
import { calcDisclaimer } from "../../../constants/constants";

//@observer
export const SimpleHypoCalc = observer(() => {

    const mortgateService = useInjection(MortgageMathService);
    const userInfoService = useInjection(UserInfo);

    const recompute = action(() => {
        const data: MortgageParams = {};
        // @ts-ignore
        Object.keys(userInfoService.simpleMortgageData).forEach((key: keyof StringMortgageParams) => {
            if (key !== userInfoService.simpleMortgageData.computing) {
                // @ts-ignore
                data[key] = userInfoService.simpleMortgageData[key]
            }
        });

        data[userInfoService.simpleMortgageData.computing] = undefined;
        try {
            const result = mortgateService.calcMortgageViaMissingParam(data);
            userInfoService.simpleMortgageData[userInfoService.simpleMortgageData.computing] = Number(result).toLocaleString('cs');
            userInfoService.simpleMortgageData.error = false;
        } catch(e) {
            userInfoService.simpleMortgageData.error = true;
            console.warn(e);
        }
    })

    return (
        <div className='app-screen'>
            <div className="simple-calc-wrapper">
                <div className="simple-hypo-input-section">
                    <div className="simple-label">
                        Výše hypotéky
                    </div>
                    <InputWithSlider
                        placeholder='Výše hypotéky'
                        min={100000}
                        max={10000000}
                        step={50000}
                        observable={userInfoService.simpleMortgageData}
                        field={'amount'}
                        onChangeCallback = {recompute}
                        textAfterInput = 'Kč'
                    />
                </div>
                <div className="simple-hypo-input-section">
                    <div className="simple-label">
                        Úroková sazba
                    </div>
                    <InputWithSlider
                        placeholder='Úroková sazba'
                        min={0.01}
                        max={10}
                        step={0.01}
                        observable={userInfoService.simpleMortgageData}
                        field={'iRate'}
                        onChangeCallback = {recompute}
                        errorMessage = {'Výsledná sazba je mimo běžné hodnoty'}
                        textAfterInput = ' %'
                        showDecimals = {true}
                    />
                </div>
                <div className="simple-hypo-input-section">
                    <div className="simple-label">
                        Splatnost v letech
                    </div>
                    <InputWithSlider
                        placeholder='Splatnost'
                        min={1}
                        max={40}
                        step={1}
                        observable={userInfoService.simpleMortgageData}
                        field={'due'}
                        onChangeCallback = {recompute}
                        errorMessage = {'Výsledná splatnost je vyšší než 40 let'}
                        textAfterInput = ' let'
                    />
                </div>
                <div className="simple-hypo-input-section">
                    <div className="simple-label">
                        Výše splátky
                    </div>
                    <InputWithSlider
                        placeholder='Výše splátky'
                        min={100}
                        max={40000}
                        step={100}
                        observable={userInfoService.simpleMortgageData}
                        field={'installment'}
                        onChangeCallback = {recompute}
                        textAfterInput = ' Kč'
                    />
                </div>

                <div className="calc-disclaimer">{calcDisclaimer}</div>
            </div>
        </div>
    )
})